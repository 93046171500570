import "./Login.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import SpinnerLoader from '../../components/spinner-loader/SpinnerLoader.component';
import { authService } from "../../services/AuthService";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import logo from "./owwll-logo.png";
import withAuth from "../../components/hod/withAuth";
import React from "react";
import Footer from "../../components/footer/Footer";

function Login(props: any) {
  const [isError, setIsError] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => {
    authService
      .login(data, props)
      .then((res: any) => {
        if (res.data.result.accessToken != null) {
          localStorage.setItem("token", res.data.result.accessToken);
          localStorage.setItem("user-name", res.data.result.user.fullName);
          props.history.push("/");
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid main-bg">
       <SpinnerLoader isGeneral={true} tasksList={['authentication', 'forgotpassword']} inInternal={false} />
      <div className="container">
        <div className="row pt-5">
          <div className="login-page">
            <div className="logo"></div>
            <div className="heading-bx">
              <h2>
                Welcome to
                <span> Owwll Back Office</span>
              </h2>
            </div>

            <div className="col-sm-8 col-lg-5 p-0">
              <form
                autoComplete="off"
                className="needs-validation p-5 form-login"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <div className="mb-3 ">
                  <b>ADMIN USER</b>
                  <br />
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faUser} color={"#fff"} />
                        </div>
                      </div>
                      <input
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        type="text"
                        className="form-control"
                        id="username"
                        name="email"
                        placeholder="Login Name"
                      />
                      {errors.email && <span>{errors.email?.message}</span>}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faLock} color={"#fff"} />
                        </div>
                      </div>
                      <input
                        ref={register({ required: true })}
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                      />
                      {errors.password && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                {isError ? (
                  <span className="text-danger">Invalid Email or Password</span>
                ) : (
                  ""
                )}

                <div className="form-row pl-1 float-right">
                  <div className="col-md-12 mb-3">
                    <button type="submit" className="btn btn-style login-btn ">
                      Login
                    </button>
                  </div>
                </div>
                <div className="clearfix"></div>
              </form>
            </div>
          </div>
          <div className="offset-md-2"></div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Login;
