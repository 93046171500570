import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReportService from "../../../services/report-service/ReportService";
import { useEffect, useState } from "react";
import Utility from "../../../utility/Utility";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function BalanceReport() {
  const classes = useStyles();
  const [reportData, setReportData] = useState("");
  const [pdfBytes, setpdfBytes] = useState("");
  const [fullName, setFullName] = useState<any>("");
  const [sortOn, setSortOn] = useState("Name");
  const [role, setRole] = useState("Owlet");
  const [orderBy, setOrderBy] = useState("Asc");
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFullNameChange = (name: any) => {
    console.log("name=" + name.target.value);
    setFullName(name.target.value);
  };

  const handleSortOnChange = (name: any) => {
    console.log("SortOn=" + name.target.value);
    setSortOn(name.target.value);
  };

  const handleRoleChange = (Role: any) => {
    console.log("role=" + Role.target.value);
    setRole(Role.target.value);
  };
  const handleOrderByChange = (OrderBy: any) => {
    console.log("OrderBy=" + OrderBy.target.value);
    setOrderBy(OrderBy.target.value);
  };

  const getBalanceReport = () => {
    let getRequestParams = {
       fullName: fullName,
      role: role,
      sortDir: orderBy,
      sortOn: sortOn,
    };
    console.log(getRequestParams);
    // if (fullName === "") {
    //   setIsError(true);
    // } else 
      ReportService.getBalanceReport(getRequestParams).then((res: any) => {
        setReportData(res.data.result.reportHTML);
        setpdfBytes(res.data.result.reportBytes);
        setShowDownloadBtn(true);
        setIsError(false);
      });
    
  };

  const ClearSearchCriteria = () => {
    setFullName("");
    setSortOn("Name");
    setRole("Owlet");
    setOrderBy("Asc");
  };

  const Download = () => {
    var blob = Utility.b64toBlob(pdfBytes);
    var url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "BalanceReport";
    a.click();
  };

  return (
    <>
      <div className="col-10 mt-4">
        <h3>Balances</h3>
        <div className="pt-2">
          <div className="formouter">
            <form className={classes.container} noValidate>
              <fieldset id="group1">
                <div className="custom-control custom-radio custom-control-inline m-1">
                  <input
                    type="radio"
                    value="Owlet"
                    id="owlet"
                    //defaultValue={role}
                    checked={role === "Owlet"}
                    name="group1"
                    className="custom-control-input"
                    onChange={handleRoleChange}
                  />
                  <label className="custom-control-label" htmlFor="owlet">
                    Owlet
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline m-1">
                  <input
                    type="radio"
                    value="Owwll"
                    id="owwll"
                    name="group1"
                    className="custom-control-input"
                    onChange={handleRoleChange}
                  />
                  <label className="custom-control-label" htmlFor="owwll">
                    Owwll
                  </label>
                </div>
              </fieldset>
              <label className="m-1 ml-4">Name</label>
              <input
                className="form-control form-control-sm"
                type="name"
                name="txtfullName"
                value={fullName}
                onChange={handleFullNameChange}
                style={{ backgroundColor: "#fff", width: "125px" }}
                //ref={searchForm({ required: false })}
                placeholder="Name"
              />
              {isError ? (
                <p
                  style={{
                    color: "red",
                    marginLeft: "-205px",
                    marginTop: "36px",
                  }}
                >
                  name field cannot be empty
                </p>
              ) : (
                ""
              )}
              <label htmlFor="sorton" className="m-1 ml-4">
                Sort On
              </label>

              <select
                onChange={handleSortOnChange}
                value={sortOn}
                style={{ width: "125px" }}
                className="form-control form-control-sm"
                id="sorton"
                name="sorton"
              >
                <option value="Name">Name</option>
                <option value="Balance">Balance</option>
                <option value="Withdrawal">Withdrawal</option>
              </select>
              <fieldset id="group2">
                <div className="custom-control custom-radio custom-control-inline m-1 ml-4 ">
                  <input
                    type="radio"
                    value="Asc"
                    id="asc"
                    checked={orderBy === "Asc"}
                    name="group2"
                    className="custom-control-input"
                    onChange={handleOrderByChange}
                  />
                  <label className="custom-control-label" htmlFor="asc">
                    Low to High
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline m-1">
                  <input
                    type="radio"
                    value="Desc"
                    id="desc"
                    checked={orderBy === "Desc"}
                    name="group2"
                    className="custom-control-input"
                    onChange={handleOrderByChange}
                  />
                  <label className="custom-control-label" htmlFor="desc">
                    High to Low
                  </label>
                </div>
              </fieldset>

              <button
                color="secondary"
                onClick={ClearSearchCriteria}
                type="button"
                className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
              >
                Reset
              </button>
              <button
                color="secondary"
                onClick={getBalanceReport}
                type="button"
                className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
              >
                View Report
              </button>
            </form>
          </div>
          <div className="mt-4">
            <div className="mb-0 heading">
              <h5>
                Report{" "}
                {showDownloadBtn ? (
                  <button
                    color="secondary"
                    onClick={Download}
                    type="button"
                    className="btn btn-sm btn-outline-secondary float-right"
                  >
                    Save as
                  </button>
                ) : (
                  ""
                )}
              </h5>
            </div>
            <div className="report-view-bx">
              <div dangerouslySetInnerHTML={{ __html: reportData }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BalanceReport;
