import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import withAuth from "../../components/hod/withAuth";
import { TimeonCall,  CallsReport, OwwllActiveTime, ReferralReport} from '../reports'
import "bootstrap/dist/css/bootstrap.min.css";
import SpinnerLoader from "../../components/spinner-loader/SpinnerLoader.component";

function Reports(props: any) {
  let { path, url } = useRouteMatch();

  return (
    <div>
         <SpinnerLoader isGeneral={true} tasksList={
        ['getRevenueReport','GetExpenseReport','GetEarningReport','getAdviceSeekerSpendingsReport','GetDepositReport','getOwwllActiveTimeReport','GetCallsReport','GetReferralReport','GetBalanceReport','getTimeonCallReport']
      } inInternal={false} />
      <div className="row m-0">
        <div className="col-2 p-0">
          <div className="left-nav-bx">
            <nav className="navbar">
              <ul className="navbar-nav left-m">
                <li className="nav-item">
                
                    <Link className={"nav-link " + (props.location.pathname == "/reports/callsreport" ? "active-sublink" : "")} to={`${url}/callsreport`}>
                      Call Counts
                    </Link>
                 
                </li>
                <li className="nav-item">
              
                    <Link className={"nav-link " + (props.location.pathname == "/reports/referralreport" ? "active-sublink" : "")} to={`${url}/referralreport`}>
                      Referrals
                    </Link>
                
                </li>
                <li className="nav-item">
              
                    <Link className={"nav-link " + (props.location.pathname == "/reports/timeoncallreport" ? "active-sublink" : "")} to={`${url}/timeoncallreport`}>
                    Time on Call
                    </Link>
               
                </li>
                <li className="nav-item">
              
                    <Link className={"nav-link " + (props.location.pathname == "/reports/owwllactivetimereport" ? "active-sublink" : "")} to={`${url}/owwllactivetimereport`}>
                    Owwll Active Time
                    </Link>
                
                </li>
             
              
              </ul>
            </nav>
          </div>
        </div>
        <Switch>
          <Route exact path={path}>
            <div className="col-10 p-4">
              <h3>Welcome to Report Manager</h3>
            </div>
          </Route>
         
          <Route path={`${path}/callsreport`} component={CallsReport} />
          <Route path={`${path}/referralreport`} component={ReferralReport} />
          <Route path={`${path}/timeoncallreport`} component={TimeonCall} />
          <Route path={`${path}/owwllactivetimereport`} component={OwwllActiveTime} />
      
        </Switch>
      </div>
    </div>
  );
}

export default withAuth(Reports);
