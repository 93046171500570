import http from "../HttpInterceptor";

const getAll = (data: any) => {
  return http.post("gateway/getwithdrawals", data, {
    headers: {'task_name': 'getwithdrawals'}
});
};


const save = (data: any) => {
  return http.post("gateway/updatewithdrawal", data, {
    headers: {'task_name': 'updatewithdrawal'}
});
};

export default {
  getAll,
  save,
};