import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import SettingsService from "../../../services/settings-service/SettingsService";
import Utili from "../../../utility/Utility";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
import moment from "moment";
let array: any[] = [];
function BonusExpiry() {
  function refreshPage() {
    window.location.reload();
  }
  const [SignupBonusExpiryDays, setSignupBonusExpiryDays] = useState<any>();
  const [FirstDeposiBonusExpiryDays, setFirstDeposiBonusExpiryDays] =
    useState<any>();
  const [ReferralBonusExpiryDays, setReferralBonusExpiryDays] = useState<any>();
  const [TopupBonusExpiryDays, setTopupBonusExpiryDays] = useState<any>();
  const [errorSignupBonus, seterrorSignupBonus] = useState<any>(false);
  const [errorReferralBonus, seterrorReferralBonus] = useState<any>(false);
  const [errorTopupBonus, seterrorTopupBonus] = useState<any>(false);
  const [errorfirstDepositbonusExpiry, seterrorfirstDepositbonusExpiry] =
    useState(false);

  const {
    register: regSignupBonusExpiry,
    handleSubmit: submitSignupBonusExpiry,
  } = useForm();
  const {
    register: regReferralBonusExpiry,
    handleSubmit: submitReferralBonusExpiry,
  } = useForm();
  const {
    register: regTopupBonusExpiry,
    handleSubmit: submitTopupBonusExpiry,
  } = useForm();

  const {
    register: registerFirstdepositExpiryAppConfig,
    reset: resetFirstdepositExpiry,
    handleSubmit: submitFirstDepositExpiryConfig,
  } = useForm();

  const GetSignupBonusExpiryDaysConfig = () => {
    SettingsService.GetSignupBonusExpiryDaysConfig().then((res: any) => {
      setSignupBonusExpiryDays(res.data.result);
    });
  };
  const GetReferralBonusExpiryDaysConfig = () => {
    SettingsService.GetReferralBonusExpiryDaysConfig().then((res: any) => {
      setReferralBonusExpiryDays(res.data.result);
    });
  };

  const GetTopupBonusExpiryDaysConfig = () => {
    SettingsService.GetTopupBonusExpiryDaysConfig().then((res: any) => {
      setTopupBonusExpiryDays(res.data.result);
    });
  };

  const GetAppConfigs = () => {
    SettingsService.GetAppConfigs().then((res: any) => {
      console.log(res.data.result);
      res.data.result.forEach(function (value: any) {
        if (value.key === "DEPOSIT_BONUS_EXPIRY") {
          setFirstDeposiBonusExpiryDays(value.value);
        }
      });
    });
  };

  const submit = (data: any) => {
    if (data.value === "" || parseInt(data.value) < 1) {
      seterrorfirstDepositbonusExpiry(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          {
            label: "Confirm",
            onClick: () => updateAppConfiguration(data),
          },
          {
            label: "Cancel",
            onClick: () => GetAppConfigs(),
          },
        ],
      });

      seterrorfirstDepositbonusExpiry(false);
    }
  };

  const updateAppConfiguration = (data: any) => {
    var newDt = new Date();
    data.effectiveStart = moment(newDt).format("YYYY-MM-DD");
    data.key = "DEPOSIT_BONUS_EXPIRY";
    var dataArray = [data];
    SettingsService.UpdateAppConfiguration(dataArray)
      .then((res) => {
        GetAppConfigs();
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  const SignupBonusExpiry = (data: any) => {
    console.log(data);
    SettingsService.UpdateSignupBonusExpiryDaysConfig(data.value)
      .then((res) => {
        console.log(res.data);
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const ReferralBonusExpiry = (data: any) => {
    console.log(data);
    SettingsService.UpdateReferralBonusExpiryDaysConfig(data.value)
      .then((res) => {
        console.log(res.data);
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const TopupBonusExpiry = (data: any) => {
    console.log(data);
    SettingsService.UpdateTopupBonusExpiryDaysConfig(data.value)
      .then((res) => {
        console.log(res.data);
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const onsubmitSignupBonusExpiry = (data: any) => {
    if (data.value === "" || parseInt(data.value) < 1) {
      seterrorSignupBonus(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          { label: "Confirm", onClick: () => SignupBonusExpiry(data) },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorSignupBonus(false);
    }
  };
  const onsubmitReferralBonusExpiry = (data: any) => {
    if (data.value === "" || parseInt(data.value) < 1) {
      seterrorReferralBonus(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          { label: "Confirm", onClick: () => ReferralBonusExpiry(data) },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorReferralBonus(false);
    }
  };

  const onsubmitTopupBonusExpiry = (data: any) => {
    if (data.value === "" || parseInt(data.value) < 1) {
      seterrorTopupBonus(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          { label: "Confirm", onClick: () => TopupBonusExpiry(data) },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorTopupBonus(false);
    }
  };

  useEffect(() => {
    GetSignupBonusExpiryDaysConfig();
    GetReferralBonusExpiryDaysConfig();
    GetTopupBonusExpiryDaysConfig();
    GetAppConfigs();
  }, []);
  return (
    <>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "UpdateTopupBonusExpiryDaysConfig",
          "UpdateReferralBonusExpiryDaysConfig",
          "UpdateSignupBonusExpiryDaysConfig",
          "GetSignupBonusExpiryDaysConfig",
          "GetReferralBonusExpiryDaysConfig",
          "GetTopupBonusExpiryDaysConfig",
        ]}
        inInternal={false}
      />
      <div className=" p-0" style={{ width: "90%" }}>
        <h3 className="mb-3">Bonus Expiry</h3>
        <h4 className="mb-0 heading"> Configuration </h4>
        <div className="p-2 border config-rows mb-4">
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Signup Bonus Expiry</label>
            </div>
            <div style={{ width: "10%" }}>
              <input
                className="form-control form-control-sm "
                defaultValue={SignupBonusExpiryDays}
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={regSignupBonusExpiry}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                days
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitSignupBonusExpiry(onsubmitSignupBonusExpiry)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorSignupBonus ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Signup Bonus Expiry cannot be zero or negative day
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Referral Bonus Expiry</label>
            </div>
            <div style={{ width: "10%" }}>
              <input
                className="form-control form-control-sm "
                defaultValue={ReferralBonusExpiryDays}
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={regReferralBonusExpiry}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                days
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitReferralBonusExpiry(onsubmitReferralBonusExpiry)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorReferralBonus ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Referral Bonus Expiry cannot be zero or negative day
            </p>
          ) : (
            ""
          )}
          <hr />

          {errorTopupBonus ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Topup Bonus Expiry cannot be zero or negative day
            </p>
          ) : (
            ""
          )}

          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">First Deposit Bonus Expiry</label>
            </div>
            <div style={{ width: "10%" }}>
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={registerFirstdepositExpiryAppConfig}
                defaultValue={FirstDeposiBonusExpiryDays}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                days
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitFirstDepositExpiryConfig(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorfirstDepositbonusExpiry ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              First Deposit Bonus Expiry cannot be a negative number or empty.
            </p>
          ) : (
            ""
          )}
          <hr />
        </div>
      </div>
    </>
  );
}

export default BonusExpiry;
