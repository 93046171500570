import http from "./HttpInterceptor";


const PlateformStatsWidgetData = (duration: any) => {
  return http.get("gateway/Dashboard/PlateformStatsWidgetData?duration=" + duration, {
    headers: { 'task_name': 'PlateformStatsWidgetData' }
  });
};

const UserStatsWidgetData = (duration: any) => {
  return http.get("gateway/Dashboard/UserStatsWidgetData?duration=" + duration, {
    headers: { 'task_name': 'UserStatsWidgetData' }
  });
};
const getTopEarners = (duration: any) => {
  return http.get("gateway/Dashboard/TopCallEarners?duration=" + duration, {
    headers: { 'task_name': 'getTopEarners' }
  });
};

const getTopReferralEarners = (duration: any) => {
  return http.get("gateway/Dashboard/TopReferralEarners?duration=" + duration, {
    headers: { 'task_name': 'getTopReferralEarners' }
  });
};

const getTopSignupEarners = (duration: any) => {
  return http.get("gateway/Dashboard/TopSignupEarners?duration=" + duration, {
    headers: { 'task_name': 'getTopSignupEarners' }
  });
};

const getLoadChart = (duration: any) => {
  return http.get("gateway/Dashboard/LoadChart?duration=" + duration, {
    headers: { 'task_name': 'getLoadChart' }
  });
};

export default {
  getLoadChart,
  getTopSignupEarners,
  getTopReferralEarners,
  getTopEarners,
  UserStatsWidgetData,
  PlateformStatsWidgetData,

};