import axios from "axios";
import { TaskManager } from '../components/managers/Task.manager';
import { Task } from '../components/models/Task.model';
import { server } from '../config/Config';

const taskManager = TaskManager.getInstance();
const instance = axios.create({
  baseURL: server,
  headers: {
    "Content-type": "application/json",
  }
});

instance.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    let taskId = 'generic';
    if (!req.headers.task_name) {
      console.log("PLEASE ADD A TASK NAME FOR THE REQUEST");
      req.headers.task_name = 'generic';
    } else {
      taskId = req.headers.task_name;
    }
    const currentTask = { id: taskId, status: 1, loading: true } as Task;
    taskManager.addTask(currentTask);
    return req;
  },
  (error) => {
    taskManager.removeTask(error.toJSON().config.headers.task_name);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    let task = taskManager.getTask(response.config.headers.task_name);
    if (task != null) {
      task.loading = false;
      taskManager.updateTask(task.id, task);
      taskManager.removeTask(task.id);
    }

    return response;
  },
  (error) => {
    taskManager.removeTask(error.toJSON().config.headers.task_name);
    return Promise.reject(error);
  }
);

export default instance;