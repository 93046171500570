import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import SettingsService from "../../../services/settings-service/SettingsService";
import Utili from "../../../utility/Utility";
function DeductionOrder() {
  function refreshPage() {
    window.location.reload();
  }
  const [SOURCE_ORDER, setSOURCE_ORDER] = useState<any>([]);
  const [CallSourceOrderWithdrawal, setCallSourceOrderWithdrawal] =
    useState<any>([]);
  const [ErrorORDER, setErrorORDER] = useState<any>("");
  const [isSOURCEError, setSourceError] = useState<any>(false);
  const [isWithdrawalError, setWithdrawalrror] = useState<any>(false);

  const { register: registerSOURCE_ORDER, handleSubmit: submitSOURCE_ORDER } =
    useForm();
  const {
    register: registerWithdrawalOrder,
    handleSubmit: submitWithdrawalOrder,
  } = useForm();

  const GetCallSourceOrder = () => {
    SettingsService.GetCallSourceOrder().then((res: any) => {
      setSOURCE_ORDER(res.data.result);
    });
  };

  const GetCallSourceOrderWithdrawal = () => {
    SettingsService.GetCallSourceOrderWithdrawal().then((res: any) => {
      setCallSourceOrderWithdrawal(res.data.result);
    });
  };

  const onSubmitSOURCE_ORDER = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        { label: "Confirm", onClick: () => SubmitSOURCE_ORDER(data) },
        { label: "Cancel", onClick: () => refreshPage() },
      ],
    });
  };
  const onSubmitWithdrawalSource = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        { label: "Confirm", onClick: () => SubmitWithdrawalSource(data) },
        { label: "Cancel", onClick: () => refreshPage() },
      ],
    });
  };
  const SubmitWithdrawalSource = (data: any) => {
    console.log(data.data[0]);
    if (data.data[0].value === data.data[1].value) {
      setWithdrawalrror(true);
      setErrorORDER("Call deduction not in correct order");
      console.log("Call deduction not in correct order");
    } else if (
      parseInt(data.data[0].value) > 2 ||
      parseInt(data.data[0].value) < 1 ||
      parseInt(data.data[1].value) > 2 ||
      parseInt(data.data[1].value) < 1
    ) {
      setWithdrawalrror(true);
      setErrorORDER("Value Cannot be greater than 2 and less than 1");
      console.log("Value Cannot be greater than 2 and less than 1");
    } else {
      setWithdrawalrror(false);
      console.log(data.data);
      SettingsService.UpdateAppConfiguration(data.data)
        .then((res) => {
          console.log(res.data);
          Utili.notifyUpdated();
        })
        .catch((err) => {
          Utili.notifyError();
        });
    }
  };
  const SubmitSOURCE_ORDER = (data: any) => {
    if (
      data.data[0].value === data.data[1].value ||
      data.data[0].value === data.data[2].value ||
      data.data[0].value === data.data[3].value ||
      data.data[0].value === data.data[4].value ||
      data.data[0].value === data.data[5].value ||
      data.data[0].value === data.data[6].value ||
      data.data[0].value === data.data[7].value ||
      data.data[1].value === data.data[2].value ||
      data.data[1].value === data.data[3].value ||
      data.data[1].value === data.data[4].value ||
      data.data[1].value === data.data[5].value ||
      data.data[1].value === data.data[6].value ||
      data.data[1].value === data.data[7].value ||
      data.data[2].value === data.data[3].value ||
      data.data[2].value === data.data[4].value ||
      data.data[2].value === data.data[5].value ||
      data.data[2].value === data.data[6].value ||
      data.data[2].value === data.data[7].value ||
      data.data[3].value === data.data[4].value ||
      data.data[3].value === data.data[5].value ||
      data.data[3].value === data.data[6].value ||
      data.data[3].value === data.data[7].value ||
      data.data[4].value === data.data[5].value ||
      data.data[4].value === data.data[6].value ||
      data.data[4].value === data.data[7].value ||
      data.data[5].value === data.data[6].value ||
      data.data[5].value === data.data[7].value ||
      data.data[6].value === data.data[7].value
    ) {
      setSourceError(true);
      setErrorORDER("Call deduction not in correct order");
      console.log("Call deduction not in correct order");
    } else if (
      parseInt(data.data[0].value) > 8 ||
      parseInt(data.data[0].value) < 1 ||
      parseInt(data.data[1].value) > 8 ||
      parseInt(data.data[1].value) < 1 ||
      parseInt(data.data[2].value) > 8 ||
      parseInt(data.data[2].value) < 1 ||
      parseInt(data.data[3].value) > 8 ||
      parseInt(data.data[3].value) < 1 ||
      parseInt(data.data[4].value) > 8 ||
      parseInt(data.data[4].value) < 1 ||
      parseInt(data.data[5].value) > 8 ||
      parseInt(data.data[5].value) < 1 ||
      parseInt(data.data[6].value) > 8 ||
      parseInt(data.data[6].value) < 1 ||
      parseInt(data.data[7].value) > 8 ||
      parseInt(data.data[6].value) < 1
    ) {
      setSourceError(true);
      setErrorORDER("Value Cannot be greater than 8 and less than 1");
      console.log("Value Cannot be greater than 8 and less than 1");
    } else {
      setSourceError(false);
      console.log(data.data);
      SettingsService.UpdateAppConfiguration(data.data)
        .then((res) => {
          console.log(res.data);
          Utili.notifyUpdated();
        })
        .catch((err) => {
          Utili.notifyError();
        });
    }
  };

  useEffect(() => {
    GetCallSourceOrder();
    GetCallSourceOrderWithdrawal();
  }, []);
  return (
    <>
      <div className=" p-0" style={{ width: "80%" }}>
        <h3 className="mb-3">Deduction Order Configuration</h3>
        <h4 className="mb-0 heading">Call Deduction Configuration </h4>
        <div className="p-2 border config-rows mb-4">
          <form noValidate onSubmit={submitSOURCE_ORDER(onSubmitSOURCE_ORDER)}>
            {SOURCE_ORDER.map((item: any, index: any) => {
              const fieldValue = `data[${index}]`;
              return (
                <fieldset name={fieldValue} key={fieldValue}>
                  <div>
                    <div className="row mb-0">
                      <div className="col-6">
                        <label className="mt-2">{item.label}</label>
                      </div>
                      <div className="col-2">
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.category`}
                          value={item.category}
                          ref={registerSOURCE_ORDER}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.label`}
                          value={item.label}
                          ref={registerSOURCE_ORDER}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.unit`}
                          value={item.unit}
                          ref={registerSOURCE_ORDER}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.key`}
                          value={item.key}
                          ref={registerSOURCE_ORDER}
                        />
                        <input
                          className="form-control form-control-sm "
                          defaultValue={item.value}
                          type="number"
                          name={`${fieldValue}.value`}
                          min={1}
                          max={3}
                          style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                          ref={registerSOURCE_ORDER}
                        />
                      </div>
                      <div className="col-3">
                        <label
                          className="m-2 small"
                          style={{ color: "#b59852" }}
                        >
                          {item.unit}
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            })}
            {isSOURCEError ? (
              <p style={{ color: "red", marginLeft: "50%" }}>{ErrorORDER}</p>
            ) : (
              ""
            )}
            <div className=" form-group  pb-3">
              <Button
                type="submit"
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
        <h4 className="mb-0 heading">Withdrawal Deduction Configuration </h4>
        <div className="p-2 border config-rows mb-4">
          <form
            noValidate
            onSubmit={submitWithdrawalOrder(onSubmitWithdrawalSource)}
          >
            {CallSourceOrderWithdrawal.map((item: any, index: any) => {
              const fieldValue = `data[${index}]`;
              return (
                <fieldset name={fieldValue} key={fieldValue}>
                  <div>
                    <div className="row  mb-0">
                      <div className="col-6">
                        <label className="mt-2">{item.label}</label>
                      </div>
                      <div className="col-2">
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.category`}
                          value={item.category}
                          ref={registerWithdrawalOrder}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.label`}
                          value={item.label}
                          ref={registerWithdrawalOrder}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.unit`}
                          value={item.unit}
                          ref={registerWithdrawalOrder}
                        />
                        <input
                          hidden
                          type="text"
                          name={`${fieldValue}.key`}
                          value={item.key}
                          ref={registerWithdrawalOrder}
                        />
                        <input
                          className="form-control form-control-sm "
                          defaultValue={item.value}
                          type="number"
                          name={`${fieldValue}.value`}
                          min={1}
                          max={3}
                          style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                          ref={registerWithdrawalOrder}
                        />
                      </div>
                      <div className="col-3">
                        <label
                          className="m-2 small"
                          style={{ color: "#b59852" }}
                        >
                          {item.unit}
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            })}
            {isWithdrawalError ? (
              <p style={{ color: "red", marginLeft: "50%" }}>{ErrorORDER}</p>
            ) : (
              ""
            )}
            <div className=" form-group  pb-3">
              <Button
                type="submit"
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DeductionOrder;
