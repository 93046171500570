import { Button, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditIcon, CheckIcon, CloseIcon } from "../../../components/Icons";
import React, { useEffect, useState } from "react";
import "./ManageUser.css";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import UserService from "../../../services/user-service/UserService";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import Utili from "../../../utility/Utility";
import { ExportToCsv } from "export-to-csv";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
import { UserProfile, TopUp } from "../../../components";
import moment from "moment";

const User: any = [];
const initialState = {
  id: null,
  FullName: "",
  UserEmail: "",
  UserType: "",
  Enabled: "",
  Deleted: "",
  Password: "",
  isActive: false,
  isFeatured: false,
};
function ManageUser(props: any) {
  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => (
        <a
          className="outline-primary hand "
          onClick={() => profile(users.find((e: any) => e.id == params.row.id))}
        >
          <Tooltip title={params.row.fullName}>
            <span className="table-cell-trucate">{params.row.fullName}</span>
          </Tooltip>
        </a>
      ),
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
      renderCell: (params: any) => (
        <Tooltip title={params.row.email}>
          <span className="table-cell-trucate">{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "signupUpdatedOn",
      headerName: "Approval Date",
      flex: 0.6,
      // align: "left",
      // headerAlign: "center",
      valueFormatter: (params) =>
        moment(params.row.signupUpdatedOn).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: "brand_Ambassador",
      headerName: "Ambassador",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "isActive",
      headerName: "Enabled",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",

      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "isDeleted",
      headerName: "Deleted",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isDeleted ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "isFeatured",
      headerName: "Featured",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isFeatured ? <CheckIcon /> : <CloseIcon />,
    },
    // {
    //   field: "subscriptionStatus",
    //   headerName: "Premium Account",
    //   flex: 0.5,
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "grid-h",
    //   renderCell: (params: any) =>
    //     params.row.subscriptionStatus === null ||
    //     params.row.subscriptionStatus === "" ? (
    //       <a>Active</a>
    //     ) : (
    //       <a>{params.row.subscriptionStatus}</a>
    //     ),
    // },
    {
      field: "col6",
      headerName: "Referred By",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.referredBy.fullName != "" ? (
          <a
            className="outline-primary"
            href="javascript:void(0);"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
          >
            {params.row.referredBy.fullName}
          </a>
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
            fontSize="small"
          />
        ),
    },
    {
      field: "col7",
      headerName: " ",
      width: 20,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.isDeleted ? (
          ""
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() => edit(users.find((e: any) => e.id == params.row.id))}
            fontSize="small"
          />
        ),
    },
    {
      field: "col8",
      headerName: "Feedback",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params) => (
        <Button variant="contained" className="MuiButton-textSizeSmall primary">
          <Link className="lnk" to={`feedback/${params.row?.id}`}>
            Review
          </Link>
        </Button>
      ),
    },
  ];

  const columnsAmbassador: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => (
        <a
          className="outline-primary hand"
          onClick={() => profile(users.find((e: any) => e.id == params.row.id))}
        >
          <Tooltip title={params.row.fullName}>
            <span className="table-cell-trucate">{params.row.fullName}</span>
          </Tooltip>
        </a>
      ),
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
      renderCell: (params: any) => (
        <Tooltip title={params.row.email}>
          <span className="table-cell-trucate">{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "signupUpdatedOn",
      headerName: "Approval Date",
      width: 160,
      // align: "left",
      // headerAlign: "center",
      valueFormatter: (params) =>
        moment(params.row.signupUpdatedOn).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: "brand_Ambassador",
      headerName: "Ambassador",
      width: 140,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "isActive",
      headerName: "Enabled",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "isDeleted",
      headerName: "Deleted",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isDeleted ? <CheckIcon /> : <CloseIcon />,
    },

    {
      field: "isFeatured",
      headerName: "Featured",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isFeatured ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "col6",
      headerName: "Referred By",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.referredBy.fullName != "" ? (
          <a
            className="outline-primary"
            href="javascript:void(0);"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
          >
            {params.row.referredBy.fullName}
          </a>
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
            fontSize="small"
          />
        ),
    },
    {
      field: "col7",
      headerName: " ",
      width: 20,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.isDeleted ? (
          ""
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() => edit(users.find((e: any) => e.id == params.row.id))}
            fontSize="small"
          />
        ),
    },
    {
      field: "col8",
      headerName: "Referrals",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params) => (
        <Button variant="contained" className="MuiButton-textSizeSmall primary">
          <Link className="lnk" to={`referrals/${params.row?.id}`}>
            View
          </Link>
        </Button>
      ),
    },
  ];

  const columnsOwlet: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => (
        <a
          className="outline-primary hand"
          onClick={() => profile(users.find((e: any) => e.id == params.row.id))}
        >
          <Tooltip title={params.row.fullName}>
            <span className="table-cell-trucate">{params.row.fullName}</span>
          </Tooltip>
        </a>
      ),
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
      renderCell: (params: any) => (
        <Tooltip title={params.row.email}>
          <span className="table-cell-trucate">{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "signupUpdatedOn",
      headerName: "Approval Date",
      width: 160,
      valueFormatter: (params) =>
        moment(params.row.signupUpdatedOn).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: "brand_Ambassador",
      headerName: "Ambassador",
      width: 150,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "isActive",
      headerName: "Enabled",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "isDeleted",
      headerName: "Deleted",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isDeleted ? <CheckIcon /> : <CloseIcon />,
    },
    // {
    //   field: "subscriptionType",
    //   headerName: "Free Trial Offered",
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "grid-h",
    //   renderCell: (params: any) =>
    //     params.row.subscriptionType === null ||
    //     params.row.subscriptionType === "Paid" ? (
    //       <CloseIcon />
    //     ) : (
    //       <CheckIcon />
    //     ),
    // },
    {
      field: "col6",
      headerName: "Referred By",
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.referredBy.fullName != "" ? (
          <a
            className="outline-primary"
            href="javascript:void(0);"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
          >
            {params.row.referredBy.fullName}
          </a>
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() =>
              AddReferral(users.find((e: any) => e.id == params.row.id))
            }
            fontSize="small"
          />
        ),
    },
    {
      field: "col7",
      headerName: " ",
      width: 15,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.isDeleted ? (
          ""
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() => edit(users.find((e: any) => e.id == params.row.id))}
            fontSize="small"
          />
        ),
    },
    {
      field: "col8",
      headerName: "Feedback",
      flex: 0.35,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params) => (
        <Button variant="contained" className="MuiButton-textSizeSmall primary">
          <Link className="lnk" to={`feedback/${params.row.id}`}>
            Review
          </Link>
        </Button>
      ),
    },
  ];

  const columnsAdmin: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "isActive",
      headerName: "Enabled",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "isDeleted",
      headerName: "Deleted",
      width: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isDeleted ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "col6",
      headerName: " ",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "grid-h",
      sortable: false,
      renderCell: (params) =>
        params.row.isDeleted ? (
          ""
        ) : (
          <EditIcon
            data-toggle="modal"
            data-target="#editModal"
            className="edit"
            onClick={() => edit(users.find((e: any) => e.id == params.row.id))}
            fontSize="small"
          />
        ),
    },
  ];

  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    getValues: getValuesSearchForm,
  } = useForm();
  const {
    register: userForm,
    handleSubmit: submitUserForm,
    reset: resetUserForm,
  } = useForm();
  const {
    register: codeForm,
    handleSubmit: submitcodeForm,
    reset: resetcodeForm,
  } = useForm();
  const {
    register: adminForm,
    errors: errorsAdminForm,
    handleSubmit: submitAdminForm,
    reset: resetAdminForm,
  } = useForm();

  const [users, setUsers] = useState(User);
  const [showAmbassedor, setShowAmbassedor] = useState(false);
  const [showError, setshowError] = useState(false);
  const [currentEditUser, setCurrentEditUser] = useState<any>({});
  const [currentAddReferralUser, setCurrentAddReferralUser] = useState<any>({});
  const [searchResult, setSearchResult] = useState(false);
  const [page, setPage] = useState(0);
  const [RowCount, setRowCount] = useState<any>(0);
  const [showUserFrom, setShowUserFrom] = useState(false);
  const [showReferralFrom, setShowReferralFrom] = useState(false);
  const [isUserProfile, setIsUserProfile] = useState(true);
  const [showAdminForm, setShowAdminForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [userProfile, setUserProfile] = useState<any>({});
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [codeErre, setcodeErre] = useState(false);
  const [codeErreMes, setcodeErreMes] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const CloseUserFrom = () => {
    setIsEdit(false);
    setShowUserFrom(false);
  };

  const closeAdminForm = () => {
    setIsEdit(false);
    setShowAdminForm(false);
    resetAdminForm(initialState, {
      errors: false,
      isDirty: true,
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
    });
  };

  const OpenAdminForm = () => {
    setShowAdminForm(true);

    resetAdminForm(initialState, {
      errors: false,
      isDirty: true,
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
    });
  };

  const search = (data: any) => {
    const userSearchBy = JSON.stringify(data);
    debugger;
    data.pageNumber = page;
    data.pageSize = 10;
    localStorage.setItem("user-search-by", userSearchBy);
    setSearchResult(false);
    if (data.role == "Admin") {
      setShowAdmin(true);
      setIsAdmin(true);
    } else {
      setShowAdmin(false);
      setIsAdmin(false);
    }
    UserService.searchEmail(data).then((res) => {
      console.log(JSON.stringify(data));
      setRowCount(res.data.result.totalRecordCount);
      setUsers(res.data.result.searchResultData);
      setSearchResult(true);
    });
  };

  const pagination = (page: any) => {
    setPage(page);
    let userSearch = localStorage.getItem("user-search-by");
    // @ts-ignore
    let data = JSON.parse(userSearch);
    data.pageNumber = page;
    data.pageSize = 10;
    UserService.searchEmail(data).then((res) => {
      setRowCount(res.data.result.totalRecordCount);
      setUsers(res.data.result.searchResultData);
      setSearchResult(true);
    });
  };

  const closeUserProfile = () => {
    setIsUserProfile(true);
    setShowUserProfile(false);
  };

  const profile = (user: any) => {
    setUserProfile(user);
    setShowUserProfile(true);
  };

  const edit = (user: any) => {
    let subscriptionType = "";
    setshowError(false);

    // if (user.role.name == "Owlet") {
    //   if (user.subscriptionType === null || user.subscriptionType === "") {
    //     subscriptionType = user.subscriptionType = "Paid";
    //   } else {
    //     subscriptionType = user.subscriptionType;
    //   }
    // }
    // if (user.role.name == "Owwll") {
    //   if (user.subscriptionType === null || user.subscriptionType === "") {
    //     subscriptionType = "LifeTimeFree";
    //   } else {
    //     subscriptionType = user.subscriptionType;
    //   }
    // }
    subscriptionType = user.subscriptionType;
    setCurrentEditUser(user);
    setShowAmbassedor(user.isBrandAmbassador);
    setIsEdit(true);
    if (user.role == "Admin") {
      OpenAdminForm();
      resetAdminForm({
        fullName: user.fullName,
        id: user.id,
        email: user.email,
        role: user.role,
        isActive: user.isActive,
      });
    } else {
      setShowUserFrom(true);
      resetUserForm({
        id: user.id,
        email: user.email,
        isActive: user.isActive,
        isFeatured: user.isFeatured,
        isBrandAmbassador: user.isBrandAmbassador,
        brand_Ambassador: user.brand_Ambassador,
        subscriptionType: subscriptionType,
      });
    }
  };

  const AddReferral = (user: any) => {
    setcodeErre(false);
    setshowError(false);
    setCurrentAddReferralUser(user);
    setShowAmbassedor(user.isBrandAmbassador);
    setIsEdit(true);
    if (user.role == "Admin") {
      OpenAdminForm();
      resetAdminForm({
        fullName: user.fullName,
        id: user.id,
        email: user.email,
        role: user.role,
        isActive: user.isActive,
      });
    } else {
      setShowReferralFrom(true);
      resetcodeForm({
        id: user.id,
        email: user.email,
        isActive: user.isActive,
        isFeatured: user.isFeatured,
        isBrandAmbassador: user.isBrandAmbassador,
        brand_Ambassador: user.brand_Ambassador,
      });
    }
  };

  const onSubmitCode = (code: any) => {
    var data = {
      fullName: getValuesSearchForm("fullName"),
      role: getValuesSearchForm("role"),
      isActive: getValuesSearchForm("isActive"),
    };
    UserService.AddReferralCode(code)
      .then((res) => {
        setcodeErre(res.data.isError);
        setcodeErreMes(res.data.message);

        if (res.data.isError === false) {
          setShowReferralFrom(false);
          setcodeErre(false);
          notifyUpdated();
          UserService.searchEmail(data).then((res) => {
            setRowCount(res.data.result.totalRecordCount);
            setUsers(res.data.result.searchResultData);
            setShowUserFrom(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitUser = (user: any) => {
    UserService.update(user)
      .then((res) => {
        if (res.data == "updated") {
          notifyUpdated();
        } else {
          Utili.notifyError();
        }
        var data = {
          fullName: getValuesSearchForm("fullName"),
          role: getValuesSearchForm("role"),
          isActive: getValuesSearchForm("isActive"),
        };
        UserService.searchEmail(data).then((res) => {
          setRowCount(res.data.result.totalRecordCount);
          setUsers(res.data.result.searchResultData);
          setShowUserFrom(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitAdmin = (user: any) => {
    var data = {
      fullName: getValuesSearchForm("fullName"),
      role: getValuesSearchForm("role"),
      isActive: getValuesSearchForm("isActive"),
    };
    if (isEdit) {
      UserService.updateAdmin(user).then((res) => {
        if (res.data == "Admin updated") {
          notifyUpdated();
          UserService.searchEmail(data)
            .then((res) => {
              setRowCount(res.data.result.totalRecordCount);
              setUsers(res.data.result.searchResultData);
            })
            .catch((err) => {
              Utili.notifyError();
            });
        } else {
          Utili.notifyError();
        }
      });
    } else {
      UserService.create(user).then((res) => {
        if (res.data.message == "userCreated") {
          notifyUpdated();
          UserService.searchEmail(data)
            .then((res) => {
              setRowCount(res.data.result.totalRecordCount);
              setUsers(res.data.result.searchResultData);
            })
            .catch(() => {
              Utili.notifyError();
            });
        }
      });
    }
    closeAdminForm();
  };

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    filename: "owwllusers",
    showTitle: false,
    title: "All Users",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  const csvExporter = new ExportToCsv(options);
  const GetCSVFile = () => {
    UserService.getAllUser().then((res) => {
      csvExporter.generateCsv(res.data.result);
    });
  };

  const notifyUpdated = () =>
    toast.success(
      isEdit ? "Record updated successfully!" : "Admin added successfully!",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  useEffect(() => {
    let userSearch = localStorage.getItem("user-search-by");
    if (userSearch != null) {
      // @ts-ignore
      let data = JSON.parse(userSearch);
      data.pageNumber = 0;
      data.pageSize = 10;
      setValuesSearchForm("role", data.role);
      setValuesSearchForm("isActive", data.isActive);
      setValuesSearchForm("fullName", data.fullName);

      UserService.searchEmail(data).then((res) => {
        setRowCount(res.data.result.totalRecordCount);
        setUsers(res.data.result.searchResultData);
        setSearchResult(true);
        if (data.role == "Admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      });
    } else {
      setValuesSearchForm("role", "Admin");
      setValuesSearchForm("isActive", true);
    }
  }, []);

  return (
    <>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "searchEmail",
          "addReferralCode",
          "getuserprofile",
          "createadmin",
          "updateuser",
          "getUserBonusTranscationHistory",
          "getAllUser",
          "updateadmin",
          "addTopupBonus",
          "getTopUpBonuses",
          "GetPreference",
          "AddPreference",
          "getOfficeHours",
        ]}
        inInternal={false}
      />
      <>
        <h3 className="mb-3">
          {" "}
          Manage User
          <Button
            className="float-right export-btn primary"
            onClick={GetCSVFile}
          >
            Export All Users
          </Button>
        </h3>
        <h4 className="mb-0 heading">
          Search
          <Button
            variant="contained"
            onClick={OpenAdminForm}
            className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right pearl-black-btn"
          >
            Add Admin
          </Button>
        </h4>
        <div className="p-3 border">
          <form
            autoComplete="off"
            className="form-inline u-mang-form"
            noValidate
            onSubmit={submitSearchForm(search)}
          >
            <div className="form-group">
            <div className="form-group mb-2 inputs-wrap">
              <input
                className="form-control form-control-sm"
                type="name"
                name="fullName"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Name"
              />
               <input
                className="form-control form-control-sm"
                type="email"
                name="email"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Email"
              />
               <label className="m-2">Account Type</label>
               <select
               className="drop"
               ref={searchForm({ required: false })}
                    
                    name="subscription"
                    id="subscription"
                  >
                   <option value="" selected>All</option>
                   <option value="Paid">Paid</option>
                    <option value="MonthlyFree">One Month Free</option>
                    <option value="LifeTimeFree">Lifetime Free</option>
                    
                  </select>
            </div>
             
            </div>
            <div className="form-group mb-2">
            <label className="m-2">Type</label>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value="Admin"
                  id="Admin"
                  ref={searchForm}
                  name="role"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="Admin">
                  Administrator
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value="Standard"
                  id="Standard"
                  ref={searchForm}
                  name="role"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="Standard">
                Standard
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value="Premium"
                  id="Premium"
                  ref={searchForm}
                  name="role"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="Premium">
                Premium
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value="IsAmbassador"
                  id="IsAmbassador"
                  ref={searchForm}
                  name="role"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="IsAmbassador">
                  Brand Ambassador
                </label>
              </div>

              <div className="btn-enabled">
                <div className="custom-control custom-checkbox ">
                  <label>
                    <input ref={searchForm} name="isActive" type="checkbox" />
                    &nbsp; Enabled
                  </label>
                </div>
                <div className="custom-control custom-checkbox ">
                  <label>
                    <input ref={searchForm} name="isDeleted" type="checkbox" />
                    &nbsp; Deleted
                  </label>
                </div>
                <div className="float-right">
                  <Button
                    type="submit"
                    variant="contained"
                    
                    className="btn search-btn float-right ml-3 primary"
                  >
                    <i className="fa fa-search"></i> Search
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {searchResult ? (
          <div className="mt-2">
            <h5 className="mb-0 heading"> Search Result</h5>
            <div className="gridWrapper data-grid">
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                columns={
                  getValuesSearchForm("role") == "Admin"
                    ? columnsAdmin
                    : getValuesSearchForm("role") == "Owlet"
                    ? columnsOwlet
                    : getValuesSearchForm("role") == "IsAmbassador"
                    ? columnsAmbassador
                    : columns
                }
                rows={users}
                pageSize={10}
                pagination
                rowHeight={41}
                autoHeight={true}
                rowCount={RowCount}
                paginationMode="server"
                onPageChange={(newPage) => pagination(newPage)}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </>
      <Modal show={showUserProfile} onHide={closeUserProfile} size="xl">
        <Modal.Body>
          <div className="tabi-nav modal-header">
            <a
              className={
                "modal-title h4 hand" + (isUserProfile == true ? " active" : "")
              }
              onClick={() => setIsUserProfile(true)}
            >
              User Profile
            </a>
            <a
              className={
                "modal-title h4 hand" +
                (isUserProfile == false ? " active" : "")
              }
              onClick={() => setIsUserProfile(false)}
            >
              Top-up
            </a>
          </div>
          {isUserProfile ? (
            <UserProfile {...props} UserProfile={userProfile} />
          ) : (
            <TopUp {...props} UserProfile={userProfile} />
          )}
          <Button
            onClick={closeUserProfile}
            variant="contained"
            className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right m-4"
            size="small"
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={showUserFrom} onHide={CloseUserFrom}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            onSubmit={submitUserForm(onSubmitUser)}
            className="mt-3 ml-2"
            noValidate
          >
            <div className="row pb-1">
              <div className="col-3">
                <label className="lbl">Name</label>
              </div>
              <div className="col-8">
                <input id="id" name="id" type="hidden" ref={userForm} />
                <input
                  value={currentEditUser.fullName}
                  name="fullName"
                  type="hidden"
                  ref={userForm}
                />
                <input
                  value={currentEditUser.email}
                  name="email"
                  type="hidden"
                  ref={userForm}
                />
                <p className="m-0">{currentEditUser.fullName}</p>
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-3">
                <label className="lbl">Email</label>
              </div>
              <div className="col-8">
                <p className="m-0">{currentEditUser.email}</p>
              </div>
            </div>
            {isAdmin ? (
              ""
            ) : (
              <div className="row pb-1">
                <div className="col-3">
                  <label className="lbl">Ambassador</label>
                </div>
                <div className="col-8">
                  <select
                    style={{ width: 200, outline: 0 }}
                    ref={userForm}
                    name="brand_Ambassador"
                    id="brand_Ambassador"
                  >
                    <option value="Not an Ambassador" selected>
                      {" "}
                      Not an Ambassador{" "}
                    </option>
                    <option value="Bronze">Bronze</option>
                    <option value="Silver">Silver</option>
                    <option value="Gold">Gold</option>
                  </select>
                </div>
              </div>
            )}

            {isAdmin ? (
              ""
            ) : currentEditUser.subscriptionStatus == "Active" &&
              currentEditUser.subscriptionType == "Paid" &&
              getValuesSearchForm("role") == "Owwll" ? (
              <div className="row pb-1" hidden>
                <div className="col-3">
                  <label className="lbl">Free Trial</label>
                </div>
                <div className="col-8">
                  <select
                    style={{ width: 200, outline: 0 }}
                    ref={userForm}
                    name="subscriptionType"
                    id="subscriptionType"
                  >
                    <option value="Paid" selected>
                      {" "}
                      Not Offered{" "}
                    </option>

                    <option value="MonthlyFree">One Month</option>
                    <option value="LifeTimeFree">Lifetime</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="row pb-1">
                <div className="col-3">
                  <label className="lbl">Free Trial</label>
                </div>
                <div className="col-8">
                  <select
                    style={{ width: 200, outline: 0 }}
                    ref={userForm}
                    name="subscriptionType"
                    id="subscriptionType"
                  >
                    <option value="Paid" selected>
                      {" "}
                      Not Offered{" "}
                    </option>

                    <option value="MonthlyFree">One Month</option>
                    <option value="LifeTimeFree">Lifetime</option>
                  </select>
                </div>
              </div>
            )}

            <div className="row pb-2">
              <div className="col-3"></div>
              <div className="col-8">
                <div>
                  <label>
                    {" "}
                    <input
                      ref={userForm}
                      name="isActive"
                      type="checkbox"
                    />{" "}
                    Enabled{" "}
                  </label>
                  {showAdmin || currentEditUser.role == "Owlet" ? (
                    ""
                  ) : (
                    <label>
                      &nbsp;{" "}
                      <input ref={userForm} name="isFeatured" type="checkbox" />{" "}
                      Featured{" "}
                    </label>
                  )}
                </div>

                <br />
                <Button
                  type="submit"
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                  style={{ background: "#bfa056", color: "#fff" }}
                >
                  Save
                </Button>
                <Button
                  onClick={CloseUserFrom}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showReferralFrom} onHide={() => setShowReferralFrom(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Referral Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            onSubmit={submitcodeForm(onSubmitCode)}
            className="mt-3 ml-2"
            noValidate
          >
            {currentAddReferralUser.referredBy?.fullName != "" ? (
              <div className="row pb-1">
                <div className="col-4">
                  <label className="lbl">Referred By</label>
                </div>
                <div className="col-8">
                  <p className="m-0">
                    {currentAddReferralUser.referredBy?.fullName}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {currentAddReferralUser.referredBy?.fullName != "" ? (
              <div className="row pb-1">
                <div className="col-4">
                  <label className="lbl">Referral Code</label>
                </div>
                <div className="col-8">
                  <input
                    defaultValue={
                      currentAddReferralUser.referredBy?.referralCode
                    }
                    placeholder="Enter Code"
                    name="code"
                    ref={codeForm({ required: true })}
                    style={{ width: 200, outline: 0 }}
                    disabled={currentAddReferralUser.referredBy?.fullName != ""}
                  />
                  {codeErre ? (
                    <p style={{ color: "red" }}>{codeErreMes}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="row pb-1">
                <div className="col-4">
                  <label className="lbl">Referral Code</label>
                </div>
                <div className="col-8">
                  <input
                    id="id"
                    defaultValue={currentAddReferralUser.id}
                    hidden
                    name="id"
                    ref={codeForm}
                  />
                  <input
                    placeholder="Enter Code"
                    name="code"
                    ref={codeForm({ required: true })}
                    style={{ width: 200, outline: 0 }}
                    disabled={currentAddReferralUser.referredBy?.fullName != ""}
                  />
                  {codeErre ? (
                    <p style={{ color: "red" }}>{codeErreMes}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            <div className="row pb-2">
              <div className="col-3"></div>
              <div className="col-8">
                <br />
                {currentAddReferralUser.referredBy?.fullName != "" ? (
                  ""
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                    size="small"
                    style={{ background: "#bfa056", color: "#fff" }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  onClick={() => setShowReferralFrom(false)}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showAdminForm} onHide={closeAdminForm}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Admin" : "Add Admin"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            onSubmit={submitAdminForm(onSubmitAdmin)}
            className="mt-3 ml-2"
            noValidate
          >
            <div className="row pb-1">
              <div className="col-3">
                <label className="lbl">Name</label>
              </div>
              <div className="col-8">
                <input id="id" name="id" type="hidden" ref={adminForm} />
                <input
                  name="fullName"
                  ref={adminForm({ required: true })}
                  className="form-control form-control-sm"
                  placeholder="Name"
                  style={{ backgroundColor: "#fff" }}
                />
                {errorsAdminForm.fullName && (
                  <small className="text-danger">
                    You must specify a Full Name
                  </small>
                )}
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-3">
                <label className="lbl">Email</label>
              </div>
              <div className="col-8">
                {isEdit ? (
                  <p className="m-0 mt-1">{currentEditUser.email}</p>
                ) : (
                  <input
                    name="email"
                    ref={adminForm({
                      required: "You must specify a email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    className="form-control form-control-sm"
                    style={{ backgroundColor: "#fff" }}
                    placeholder="Email"
                  />
                )}
                {errorsAdminForm.email && (
                  <small className="text-danger">
                    {errorsAdminForm.email.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-3">
                <label className="lbl">Password</label>
              </div>
              <div className="col-8">
                {isEdit ? (
                  <input
                    name="password"
                    ref={adminForm({
                      pattern: {
                        value:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                        message:
                          "Must contain at least one upper and lowercase letter and at least one digit and at least one special character, and at least 8 or more characters",
                      },
                    })}
                    className="form-control form-control-sm"
                    style={{ backgroundColor: "#fff" }}
                    type="password"
                    placeholder="********"
                  />
                ) : (
                  <input
                    name="password"
                    ref={adminForm({
                      required: "Password is required",
                      pattern: {
                        value:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                        message:
                          "Must contain at least one upper and lowercase letter and at least one digit and at least one special character, and at least 8 or more characters",
                      },
                    })}
                    className="form-control form-control-sm"
                    style={{ backgroundColor: "#fff" }}
                    type="password"
                    placeholder="********"
                  />
                )}
                {errorsAdminForm.password && (
                  <small className="text-danger">
                    {errorsAdminForm.password.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-3"></div>
              <div className="col-8 pb-2">
                <label className="pt-2">
                  <input ref={adminForm} name="isActive" type="checkbox" />{" "}
                  Enabled
                </label>
                <br />
                <Button
                  type="submit"
                  style={{ background: "#bfa056", color: "#fff" }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Save
                </Button>
                <Button
                  onClick={closeAdminForm}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ManageUser;
