import { Button } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowsProp } from "@material-ui/data-grid";
import React from "react";
import { useEffect, useState } from "react";
import moment from 'moment-timezone';
import { Modal } from "react-bootstrap";
import WithdrawalService from "../../../services/withdrawal/WithdrawalService";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import "./Withdrawals.css";

import Utili from "../../../utility/Utility";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const Withdrawals: any = [];

function Withdrawls(props: any) {

  const [showGrid, setShowGrid] = useState(true);

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    {
      field: "fullName",
      headerName: "Name",
      width: 230,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "requestNumber",
      headerName: "Request #",
      headerAlign: "center",
      width: 180,
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      align: "center",
      
      headerAlign: "center",
      renderCell: (params: any) => (
        <>{Utili.formatAmount(params.row.amount)}</>
      ),
    },
    {
      field: "createdOn",
      headerName: "Date",
      width: 160,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) =>
        moment(params.row?.createdOn).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          type="button"
          onClick={() =>
            review(withdrawals.find((e: any) => e.id == params.row.id))
          }
          variant="contained"
          className="MuiButton-textSizeSmall primary"
        >
          {params.row.status == "Closed" || params.row.status == "Rejected" || params.row.status == "Processed"
            ? "View"
            : "Review"}
        </Button>
      ),
    },
  ];

  const { register, handleSubmit, reset, errors } = useForm();

  const [withdrawals, setWithdrawals] = useState(Withdrawals);
  const [currentWithdrawals, setCurrentWithdrawals] = useState<any>({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    reset: resetSearchFrom,
    watch,
    control: contorlSearch,
    getValues: getValuesSearchForm,
  } = useForm();

  useEffect(() => {
    setValuesSearchForm("status", "Pending");
    

    WithdrawalService.getAll("Pending")
      .then((res) => {
        setWithdrawals(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getWd = () => {
    setWithdrawals(Withdrawals);
    setShowGrid(false);
    WithdrawalService.getAll(getValuesSearchForm("status"))
      .then((res) => {
        setWithdrawals(res.data.result);
        setShowGrid(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const review = (wd: any) => {
    setCurrentWithdrawals(wd);
    handleShow();
  };
  const submit = (data: any, status: any) => {
    var date = moment();
    data.id = currentWithdrawals.id;
    data.status = status;
    if (
      currentWithdrawals.comments != "" &&
      currentWithdrawals.comments != null &&
      currentWithdrawals.comments != undefined
    ) {
      data.comments =
        currentWithdrawals.comments +
        "\n" +
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") +
        " - " +
        data.comments;
    } else {
      
      data.comments =
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") + " - " + data.comments;
    }
    WithdrawalService.save(data)
      .then((res) => {
        WithdrawalService.getAll(getValuesSearchForm("status"))
          .then((res) => {
            setWithdrawals(res.data.result);
            handleClose();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Message)
          toast.error(
            err.response.data.Message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
      });
  };

  return (
   
        
      <div className="col-10 mt-4">
      <SpinnerLoader isGeneral={true} tasksList={
        ['getwithdrawals','updatewithdrawal']
      } inInternal={false} />
        <h3 className="mb-3">Withdrawals</h3>
        <div className="">
          <form autoComplete="off" className="form-inline" noValidate>
            <div className="form-group mb-2">
              <div className="p-2">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="All"
                    id="all"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                    onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="all">
                    All
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Pending"
                    id="pending"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                    onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="pending">
                    Pending
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Processed"
                    id="processed"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                    onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="processed">
                    Processed
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Closed"
                    id="closed"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                    onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="closed">
                    Closed
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Rejected"
                    id="rejected"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                    onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="rejected">
                    Rejected
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div style={{ paddingBottom: "40px", width: "100%" }}>
          <h5 className="mb-0 heading">Requests</h5>
         { showGrid ? 
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            columns={columns}
            rows={withdrawals}
            pageSize={10}
            autoHeight={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          /> : '' }
        </div>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Withdrawals Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="col-12 mt-2" autoComplete="off" noValidate>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Name</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.fullName}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Request Number</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.requestNumber}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Amount</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {Utili.formatAmount(currentWithdrawals.amount)}
                  </p>
                </div>
                <div className="col-2">
                  <label className="lbl">Created On</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {moment(currentWithdrawals.createdOn).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Status</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.status}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Previous Comments</label>
                </div>
                <div className="col-10">
                  <p className="">{currentWithdrawals.comments}</p>
                </div>
              </div>
              {currentWithdrawals.status == "Rejected" ||
              currentWithdrawals.status == "Closed" ? (
                ""
              ) : (
                <div className="form-group">
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Comments</label>
                      <span className="text-danger asterisk-bx"> *</span>
                    </div>
                    <div className="col-10">
                      <textarea
                        name="comments"
                        ref={register({ required: true })}
                        className="form-control"
                        placeholder="comments"
                      />

                      {/* {errors.comments && (
                        <span className="text-danger">
                          Comments are required
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              )}
              <br />
              {/* <div className="row">
                <div className="col-md-10 offset-2">
                  <span className="text-danger">Field with * are required.</span>
                </div>
              </div> */}
              <div className="row">
                <div className="col-2"></div>
                <div className="col-10">
                  <div className="pb-4">
                  {
                      currentWithdrawals.status != "Rejected" &&
                      currentWithdrawals.status != "Closed" ? 
                      (
                        <span className="pull-left text-danger">
                        All fields are required with Asterisk (
                        <span className="asterisk-bx text-danger">*</span>)
                        </span>
                      ) :
                      ""
                  }

                    <div className="pull-right">
                      <Button
                        className="mr-2"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      {currentWithdrawals.status == "Rejected" || currentWithdrawals.status == "Processed" ||
                      currentWithdrawals.status == "Closed" ? (
                        ""
                      ) : (
                        <Button
                          variant="contained"
                          style={{ background: "#bfa056", color: "#fff" }}
                          onClick={handleSubmit((data) =>
                            submit(data, "Rejected")
                          )}
                        >
                          Reject
                        </Button>
                      )}
                      {currentWithdrawals.status == "Pending" ? (
                        <Button
                          className="ml-2"
                          style={{ background: "#bfa056", color: "#fff" }}
                          variant="contained"
                          onClick={handleSubmit((data) =>
                            submit(data, "Processed")
                          )}
                        >
                          Process
                        </Button>
                      ) : (
                        ""
                      )}
                      {currentWithdrawals.status == "Processed" ? (
                        <Button
                          className="ml-2"
                          style={{ background: "#bfa056", color: "#fff" }}
                          variant="contained"
                          onClick={handleSubmit((data) =>
                            submit(data, "Closed")
                          )}
                        >
                          Close
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
   
  );
}

export default Withdrawls;
