import http from "../HttpInterceptor";

const getAllCategories = () => {
  return http.get("gateway/GetAllCategories", {
    headers: { task_name: "GetAllCategories" },
  });
};

const getAllHobbies = () => {
  return http.get("gateway/GetAllHobbies", {
    headers: { task_name: "GetAllHobbies" },
  });
};

const getAllSourceOptions = () => {
  return http.get("gateway/GetAllSourceOptions", {
    headers: { task_name: "GetAllSourceOptions" },
  });
};

const SaveCategory = (data: any) => {
  return http.post("gateway/SaveCategory", data, {
    headers: { task_name: "SaveCategory" },
  });
};

const SaveHobby = (data: any) => {
  return http.post("gateway/SaveHobby", data, {
    headers: { task_name: "SaveHobby" },
  });
};

const SaveSource = (data: any) => {
  return http.post("gateway/SaveSource", data, {
    headers: { task_name: "SaveSource" },
  });
};

export default {
  SaveSource,
  getAllSourceOptions,
  getAllCategories,
  getAllHobbies,
  SaveCategory,
  SaveHobby,
};
