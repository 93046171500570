import http from "../HttpInterceptor";

const GetOnlineAvailableUsersCounts = () => {
    return http.get("gateway/GetOnlineAvailableUsersCounts", {
      headers: { 'task_name': 'getOnlineUsersCount' }
    });
  };

export default {
    GetOnlineAvailableUsersCounts,
};