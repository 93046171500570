const Platform = [
  {
    name: "",
    expense: 0,
    revenue: 0,
    profit: 0
  },
  {
    name: "Wk-1",
    expense: 2800,
    revenue: 2400,
    profit: 400
  },
  {
    name: "Wk-2",
    expense: 3000,
    revenue: 1398,
    profit:1400
  },
  {
    name: "Wk-3",
    expense: 2000,
    revenue: 9800,
    profit:7800
  },
  {
    name: "Wk-4",
    expense: 2780,
    revenue: 3908,
    profit:1172
  },
  // {
  //   name: "May",
  //   expense: 1890,
  //   revenue: 4800,
   
  // },
  // {
  //   name: "Jun",
  //   expense: 2390,
  //   revenue: 3800,
  // },
  // {
  //   name: "July",
  //   expense: 3490,
  //   revenue: 4300,
  // },
  // {
  //   name: "Aug",
  //   expense: 8990,
  //   revenue: 5300,
  // },
  // {
  //   name: "Sept",
  //   expense: 3390,
  //   revenue: 4700,
  // },
  // {
  //   name: "Oct",
  //   expense: 1090,
  //   revenue: 8700,
  // },
  // {
  //   name: "Nov",
  //   expense: 2490,
  //   revenue: 4300,
  // },
  // {
  //   name: "Dec",
  //   expense: 890,
  //   revenue: 1800,
  // },
];

export default Platform;
