import React from 'react'
const TopSignUpEarner = (props: any) => {
  return (
    <>
      <h3>Top Sign-up Referral Earners</h3>
      <table style={{ width: "100%" }}>
        <tr>
          <th style={{ width: "25%" }}>Rank</th>
          <th style={{ width: "50%" }} >User</th>
          <th style={{ width: "25%" }}>Earning ($)</th>
        </tr>
        {
          props.topSignUpEarner.map((data: any) => {
            return (
              <>
                <tr>
                  <td>{data.rank}</td>
                  <td>{data.userFullName}</td>
                  <td>{data.earning}</td>
                </tr>
              </>
            )
          })
        }
      </table>
    </>
  )
}

export default TopSignUpEarner