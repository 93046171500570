import React, { useEffect, useState } from "react";
import Utility from "../../utility/Utility";
import ClubHouse from "./../../images/clubhouse.png";
import TikTok from "./../../images/ticktok.png";
import Website from "./../../images/website.png";
import Calender from "./../../images/calendar.png";
import Youtube from "./../../images/youtube.png";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import UserService from "../../services/user-service/UserService";
function UserProfile(props: any) {
  console.log(props.UserProfile);
  const [userProfile, setUserProfile] = useState<any>({});
  const [userOfficeHours, setUserofficeHours] = useState<any>({});
  const [PremimumAccountOwwll, setPremimumAccountOwwll] = useState<any>("");
  const shortDays: any = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
  useEffect(() => {
    UserService.getProfile(props.UserProfile.id)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result.role === "Owwll") {
          if (
            (res.data.result.subscriptionType == "MonthlyFree" ||
              res.data.result.subscriptionType == "Paid") &&
            res.data.result.subscriptionStatus == "Active"
          ) {
            if (res.data.result.subscriptionType == "MonthlyFree") {
              res.data.result.subscriptionType = "One Month Free";
            }

            setPremimumAccountOwwll(
              " (" +
                res.data.result.subscriptionType +
                ", expiring at " +
                moment(res.data.result.subscriptionExpireOn).format(
                  "MM/DD/YYYY"
                ) +
                ")"
            );
          }
          if (
            (res.data.result.subscriptionType === null ||
              res.data.result.subscriptionType === "" ||
              res.data.result.subscriptionType === "LifeTimeFree") &&
            (res.data.result.subscriptionStatus === null ||
              res.data.result.subscriptionStatus === "" ||
              res.data.result.subscriptionStatus === "Active")
          ) {
            res.data.result.subscriptionStatus = "Active";
            setPremimumAccountOwwll("(Lifetime Free)");
          }
          if (
            (res.data.result.subscriptionType == null ||
              res.data.result.subscriptionType == "") &&
            (res.data.result.subscriptionStatus == null ||
              res.data.result.subscriptionStatus == "")
          ) {
            setPremimumAccountOwwll("(Lifetime Free)");
            res.data.result.subscriptionStatus = "Active";
          }
        }
        if (res.data.result.role === "Owlet") {
          if (res.data.result.subscriptionType == "MonthlyFree") {
            res.data.result.subscriptionType = "(One Month Free)";
          }

          if (res.data.result.subscriptionType == "LifeTimeFree") {
            res.data.result.subscriptionType = "(Lifetime)";
          }
        }
        setUserProfile(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });

    UserService.GetOfficeHours(props.UserProfile.id)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result) {
          setUserofficeHours(res.data.result);
        } else {
          setUserofficeHours(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="">
        {userProfile.role === "Owlet" ? (
          <>
            <div
              className="h5 mt-1"
              style={{ color: "black", marginLeft: "35px" }}
            >
              <b>Credit Balance </b>(Deposits - Calls):{" "}
              <b style={{ fontFamily: "sans-serif" }}>
                ${userProfile.balanceAmount}
              </b>{" "}
            </div>
            <div
              className="h5"
              style={{
                color: "black",
                marginLeft: "880px",
                marginTop: "-30px",
              }}
            >
              <b>Bonus Balance </b>:{" "}
              <b style={{ fontFamily: "sans-serif" }}>
                ${userProfile.bonusAmount}
              </b>{" "}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              className="h5 mt-1"
              style={{ color: "black", marginLeft: "35px" }}
            >
              <b>Credit Balance </b>(Deposits + Earnings - Calls - Withdrawals):{" "}
              <b style={{ fontFamily: "sans-serif" }}>
                ${userProfile.balanceAmount}
              </b>{" "}
            </div>
            <div
              className="h5"
              style={{
                color: "black",
                marginLeft: "880px",
                marginTop: "-30px",
              }}
            >
              <b>Bonus Balance </b>:{" "}
              <b style={{ fontFamily: "sans-serif" }}>
                ${userProfile.bonusAmount}
              </b>{" "}
            </div>
          </>
        )}

        {userProfile.role == "Owwll" && (
          <div
            className="h5 mt-1 row"
            style={{ color: "black", marginLeft: "35px" }}
          >
            <b>Subscription Type:</b>
            <b style={{ marginLeft: "10px", marginRight: "10px" }}>
              {userProfile.subscriptionAccount}{" "}
            </b>
            {userProfile.subscriptionAccount === "Premium" && (
            <span>{PremimumAccountOwwll}</span>
            )}
            
          </div>
          
        )}

         {/* {userProfile.role == "Owwll" && (
          <div
            className="h5 mt-1 row"
            style={{ color: "black", marginLeft: "35px" }}
          >
            <b>Subscription Type:</b>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              {userProfile.subscriptionAccount}{" "}
            </span>
            

            
          </div>
          
        )} */}

        {userProfile.role == "Owlet" && (
          <div
            className="h5 mt-1 row"
            style={{ color: "black", marginLeft: "35px" }}
          >
            <b>Premium Account</b>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              {"(Trial Offered?)"}
            </span>
            {userProfile.subscriptionType === null ||
            userProfile.subscriptionType === "Paid" ? (
              <b>No </b>
            ) : (
              <div className="row">
                <b>Yes </b>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {userProfile.subscriptionType}
                </span>
              </div>
            )}{" "}
          </div>
        )}
      </div>
      <hr />
      <div className="modal-body-bx">
        <div className="row">
          <div className="col-2">
            <label className="lbl">Name</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.fullName}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Email</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.email}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">Title</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.title}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Company/Organization</label>
          </div>
          <div className="col-3">
            <p className="">
              {userProfile.organization != null &&
              userProfile.organization != ""
                ? userProfile.organization
                : userProfile.occupation}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">Enable</label>
          </div>
          <div className="col-3">
            <p className="">
              {userProfile.isActive ? <CheckIcon /> : <CloseIcon />}
            </p>
          </div>
          <div className="col-2">
            <label className="lbl">Featured</label>
          </div>
          <div className="col-3">
            <p className="">
              {userProfile.isFeatured ? <CheckIcon /> : <CloseIcon />}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <label className="lbl">Ambassador</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.brand_Ambassador}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Referral Code</label>
          </div>
          <div className="col-3">
            <p style={{ fontFamily: "sans-serif" }}>
              {userProfile.referralCode}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">Date of Signup</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.created_on}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Date of referral</label>
          </div>
          <div className="col-3">
            {userProfile.referredBy?.fullName != null ? (
              <p>{userProfile.referredBy?.referredOn}</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <label className="lbl">How did you hear about us?</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.sourceOption}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Referred By</label>
          </div>
          <div className="col-3">
            {userProfile.referredBy?.fullName != null ? (
              <p className="">
                {userProfile.referredBy?.fullName}
                <span style={{ fontFamily: "sans-serif" }}>
                  ({userProfile.referredBy?.referralCode})
                </span>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">City</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.city}</p>
          </div>
          <div className="col-2">
            <label className="lbl">State</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.state}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">College</label>
          </div>
          <div className="col-3">
            <p className="">{userProfile.collegeAttend}</p>
          </div>
          <div className="col-2">
            <label className="lbl">Date of Birth</label>
          </div>
          <div className="col-3">
            <p className="">
              {moment(userProfile.dob).format("MM/DD/YYYY") != "01/01/0001"
                ? moment(userProfile.dob).format("MM/DD/YYYY")
                : null}
            </p>
          </div>
        </div>
        {userProfile.role == "Owwll" && (
          <div className="row">
            <div className="col-2">
              <label className="lbl">Social Profiles</label>
            </div>
            <div className="col-3  sm_wrapper">
              {userProfile.socialProfiles?.map((s: any, i: any) => (
                <span
                  className="slink"
                  onClick={() =>
                    window.open(
                      "https://" + s.url.replace(/^https?\:\/\//i, ""),
                      "_blank"
                    )
                  }
                  key={i}
                >
                  {s.media_type == "ClubHouse" && s.url != "" ? (
                    <img src={ClubHouse} />
                  ) : s.media_type == "TikTok" && s.url != "" ? (
                    <img src={TikTok} />
                  ) : s.media_type == "Website" && s.url != "" ? (
                    <img src={Website} />
                  ) : s.media_type == "Youtube" && s.url != "" ? (
                    <img src={Youtube} />
                  ) : s.media_type == "Facebook" && s.url != "" ? (
                    <Facebook />
                  ) : s.media_type == "Instagram" && s.url != "" ? (
                    <Instagram />
                  ) : s.media_type == "Twitter" && s.url != "" ? (
                    <Twitter />
                  ) : s.media_type == "LinkedIn" && s.url != "" ? (
                    <LinkedIn />
                  ) : (
                    ""
                  )}
                </span>
              ))}
            </div>
            <div className="col-2">
              <label className="lbl">Cell Phone #</label>
            </div>
            <div className="col-3">
              <p className="">{userProfile.phoneNumber}</p>
            </div>
          </div>
        )}
        <div className="row">
            <div className="col-2">
              <label className="lbl">Posts</label>
            </div>
            <div className="col-3  sm_wrapper">
            <p className="">{userProfile.postUserCount}</p>
            </div>
            <div className="col-2">
              <label className="lbl">Introductions</label>
            </div>
            <div className="col-3">
              <p className="">{userProfile.userReferralCount}</p>
            </div>
          </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">Expertise</label>
          </div>
          <div className="col-10">
            <div className="list-wrapper">
              {userProfile.categories?.map((c: any, i: any) => (
                <a href="javascript:void(0);" key={i}>
                  {c}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <label className="lbl">Groups</label>
          </div>
          <div className="col-10">
            <div className="list-wrapper">
              {userProfile.hobbies?.map((c: any, i: any) => (
                <a href="javascript:void(0);" key={i}>
                  {c}
                </a>
              ))}
            </div>
          </div>
        </div>
        {userProfile.role == "Owwll" && (
          <div className="row">
            <div className="col-2">
              <label className="lbl">Call Rates</label>
            </div>
            <div className="col-5">
              <table className="table table-bordered">
                <thead className="tbl-bk">
                  <tr>
                    <th scope="col">Duration</th>
                    <th scope="col">Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {userProfile.callRates?.map((c: any, i: any) => (
                    <tr key={i}>
                      <td className="text-center">{c.duration} min</td>
                      <td className="text-right">
                        {Utility.formatAmount(c.fee)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {userProfile.role == "Owwll" && userOfficeHours != null ? (
          <div className="office-hours-sec">
            <div className="office-hours-title">
              <label className="lbl">Office Hours</label>
            </div>
            <div className="row">
              <div className="col-2">
                <label className="lbl">Office Hours</label>
              </div>
              <div className="col-5">
                <table className="table table-bordered">
                  <thead className="tbl-bk">
                    <tr>
                      <th scope="col">Days</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userOfficeHours.days?.map((c: any, i: any) => (
                      <tr key={i}>
                        <td className="text-left">{shortDays[c.dayOfWeek]}</td>

                        {c.enable && c.fromTime != "" && c.toTime ? (
                          <td className="text-left">
                            {c.fromTime.replace(":00 ", " ") +
                              " - " +
                              c.toTime.replace(":00 ", " ")}
                          </td>
                        ) : (
                          <td className="text-left">N/A</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <label className="lbl">Start Date</label>
              </div>

              <div className="date-row">
                <p className="">
                  {moment(userOfficeHours.startDate).format("MM/DD/YYYY")}
                </p>
                <img src={Calender} />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <label className="lbl">End Date</label>
              </div>
              <div className="date-row">
                <p className="">
                  {moment(userOfficeHours.endDate).format("MM/DD/YYYY")}
                </p>
                <img src={Calender} />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <label className="lbl">Notification</label>
              </div>
              <div className="col-10">
                <p className="">
                  {userOfficeHours.is_notification ? "Enabled" : "Disabled"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-2">
            <label className="lbl">About</label>
          </div>
          <div className="col-10">
            <p className="">{userProfile.describeYourself}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            {
              // @ts-ignore
              userProfile.userSwapStatus?.requestLogs?.map(
                (h: any, index: any) => (
                  <p className="" key={index}>
                    {h.remarks}
                  </p>
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
