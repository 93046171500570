import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import BackNavigate from '../../../components/back-naviagtion/BackNavigate'
import moment from 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert';
import UserService from '../../../services/user-service/UserService';
import { Modal } from 'react-bootstrap';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import CustomNoRowsOverlay from '../../../components/NoRecord';
import Utility from '../../../utility/Utility';
import SpinnerLoader from '../../../components/spinner-loader/SpinnerLoader.component';
function NewRefundRequest() {

    const columns: GridColDef[] = [
        { field: "id", hide: true },
        {
            field: "callEndedAt",
            headerName: "Call End Date",
            width: 160,
            // align: "left",
            // headerAlign: "center",
            valueFormatter: (params) => (
                params.row.callEndedAt &&
                moment(params.row.callEndedAt).format("MM/DD/YYYY hh:mm A")
            ),

        },
        {
            field: "callFromFirstName",
            headerName: "Caller",
            flex: 0.4,
            align: "left",
            headerAlign: "center",
        },
        {
            field: "callToFirstName",
            headerName: "Receiver",
            flex: 0.4,
            align: "left",
            headerAlign: "center",
        },
        {
            field: "requestedDuration",
            headerName: "Request Duration mins",
            width: 120,
            align: "left",
            headerAlign: "center",
        },
        {
            field: "actualDuration",
            headerName: "Actual Duration mins",
            width: 120,
            align: "left",
            headerAlign: "center",
        },
        {
            field: "callStatus",
            headerName: "Transaction Status",
            flex: 0.2,
            align: "left",
            headerAlign: "center",
        },
        {
            field: "col4",
            headerName: " ",
            sortable: false,
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) => (
                <Button
                    style={{ background: "#bfa056", color: "#fff" }}
                    variant="contained"
                    className="MuiButton-textSizeSmall"
                    onClick={() => reView(CallList.find((e: any) => e.callId == params.row.callId))}
                >
                    Select Call
                </Button>
            ),
        },
    ];
    const [callerName, setcallerName] = useState("");
    const [receiverName, setReceiverName] = useState("")
    const [CallList, setCallList] = useState([]);
    const [callDetail, setcallDetail] = useState<any>({});
    const [RefundReason, setRefundReason] = useState([]);
    const [showCallList, setShowCallList] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        reset,
        getValues,
        errors: errors1,
        formState: { errors },
    } = useForm();

    const reView = (data: any) => {
        setcallDetail(data);
        setShowCallList(true);
    };
    const onSearch = () => {
        console.log(callerName + "  " + receiverName);
        let data = {
            caller: callerName,
            receiver: receiverName,
        }
        UserService.GetCallLogsListForRefundAction(data).then((res) => {
            setCallList(res.data)
        })
    }
    const submit = (data: any, status: any) => {
        var date = moment().utc();
        data.status = status;
        data.requestedDate = date;

        setShowCallList(false)
        confirmAlert({
            title: "Confirm",
            message: "Are you sure to create new refund request?",
            buttons: [
                {
                    label: " Confirm",
                    onClick: () => {
                        UserService.UpdateRefundRequest(data).then((res) => {
                            Utility.notifyAdded()
                            let data = {
                                caller: callerName,
                                receiver: receiverName,
                            }
                            UserService.GetCallLogsListForRefundAction(data).then((res) => {
                                setCallList(res.data)
                            })
                        });

                    },
                },
                {
                    label: "Cancel",
                    onClick: () => { },
                },
            ],
        });
    };
    useEffect(() => {
        let data = {
            caller: callerName,
            receiver: receiverName,
        }
        UserService.GetCallLogsListForRefundAction(data).then((res) => {
            setCallList(res.data)
        })
        UserService.GetRefundReasonConfigs().then((res) => {
            setRefundReason(res.data.result)

        })
    }, [])
    return (
        <div className="">
            <SpinnerLoader isGeneral={true} tasksList={
                ['GetCallLogsListForRefundAction', 'UpdateRefundRequest']
            } inInternal={false} />
            <h3 className="mb-3">Refund Request
                <div className="backbutton float-right">
                    <BackNavigate />
                </div>
            </h3>
            <div className=''>
                <h5 className="mb-0 heading">Search</h5>
                <div className="border mb-2">
                    <form className="form-inline u-mang-form ml-4" autoComplete="off" noValidate >
                        <div className="form-group mb-2 ">
                            <label className="">Caller Name </label>
                            <input
                                className="form-control form-control-sm ml-4"
                                type="name"
                                name="fullName"
                                onChange={(data: any) => setcallerName(data.target.value)}
                                style={{ backgroundColor: "#fff" }}
                                placeholder="Caller Name"
                            />

                            <label className=" ml-5">Receiver Name </label>
                            <input
                                className="form-control form-control-sm ml-4"
                                type="name"
                                name="fullName"
                                onChange={(data: any) => setReceiverName(data.target.value)}
                                style={{ backgroundColor: "#fff" }}
                                placeholder="Receiver Name"
                            />
                            <div className="float-right m-4 ">
                                <Button variant="contained" onClick={onSearch} className='primary' >
                                    Search
                                </Button>

                            </div>
                        </div>
                    </form>
                </div>
                <div className="mb-4">
                    <div className="user-table-header heading">
                        <h5 className="mb-0 heading">Call List</h5>

                    </div>
                    <DataGrid
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        columns={columns}
                        rows={CallList}
                        getRowId={(row) => row.callId}
                        pageSize={5}
                        autoHeight={true}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                    />
                </div>
            </div>
            <Modal show={showCallList} onHide={() => setShowCallList(false)} size="lg">
                <Modal.Body>

                    <div className="modal-header">
                        <div className="modal-title h5">Call Details </div>
                    </div>
                    <form autoComplete="off" noValidate>
                        <div className='row pt-3'>

                            <div className='col-6 cl-det'>
                                <label className="t-primary">Call From: </label>
                                <p className="label-txt font-weight-bold">{callDetail.callFromFirstName}</p>
                            </div>
                            <div className='col-6 cl-det'>
                                <label className="t-primary" >Call To: </label>
                                <p className="label-txt font-weight-bold">{callDetail.callToFirstName}</p>
                                
                            </div>

                            <div className='col-6 cl-det'>
                                <label className="t-primary">Request Duration: </label>
                                <p className="label-txt font-weight-bold">{callDetail.requestedDuration} mins</p>
                            </div>

                           


                            <div className='col-6 cl-det'>
                            <label className="t-primary" > Actual Duration: </label>
                                <p className="label-txt font-weight-bold">{callDetail.actualDuration} mins</p>
                            </div>
                        </div>
                        <div className="row txt-center-row">
                        <div className="d-flex justify-content-center" >

                            <label className="t-primary font-weight-bold">Refund Amount: </label>
                            <p className="label-txt font-weight-bold"> ${parseFloat(callDetail?.callCharges ?? 0)?.toFixed(2)}</p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <label className=" t-primary" >Call End time:  </label>
                            <p className="label-txt font-weight-bold">{callDetail.callEndedAt && moment(callDetail.callEndedAt).format("MM/DD/YYYY hh:mm A")}</p>
                        </div>
                        </div>
                        
                        <div className="group-field group-field2">
                            <label htmlFor='reason' className="t-primary">Refund Reason</label>
                            <select style={{ width: '50%', }} ref={register({ required: true })} className="form-control " id="reason" name="reason">
                                {RefundReason.map((option: any) => {
                                    return (
                                        <option value={option.value} >
                                            {option.value}
                                        </option>
                                    )
                                })}

                            </select>
                        </div>
                        <div className="group-field group-field2">
                            <label className="t-primary">Additional Details <span className=" text-danger font-weight-bold">* </span></label>
                            <input name="callLogId" ref={register} defaultValue={callDetail.callId} hidden />
                            <textarea
                                style={{ width: '73%', resize: 'none', height: '120px' }}
                                name="additionalDetail"
                                ref={register({ required: true })}
                                className="form-control"
                                placeholder="Describe the reason for refund"
                            />
                        </div>
                        <hr />
                        <span className=" ml-4 pull-left text-danger">
                            All fields are required with Asterisk (
                            <span className="asterisk-bx text-danger">*</span>)
                        </span>
                        <div className="float-right b-btns" >
                            <Button className='ml-2' variant="contained" onClick={() => setShowCallList(false)}>
                                Cancel
                            </Button>
                            <Button
                                className="ml-2 primary"
                                variant="contained"
                                onClick={handleSubmit((data) =>
                                    submit(data, "Pending")
                                )}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default NewRefundRequest