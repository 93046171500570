import React from 'react';
import Platform from '../Platform';
import { PieChart, Pie, Legend, Cell } from "recharts";
var expense = 0,
revenue = 0,
profit = 0;
Platform.forEach((value) => {
expense = expense + value.expense;
revenue = revenue + value.revenue;
});
profit = revenue - expense;



const COLORS = ["dodgerblue", "#e3712a", "#5ec859"];
const PlatformDonut = (props: any) => {
  const data = [
    { name: "Revenue", value: props.platformDonut.revenue },
    { name: "Expense", value: props.platformDonut.expense },
    //{ name: "profit", value: props.platformDonut.profit },
  
  ];
  return (
    <>
    <PieChart width={800} height={250}>
      <Legend align='left' verticalAlign='top'/>
      <Pie
        data={data}
        cx={280}
        cy={100}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      
    </PieChart>
   </>
  )
}

export default PlatformDonut;