import React, { useEffect, useState } from 'react';
import { Button } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert';
import SettingsService from '../../../services/settings-service/SettingsService';
import Utili from '../../../utility/Utility';

function BrandAmbassador() {
    function refreshPage() {
        window.location.reload();
    }
    const [GoldBrandAmbassador, setGoldBrandAmbassador] = useState<any>();
    const [SilverBrandAmbassador, setSilverBrandAmbassador] = useState<any>({});
    const [BronzBrandAmbassador, setBronzBrandAmbassador] = useState<any>({});

    const {
        register: registerGoldBrandAmbassador,
        handleSubmit: submitGoldBrandAmbassador,
    } = useForm();
    const {
        register: registerSilverBrandAmbassador,
        handleSubmit: submitSilverBrandAmbassador,
    } = useForm();

    const {
        register: registerBronzBrandAmbassador,
        handleSubmit: submitBronzBrandAmbassador,
    } = useForm();

    const SubmitValues = (data: any) => {
        if (data.value === true) {
            data.value = 'true'
        } else {
            data.value = 'false'
        }

        SettingsService.UpdateGatedSignupConfig(data).then((res) => {
            console.log(res.data)
            Utili.notifyUpdated();


        }).catch((err) => {
            Utili.notifyError();
        })


    }

    const UpdateAppConfiguration = (data: any) => {
        var newDt = new Date();
        data.effectiveStart = moment(newDt).format("YYYY-MM-DD");
       // data.effectiveEnd=  moment(newDt).format("YYYY-MM-DD");
        var dataArray = [data]
        console.log(dataArray);

        SettingsService.UpdateAppConfiguration(dataArray).then((res) => {
            console.log(res.data)
            Utili.notifyUpdated();
            GetBrandAmbassadorConfigs();
        }).catch((err) => {
            Utili.notifyError();
        })


    }

   

    const GetBrandAmbassadorConfigs = () => {
        SettingsService.GetBrandAmbassadorConfigs().then((res: any) => {
            res.data.result.forEach(function (value: any) {
                if (value.key === "GOLD_REFERRAL_PERCENT") {
                    setGoldBrandAmbassador(value);
                   
                } else if (value.key === "SILVER_REFERRAL_PERCENT") {
                    setSilverBrandAmbassador(value);
                  
                }else if (value.key === "BRONZE_REFERRAL_PERCENT") {
                    setBronzBrandAmbassador(value);
                  
                }
              
            });

        })
    }

   

    const submit = (data: any) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to Save the Changes.',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => UpdateAppConfiguration(data)
                },
                {
                    label: 'Cancel',
                    onClick: () => refreshPage()
                }
            ]
        });
    };

    useEffect(() => {

       SettingsService.GetBrandAmbassadorConfigs().then((res: any) => {
            res.data.result.forEach(function (value: any) {
                if (value.key === "GOLD_REFERRAL_PERCENT") {
                    setGoldBrandAmbassador(value);
                   
                } else if (value.key === "SILVER_REFERRAL_PERCENT") {
                    setSilverBrandAmbassador(value);
                  
                }else if (value.key === "BRONZE_REFERRAL_PERCENT") {
                    setBronzBrandAmbassador(value);
                  
                }
              
            });

        })

    }, []);
  return (
    <div className="col-10 p-0">
                <h3 className="mb-3">Brand Ambassador</h3>
                <h4 className="mb-0 heading"> Configuration </h4>
                <div className="p-3 border config-rows">
                <div className="row">
                        <div className="col-6">
                            <label className="m-2">Gold Ambassador Commission</label>
                        </div>
                        
                            <input hidden type="text" name="category" value={GoldBrandAmbassador?.category} ref={registerGoldBrandAmbassador} />
                            <input hidden type="text" name="label" value={GoldBrandAmbassador?.label} ref={registerGoldBrandAmbassador} />
                            <input hidden type="text" name="unit" value={GoldBrandAmbassador?.unit} ref={registerGoldBrandAmbassador} />
                            <input hidden type="text" name="key" value={GoldBrandAmbassador?.key} ref={registerGoldBrandAmbassador} />
                            <div className="col-2">
                            <input
                            
                                className="form-control form-control-sm "
                                defaultValue={GoldBrandAmbassador?.value}
                                type='number'
                                name="value"
                                min={0}
                               
                                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                                ref={registerGoldBrandAmbassador}

                            />
                        </div>
                        <div className="col-1">
                        <label className="m-2 small" style={{ color: "#b59852" }}>{GoldBrandAmbassador?.unit}</label>
                        </div>
                        <div className="col-3">

                            <Button
                                onClick={submitGoldBrandAmbassador(submit)}
                                style={{ background: "#bfa056", color: "#fff" }}
                                variant="contained"
                                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                                size="small"

                            >
                                Update
                            </Button>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label className="m-2">Silver Ambassador Commission</label>
                        </div>
                        
                            <input hidden type="text" name="category" value={SilverBrandAmbassador?.category} ref={registerSilverBrandAmbassador} />
                            <input hidden type="text" name="label" value={SilverBrandAmbassador?.label} ref={registerSilverBrandAmbassador} />
                            <input hidden type="text" name="unit" value={SilverBrandAmbassador?.unit} ref={registerSilverBrandAmbassador} />
                            <input hidden type="text" name="key" value={SilverBrandAmbassador?.key} ref={registerSilverBrandAmbassador} />
                            <div className="col-2">
                            <input
                            
                                className="form-control form-control-sm "
                                defaultValue={SilverBrandAmbassador?.value}
                                type='number'
                                name="value"
                                min={0}
                               
                                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                                ref={registerSilverBrandAmbassador}

                            />
                        </div>
                        <div className="col-1">
                        <label className="m-2 small" style={{ color: "#b59852" }}>{SilverBrandAmbassador?.unit}</label>
                        </div>
                        <div className="col-3">

                            <Button
                                onClick={submitSilverBrandAmbassador(submit)}
                                style={{ background: "#bfa056", color: "#fff" }}
                                variant="contained"
                                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                                size="small"

                            >
                                Update
                            </Button>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label className="m-2">Bronze Ambassador Commission</label>
                        </div>
                        <div className="col-2">
                        <input hidden type="text" name="category" value={BronzBrandAmbassador?.category} ref={registerBronzBrandAmbassador} />
                            <input hidden type="text" name="label" value={BronzBrandAmbassador?.label} ref={registerBronzBrandAmbassador} />
                            <input hidden type="text" name="unit" value={BronzBrandAmbassador?.unit} ref={registerBronzBrandAmbassador} />
                            <input hidden type="text" name="key" value={BronzBrandAmbassador?.key} ref={registerBronzBrandAmbassador} />
                            <input
                                className="form-control form-control-sm "
                                defaultValue={BronzBrandAmbassador.value}
                                type='number'
                                name="value"
                                min={0}
                              
                                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                                ref={registerBronzBrandAmbassador}

                            />
                        </div>
                        <div className="col-1">
                        <label className="m-2 small" style={{ color: "#b59852" }}>{BronzBrandAmbassador?.unit}</label>
                        </div>
                        <div className="col-3">

                            <Button
                                onClick={submitBronzBrandAmbassador(submit)}
                                style={{ background: "#bfa056", color: "#fff" }}
                                variant="contained"
                                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                                size="small"

                            >
                                Update
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
  )
}

export default BrandAmbassador