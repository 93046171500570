import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import withAuth from "../../components/hod/withAuth";
import {
  AppConfiguration,
  ActiveConfiguration,
  BonusExpiry,
  BrandAmbassador,
  DeductionOrder,
} from "../settings";
import SpinnerLoader from "../../components/spinner-loader/SpinnerLoader.component";
import ExpertsOrder from "./ExpertsOrder";

function Settings(props: any) {
  let { path, url } = useRouteMatch();
  return (
    <div>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "GetActiveNotiConfiguration",
          "GetAppConfiguration",
          "UpdateTopupBonusExpiryDaysConfig",
          "UpdateReferralBonusExpiryDaysConfig",
          "UpdateSignupBonusExpiryDaysConfig",
          "GetGatedSignupConfig",
          "GetAppConfigs",
          "UpdateGatedSignupConfig",
          "UpdateNotificationConfigs",
          "UpdateWithdrawalLimitConfig",
          "UpdateSignupBonusAllowedConfig",
          "UpdateDepositLimitConfig",
          "GetBrandAmbassadorConfigs",
          "GetCallSourceOrderWithdrawal",
          "GetCallSourceOrder",
        ]}
        inInternal={false}
      />
      <div className="row m-0">
        <div className="col-2 p-0">
          <div className="left-nav-bx">
            <nav className="navbar">
              <ul className="navbar-nav left-m">
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname ==
                      "/settings/activeconfiguration"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/activeconfiguration`}
                  >
                    Active Owwll Configuration
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/settings/appconfiguration"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/appconfiguration`}
                  >
                    App Configuration
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/settings/brandambassador"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/brandambassador`}
                  >
                    Brand Ambassador
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/settings/deductions"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/deductions`}
                  >
                    Deduction Order
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/settings/bonusexpiry"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/bonusexpiry`}
                  >
                    Bonus Expiry
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/settings/expertsorder"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/expertsorder`}
                  >
                    Home Categories Order
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-10 p-4">
          <Switch>
            <Route exact path={path}>
              <div className="">
                <h3 className="mb-3">Welcome to Settings Page</h3>
              </div>
            </Route>
            <Route
              path={`${path}/activeconfiguration`}
              component={ActiveConfiguration}
            />
            <Route
              path={`${path}/appconfiguration`}
              component={AppConfiguration}
            />
            <Route
              path={`${path}/brandambassador`}
              component={BrandAmbassador}
            />
            <Route path={`${path}/deductions`} component={DeductionOrder} />
            <Route path={`${path}/bonusexpiry`} component={BonusExpiry} />
            <Route path={`${path}/expertsorder`} component={ExpertsOrder} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Settings);
