import "./Header.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { authService } from "../../services/AuthService";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Administrator from "../../pages/administrator/Administrator";
import CountService from "../../services/count-service/CountService";

function Header(props: any) {
  const logout = () => {
    authService.logout(props);
  };

  const [adminName, setAdminName] = useState<any>("");
  const [onlineUser, setOnlineUser] = useState(0);
  const [totalUsers,setTotalUsers] = useState(0);

  const handleRefreshUsers=()=>{
    CountService.GetOnlineAvailableUsersCounts().then((res) => {
      console.log(setOnlineUser(res.data.result.onlineUsers));
      setTotalUsers(res.data.result.totalUsers);
  })}
  useEffect(() => {
    setAdminName(localStorage.getItem("user-name"));
    CountService.GetOnlineAvailableUsersCounts().then((res) => {
      console.log(setOnlineUser(res.data.result.onlineUsers));
      setTotalUsers(res.data.result.totalUsers);
    });
  }, [])
  return (
    <div className="header-bx">
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand owwll-logo-bx" to="/">
          {/* <img src="images/owwll-main-logo.png" alt="Logo" /> */}
        </Link>
        <ul className="navbar-nav mr-auto">
          <li className={"nav-item " + (props.match.path == "/" ? "active" : "")}>
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className={"nav-item " + (props.match.path == "/administrator" ? "active" : "")}>
            <Link
              className="nav-link"
              to="/administrator">
              Administration
            </Link>
          </li>
          <li className={"nav-item " + (props.match.path == "/financials" ? "active" : "")}>
            <Link
              className="nav-link"
              to="/financials/dashboard">
              Financials
            </Link>
          </li>
          {/* <li className={"nav-item " + (props.match.path == "/reports" ? "active" : "")}>
            <Link className="nav-link" to="/reports/revenuereport">
              Reports
            </Link>
          </li> */}
          <li className={"nav-item " + (props.match.path == "/attributes" ? "active" : "")}>
            <Link className="nav-link" to="/attributes/categories">
              Attributes
            </Link>
          </li>
          <li className={"nav-item " + (props.match.path == "/settings" ? "active" : "")}>
            <Link className="nav-link" to="/settings/activeconfiguration">
              Settings
            </Link>
          </li>

          
        </ul>
        
        <Button onClick={handleRefreshUsers} className="refresh-icon"> </Button>
        <div className="countswrap">
        
          {/* <img src="images/owwll-main-logo.png" alt="Logo" /> */}
       
          {/* <Link className="refresh-icon" to="/"></Link> */}

          <div className="counts-label-wrap"><div className="counts-label">Total Users : </div> <span> {totalUsers}</span></div>
          <div className="counts-label-wrap"><div className="counts-label">Online Users : </div> <span> {onlineUser}</span></div>
        </div>
        <span className="navbar-text mb-1">
          <span>Logged in as <b className="mr-2">{adminName}</b></span>
          <Button className="btn logout-btn primary" onClick={logout}>
            Logout
          </Button>
        </span>
      </nav>
    </div>
  );
}

export default Header;
