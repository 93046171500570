import React, { useEffect, useState } from 'react';
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Utility from "../../../utility/Utility";
import ReportService from "../../../services/report-service/ReportService";
import { TextField } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
function AdviceSeekerSpendings() {
    var time = "23:59:59";
    const getInitialDate = (days: number) => {
        var newDt = new Date();
        newDt.setDate(newDt.getDate() + days);
        return moment(newDt).format("YYYY-MM-DD");
    };
    const [selectedToDate, setSelectedToDate] = React.useState(getInitialDate(0));
    const [selectedFromDate, setSelectedFromDate] = React.useState(
        getInitialDate(-30)
    );
    const [maxDate, setMaxToDate] = React.useState(getInitialDate(0));
    const [validationMessage, setvalidationMessage] = React.useState("");
    const [isValidate, setIsValidate] = React.useState(false);
    const classes = useStyles();
    const [reportData, setReportData] = useState("");
    const [pdfBytes, setpdfBytes] = useState("");
    const handleFromDateChange = (date: any) => {
        console.log(date.target.value);
        setSelectedFromDate(date.target.value);
    };
    const handleToDateChange = (date: any) => {
        setSelectedToDate(date.target.value);
    };
    const getToDateString = (date: any) => {

        var newDt = moment(date + ' ' + time);
        return moment(newDt).format("MM/DD/YYYY hh:mm:ss A");
    };
    const getFromDateString = (date: any) => {
        var newDt = moment(new Date(date));
        return moment(newDt).format("MM/DD/YYYY");
    };
    const CurrentDate = () => {
        var currDate = new Date();
        return new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate());
    }
    const parseDate = (dt: Date) => {
        return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    }
    const getDateFromString = (date: string) => {
        var newDt = new Date(date);
        return newDt;
    };
    const ClearSearchCriteria = () => {
        setSelectedToDate(getInitialDate(0));

        setSelectedFromDate(getInitialDate(-30));
    };
    const Download = () => {
        var blob = Utility.b64toBlob(pdfBytes);
        var url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "AdviceSeekerSpendings";
        a.click();
    };
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);

    const getAdviceSeekerSpendingsReport = () => {
        if (
            parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
            parseDate(getDateFromString(selectedToDate)) > CurrentDate()
        ) {

            setIsValidate(true);
            setvalidationMessage("From Date cannot be greater than To Date");
            console.log("From Date cannot be greater than To Date");
            return;
        }

        if (
            parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
            parseDate(getDateFromString(selectedToDate)) > CurrentDate()
        ) {
            setIsValidate(true);
            setvalidationMessage("From Date and To Date cannot be in future");
            console.log("From Date and To Date cannot be in future");
            return;
        }

        setIsValidate(false);
        setvalidationMessage("");
        const frmDate = selectedFromDate;
        const toDate = moment(selectedToDate + ' ' + "23:59:59").format('MM/DD/YYYY hh:mm:ss A');
        //?fromDate=0001-01-01&toDate=0001-01-01
        ReportService.getAdviceSeekerSpendingsReport(
            frmDate == "Invalid date" ? "0001-01-01" : frmDate,
            toDate == "Invalid date" ? "0001-01-01" : toDate
        ).then((res: any) => {
            setReportData(res.data.result.reportHTML);
            setpdfBytes(res.data.result.reportBytes);
            setShowDownloadBtn(true);
        });
    };
    useEffect(() => {
        //getRevenue();
    }, []);
    return (
        <div className="col-10 mt-4">
            <h3>Advice Seeker Spendings</h3>
            <div className="pt-2">
                <form className={classes.container} noValidate>
                    <TextField
                        id="dateFrom"
                        label="From Date"
                        type="date"
                        value={selectedFromDate}
                        onChange={handleFromDateChange}
                        InputProps={{ inputProps: { max: selectedToDate } }}
                        //defaultValue="0001-01-01" // 0001-01-01
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="dateTo"
                        label="To Date"
                        type="date"
                        value={selectedToDate}
                        onChange={handleToDateChange}
                        InputProps={{ inputProps: { max: maxDate } }}
                        //defaultValue="0001-01-01"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <button
                        color="secondary"
                        onClick={ClearSearchCriteria}
                        type="button"
                        className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
                    >
                        Reset
                    </button>
                    <button
                        color="secondary"
                        onClick={getAdviceSeekerSpendingsReport}
                        type="button"
                        className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
                    >
                        View Report
                    </button>
                    {isValidate && (
                        <span className="error-msg" style={{ color: "#ff0000" }}>
                            {validationMessage}
                        </span>
                    )}
                </form>
                <div className="mt-4">
                    <div className="mb-0 heading">
                        <h5>
                            Report{" "}
                            {
                                showDownloadBtn ?
                                    <button
                                        color="secondary"
                                        onClick={Download}
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary float-right"
                                    >
                                        Save as
                                    </button>
                                    :
                                    ''
                            }
                        </h5>
                    </div>
                    <div className="report-view-bx">
                        <div dangerouslySetInnerHTML={{ __html: reportData }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdviceSeekerSpendings