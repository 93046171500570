import React, { useEffect, useState } from "react";
import PlatformDonut from "./Charts/PlatformDonut";
import UserDonut from "./Charts/UserDonut";
import DashboardService from "../../../services/DashboardService";
import "./Reports.css";
import PlatformGraph from "./Charts/PlatformGraph";
import UserGraph from "./Charts/UserGraph";
import TopEarner from "./Charts/TopEarner";
import TopReferralEarner from "./Charts/TopReferralEarner";
import TopSignUpEarner from "./Charts/TopSignUpEarner";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
function Dashboard() {
  const [duration, setDuration] = useState("Last Month");
  const [plateform, setPlateform] = useState<any>({});
  const [userStats, setUserStats] = useState<any>({});
  const [plateformChart, setPlateformChart] = useState<any>([]);
  const [userStatsChart, setUserStatsChart] = useState<any>([]);
  const [topReferralEarners, setTopReferralEarners] = useState<any>([]);
  const [topEarners, setTopEarners] = useState<any>([]);
  const [topSignupEarners, setTopSignupEarners] = useState<any>([]);

  const handleDuration = (data: any) => {
    setDuration(data.target.value);
  };
  const onApply = () => {
    DashboardService.PlateformStatsWidgetData(duration).then((res) => {
      setPlateform(res.data.result);
    });
    DashboardService.UserStatsWidgetData(duration).then((res) => {
      setUserStats(res.data.result);
    });
    DashboardService.getTopEarners(duration).then((res) => {
      setTopEarners(res.data.result);
      console.log(res.data.result);
    });
    DashboardService.getTopReferralEarners(duration).then((res) => {
      setTopReferralEarners(res.data.result);
      //console.log(res.data.result);
    });
    DashboardService.getTopSignupEarners(duration).then((res) => {
      setTopSignupEarners(res.data.result);
      //console.log(res.data.result);
    });
    DashboardService.getLoadChart(duration).then((res) => {
      setPlateformChart(res.data.result.platforms);
      setUserStatsChart(res.data.result.users);
      console.log(res.data.result);
    });
  };


  useEffect(() => {
    onApply();
  }, []);
  return (
    <>
      <div className="col-10 pt-4">
        <SpinnerLoader
          isGeneral={true}
          tasksList={[
            "PlateformStatsWidgetData",
            "UserStatsWidgetData",
            "getTopEarners",
            "getTopReferralEarners",
            "getTopSignupEarners",
            "getLoadChart"
          ]}
          inInternal={true}
        />
        <div className="dropdown ">
          <select style={{ marginTop: "12px" }} name="subject" id="subject" onChange={(data: any) => handleDuration(data)}>
            <option value="Last Month">Last Month</option>
            <option value="This Month">This Month</option>
            <option value="Last 3 Months">Last 3 Months</option>
            <option value="Last 6 Months">Last 6 Months</option>
            <option value="Till date">Till Date</option>
          </select>
          <button onClick={onApply} className="apply">
            Apply
          </button>
        </div>
        <div className="row">
          <div className="portion">
            <h3>Platform</h3>
            <hr />
            <div className="box-wrapper">
              <div className="box1" style={{ backgroundColor: "dodgerblue" }}>
                <h6>Revenue</h6>
                <hr
                  style={{
                    backgroundColor: "white",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(plateform?.revenue ?? 0).toFixed(2)}
                </p>
              </div>
              <div className="box1" style={{ backgroundColor: "#e3712a" }}>
                <h6>Expense</h6>
                <hr
                  style={{
                    backgroundColor: "white !important",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(plateform?.expense ?? 0).toFixed(2)}
                </p>
              </div>
              <div className="box1" style={{ backgroundColor: "#5ec859" }}>
                <h6>Profit</h6>
                <hr
                  style={{
                    backgroundColor: "white",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(plateform?.profit ?? 0).toFixed(2)}
                </p>
              </div>
            </div>

            <div className="box-wrapper">
              <div className="text1">*From Calls</div>
              <div className="text1" style={{ marginLeft: "10px" }}>
                *Stripe Gateway Transaction Fee
              </div>
              <div className="text1" style={{ marginLeft: "15px" }}>
                *Revenue - Expense
              </div>
            </div>
            <div className="box2wrap">
              <div className="box2" style={{ backgroundColor: "#fbdc44" }}>
                <h6>Liabilities</h6>

                <p className="money">
                  ${parseFloat(userStats?.liability ?? 0).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="text2">*Withdrawable Earnings</div>
            <div>
              <PlatformDonut platformDonut={plateform} />
            </div>
            <div style={{ marginTop: "20px" }}>
              <PlatformGraph platformGraph={plateformChart} />
            </div>
            <div className="set_table">
              <TopEarner topEarner={topEarners} />
            </div>
            <div className="set_table">
              <TopSignUpEarner topSignUpEarner={topSignupEarners} />
            </div>
          </div>
          <div className=" portion portion2">
            <h3>Users</h3>
            <hr />
            <div className="box-wrapper">
              <div className="box1" style={{ backgroundColor: "#3ba7b3" }}>
                <h6>Deposits</h6>
                <hr
                  style={{
                    backgroundColor: "white",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(userStats?.deposit ?? 0).toFixed(2)}
                </p>
              </div>
              <div className="box1" style={{ backgroundColor: "#b63cc2" }}>
                <h6>Spendings</h6>
                <hr
                  style={{
                    backgroundColor: "white",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(userStats?.spending ?? 0).toFixed(2)}
                </p>
              </div>
              <div className="box1" style={{ backgroundColor: "#5ec859" }}>
                <h6>Earnings</h6>
                <hr
                  style={{
                    backgroundColor: "white",
                    marginTop: "-5px",
                    width: "70%",
                  }}
                />
                <p className="money">
                  ${parseFloat(userStats?.earning ?? 0).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="box-wrapper">
              <div className="text1">*From Credit Card</div>
              <div className="text1" style={{ marginLeft: "10px" }}>
                *From User Balance
              </div>
              <div className="text1" style={{ marginLeft: "15px" }}>
                *From Calls Taken 
              </div>
            </div>
            <div style={{ marginTop: "141px" }}>
              <UserDonut userDonut={userStats} />
            </div>

            <div style={{ marginTop: "20px" }}>
              <UserGraph userGraph={userStatsChart} />
            </div>
            <div className="set_table">
              <TopReferralEarner topReferralEarner={topReferralEarners} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
