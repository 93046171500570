import { Button, makeStyles, TextField } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Utility from "../../../utility/Utility";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import { Modal } from "react-bootstrap";
import UserService from "../../../services/user-service/UserService";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const RequestList: any = [];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function DeactivatedUsers() {
  const classes = useStyles();
  const [showGrid, setShowGrid] = useState(true);
  const [requestlist, setRequestList] = useState(RequestList);
  const [currentRequest, setCurrentRequest] = useState<any>({});
  const [deactivationRequest, setDeactivationRequest] = useState<any>({});
  const [requestStatus, setRequestStatus] = useState<any>("all");
  const [deactivated, setDeactivated] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [isValidate, setIsValidate] = React.useState(false);
  const [isRowSeleted, setIsRowSeleted] = React.useState(true);
  const [validationMessage, setvalidationMessage] = React.useState("");
  const [fullNameSearch, setfullNameSearch] = useState("");
  const [page, setPage] = useState<any>(0);
  const [RowCount, setRowCount] = useState<any>(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, reset, errors } = useForm();
  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    reset: resetSearchFrom,
    watch,
    control: contorlSearch,
    getValues: getValuesSearchForm,
  } = useForm();

  const getInitialDate = (days: number) => {
    var newDt = new Date();
    newDt.setDate(newDt.getDate() + days);
    return moment(newDt).format("YYYY-MM-DD");
  };
  const [maxDate, setMaxToDate] = React.useState(getInitialDate(0));
  const [selectedToDate, setSelectedToDate] = React.useState("0001-01-01");
  const [selectedFromDate, setSelectedFromDate] = React.useState("0001-01-01");
  const handleToDateChange = (date: any) => {
    setSelectedToDate(date.target.value);
  };
  const handleFromDateChange = (date: any) => {
    console.log(date.target.value);
    setSelectedFromDate(date.target.value);
  };
  const parseDate = (dt: Date) => {
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  };
  const getDateFromString = (date: string) => {
    var newDt = new Date(date);
    return newDt;
  };
  const CurrentDate = () => {
    var currDate = new Date();
    return new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      currDate.getDate()
    );
  };

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    {
      field: "fullName",
      headerName: "Name",
      width: 230,

      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: 280,
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      width: 280,
    },
    {
      field: "requestedDate",
      headerName: "Deactivation Date",
      width: 200,
      // align: "left",
      // headerAlign: "center",
      valueFormatter: (params) =>
        moment(params.row.requestedDate).format("MM/DD/YYYY hh:mm A"),
    },

    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          type="button"
          onClick={() =>
            review(requestlist.find((e: any) => e.id == params.row.id))
          }
          variant="contained"
          className="MuiButton-textSizeSmall primary"
        >
          {params.row.status == "approved" ? "Review" : "Review"}
        </Button>
      ),
    },
  ];

  const review = (request: any) => {
    setCurrentRequest({});
    UserService.getProfile(request.id).then((res) => {
      console.log(res.data.result);
      setCurrentRequest(res.data.result);
    });

    handleShow();
  };

  const handleUserName = (data: any) => {
    setfullNameSearch(data.target.value);
  };
  const getfilter = (data: any) => {
    if (data.target.value !== undefined) {
      setRequestStatus(data.target.value);
    }
    if (
      parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
      parseDate(getDateFromString(selectedToDate)) > CurrentDate()
    ) {
      setIsValidate(true);
      setvalidationMessage("From Date cannot be greater than To Date");
      console.log("From Date cannot be greater than To Date");
      return;
    }

    if (
      parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
      parseDate(getDateFromString(selectedToDate)) > CurrentDate()
    ) {
      setIsValidate(true);
      setvalidationMessage("From Date and To Date cannot be in future");
      console.log("From Date and To Date cannot be in future");
      return;
    }

    setIsValidate(false);
    setvalidationMessage("");
    console.log(data.target.value);
    if (data.target.value === undefined) {
      data.target.value = requestStatus;
    }

    let searchFilter = {
      status: data.target.value,

      userName: fullNameSearch,
      startDate: selectedFromDate,
      endDate: selectedToDate,
      pageNumber: page,
      pageSize: 10,
    };

    if (
      parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
      parseDate(getDateFromString(selectedToDate)) > CurrentDate()
    ) {
      setIsValidate(true);
      setvalidationMessage("From Date cannot be greater than To Date");
    } else {
      setIsValidate(false);
      setRequestList(RequestList);
      setShowGrid(false);
      UserService.searchDeactivationRequests(searchFilter).then((res: any) => {
        // setRowCount(res.data.result.totalRecordCount);
        setRequestList(res.data);

        setShowGrid(true);
      });
    }
  };

  const submit = (data: any, status: string) => {
    let requestIds = currentRequest.id;

    data.userId = requestIds;

    let searchFilter = {
      userName: fullNameSearch,
      status: requestStatus,
      startDate: selectedFromDate,
      endDate: selectedToDate,
      pageNumber: page,
      pageSize: 10,
      type: 1,
    };

    UserService.searchDeactivationRequests(searchFilter)
      .then((res: any) => {
        // setRowCount(res.data.result.totalRecordCount);
        setRequestList(res.data);
        setShowGrid(true);
        Utility.notifyApproved();
      })
      .catch((err: any) => {
        console.log(err);
      })
      .catch((err: any) => {
        console.log(err);
      });
    handleClose();
  };
  const getSearch = () => {
    let searchFilter = {
      userName: fullNameSearch,
      status: requestStatus,
      startDate: selectedFromDate,
      endDate: selectedToDate,
      type: 1,
    };
    if (
      parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
      parseDate(getDateFromString(selectedToDate)) > CurrentDate()
    ) {
      setIsValidate(true);
      setvalidationMessage("From Date cannot be greater than To Date");
    } else {
      setIsValidate(false);
      setRequestList(RequestList);
      setShowGrid(false);
      UserService.searchDeactivationRequests(searchFilter)
        .then((res: any) => {
          console.log(res.data);
          //setRowCount(res.data.result.totalRecordCount);
          setRequestList(res.data);
          console.log(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
      setShowGrid(true);
    }
  };

  const pagination = (page: any) => {
    let searchFilter = {
      userName: fullNameSearch,
      status: requestStatus,
      startDate: selectedFromDate,
      endDate: selectedToDate,
      pageNumber: page,
      pageSize: 10,
    };
    setPage(page);

    UserService.searchDeactivationRequests(searchFilter).then((res) => {
      //setRowCount(res.data.result.totalRecordCount);
      setRequestList(res.data);
    });

    console.log(page);
  };
  useEffect(() => {
    // setValuesSearchForm("status", "pending");
    // let searchFilter = {
    //     userName: fullNameSearch,
    //     status: requestStatus,
    //     startDate: selectedFromDate,
    //     endDate: selectedToDate,
    //     // pageNumber: page,
    //     // pageSize : 10,
    // }
    // UserService.searchDeletionRequests(searchFilter).then((res: any) => {
    //     console.log(res.data)
    //     // setRowCount(res.data.result.totalRecordCount)
    //      setRequestList(res.data);
    // }).catch((err: any) => {
    //     console.log(err);
    // })
    //test
    // setfullNameSearch(" ");
  }, []);

  return (
    <>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "SearchDeletedUser",
          "ProcessUserRemoval",
          "getuserprofile",
        ]}
        inInternal={false}
      />
      <div className="col-12 p-0">
        <h3 className="mb-3">Deactivated Users</h3>
        <form
          autoComplete="off"
          className="form-inline signup-up-sec mb-2"
          noValidate
        >
          <div className="lft-sec"></div>
          <div className="right-sec">
            <input
              className="form-control search-field form-control-sm"
              type="name"
              name="userName"
              value={fullNameSearch}
              onChange={handleUserName}
              style={{ backgroundColor: "#fff" }}
              //ref={searchForm({ required: false })}
              placeholder="Name"
            />
            <Button
              className="search-b"
              onClick={getSearch}
              variant="contained"
            >
              Search
            </Button>
          </div>
          {isValidate ? (
            <p style={{ marginLeft: "50%", color: "red" }}>
              {validationMessage}
            </p>
          ) : (
            ""
          )}
        </form>

        <div style={{ paddingBottom: "40px", width: "100%" }}>
          <div className="user-table-header heading">
            <h5 className="mb-0 heading">Requests</h5>
            {/* <Button disabled={isRowSeleted} className='approve-all' variant='contained' onClick={submitSelectedRows}>Approve Selected</Button> */}
          </div>
          {showGrid ? (
            <div className="data-grid">
              <DataGrid
                getRowId={(row) => row.requestedDate}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                columns={columns}
                rows={requestlist}
                onSelectionModelChange={(newSelection) => {
                  setSelectedRows(newSelection);
                  if (newSelection.length >= 1) {
                    setIsRowSeleted(false);
                  } else {
                    setIsRowSeleted(true);
                  }
                }}
                pageSize={10}
                autoHeight={true}
                //rowCount={RowCount}
                //paginationMode="server"
                // onPageChange={(newPage) => pagination(newPage)}
                disableColumnMenu={true}
                disableSelectionOnClick
                //checkboxSelection
                //isRowSelectable={(params) => params.row.signupStatus != "Approved"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off" noValidate>
              <div className="modal-body-bx">
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Name</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.fullName}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Email</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Title</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.title}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Company/Organization</label>
                  </div>
                  <div className="col-3">
                    <p className="">
                      {currentRequest.organization != null &&
                      currentRequest.organization != ""
                        ? currentRequest.organization
                        : currentRequest.occupation}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Enable</label>
                  </div>
                  <div className="col-3">
                    <p className="">
                      {currentRequest.isActive ? <CheckIcon /> : <CloseIcon />}
                    </p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Featured</label>
                  </div>
                  <div className="col-3">
                    <p className="">
                      {currentRequest.isFeatured ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Ambassador</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.brand_Ambassador}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Referral Code</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.referralCode}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Request Date</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.created_on}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Date of referral</label>
                  </div>
                  <div className="col-3">
                    {currentRequest.referredBy?.fullName != "" ? (
                      <p>{currentRequest.referredBy?.referredOn}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {requestStatus == "pending" ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Deactivated Date</label>
                    </div>
                    <div className="col-10">
                      <p className="">
                        {moment(currentRequest.removalApprovedDate).format(
                          "MM/DD/YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-2">
                    <label className="lbl">How did you hear about us?</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.sourceOption}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Referred By</label>
                  </div>
                  <div className="col-3">
                    {currentRequest.referredBy?.fullName != null ? (
                      <p className="">
                        {currentRequest.referredBy?.fullName}
                        <span style={{ fontFamily: "sans-serif" }}>
                          ({currentRequest.referredBy?.referralCode})
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">City</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.city}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">State</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.state}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">College</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentRequest.collegeAttend}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Date of Birth</label>
                  </div>
                  <div className="col-3">
                    <p className="">
                      {moment(currentRequest.dob).format("MM/DD/YYYY") !=
                      "01/01/0001"
                        ? moment(currentRequest.dob).format("MM/DD/YYYY")
                        : null}
                    </p>
                  </div>
                </div>
                {currentRequest.role == "Owwll" && (
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Social Profiles</label>
                    </div>
                    <div className="col-3">
                      {currentRequest.socialProfiles?.map((s: any, i: any) => (
                        <span
                          className="slink"
                          onClick={() =>
                            window.open(
                              "https://" + s.url.replace(/^https?\:\/\//i, ""),
                              "_blank"
                            )
                          }
                          key={i}
                        >
                          {s.media_type == "Facebook" && s.url != "" ? (
                            <Facebook />
                          ) : s.media_type == "LinkedIn" && s.url != "" ? (
                            <LinkedIn />
                          ) : s.media_type == "Twitter" && s.url != "" ? (
                            <Twitter />
                          ) : s.media_type == "Instagram" && s.url != "" ? (
                            <Instagram />
                          ) : (
                            ""
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Expertise</label>
                  </div>
                  <div className="col-10">
                    <div className="list-wrapper">
                      {currentRequest.categories?.map((c: any, i: any) => (
                        <a href="javascript:void(0);" key={i}>
                          {c}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Groups</label>
                  </div>
                  <div className="col-10">
                    <div className="list-wrapper">
                      {currentRequest.hobbies?.map((c: any, i: any) => (
                        <a href="javascript:void(0);" key={i}>
                          {c}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {currentRequest.role == "Owwll" && (
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Call Rates</label>
                    </div>
                    <div className="col-5">
                      <table className="table table-bordered">
                        <thead className="tbl-bk">
                          <tr>
                            <th scope="col">Duration</th>
                            <th scope="col">Fee</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentRequest.callRates?.map((c: any, i: any) => (
                            <tr key={i}>
                              <td className="text-center">{c.duration} min</td>
                              <td className="text-right">
                                {Utility.formatAmount(c.fee)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">About</label>
                  </div>
                  <div className="col-10">
                    <p className="">{currentRequest.describeYourself}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Deactivation Reason</label>
                  </div>
                  <div className="col-10">
                    <p className="">{currentRequest.removalReason}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    {
                      // @ts-ignore
                      currentRequest.userSwapStatus?.requestLogs?.map(
                        (h: any, index: any) => (
                          <p className="" key={index}>
                            {h.remarks}
                          </p>
                        )
                      )
                    }
                  </div>
                </div>
                {currentRequest.signupStatus == "Approved" ? (
                  ""
                ) : (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-2">
                        <label className="lbl">Comments</label>
                        <span className="text-danger asterisk-bx"> *</span>
                      </div>
                      <div className="col-10">
                        <textarea
                          name="comments"
                          ref={register({ required: true })}
                          className="form-control"
                          placeholder="comments"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <br />

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-10">
                    <div className="pb-4">
                      {currentRequest.signupStatus != "Approved" ? (
                        <span className="pull-left text-danger">
                          All fields are required with Asterisk (
                          <span className="asterisk-bx text-danger">*</span>)
                        </span>
                      ) : (
                        ""
                      )}

                      <div className="pull-right">
                        <Button
                          className="ml-2"
                          style={{ background: "#bfa056", color: "#fff" }}
                          variant="contained"
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default DeactivatedUsers;
