import "./Home.css";

import Header from "../../components/header/Header";
import withAuth from "../../components/hod/withAuth";
import React from "react";

function Home(props: any) {
  return (
    <div>
      <div className="container">
        <div className="row p-5 home">
          <div className="col-md-12">
            <h2>Welcome to Owwll Back Office</h2>
            <p>Select the Operations from top menu to begin.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Home);
