import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import Categories from "./categories/Categories";
import Hobbies from "./hobbies/Hobbies";
import SourceOption from "./source-option/SourceOption";
import withAuth from "../../components/hod/withAuth";
import SpinnerLoader from "../../components/spinner-loader/SpinnerLoader.component";

function Attributes(props: any) {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "GetAllCategories",
          "GetAllHobbies",
          "GetAllSourceOptions",
          "SaveCategory",
          "SaveHobby",
          "SaveSource",
        ]}
        inInternal={false}
      />
      <div className="row m-0">
        <div className="col-2 p-0">
          <div className="left-nav-bx">
            <nav className="navbar">
              <ul className="navbar-nav left-m">
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/attributes/categories"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/categories`}
                  >
                    Expertise
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/attributes/hobbies"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/hobbies`}
                  >
                    Groups
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/attributes/sourceoptions"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/sourceoptions`}
                  >
                    Source Options
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <Switch>
          <Route exact path={path}>
            <div className="col-10 p-4">
              <h3>Welcome to Attribute Manager</h3>
            </div>
          </Route>
          <Route path={`${path}/categories`}>
            <Categories />
          </Route>
          <Route path={`${path}/hobbies`}>
            <Hobbies />
          </Route>
          <Route path={`${path}/sourceoptions`}>
            <SourceOption />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default withAuth(Attributes);
