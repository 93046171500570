import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import ReportService from "../../../services/report-service/ReportService";
import moment from "moment";
import Utili from "../../../utility/Utility";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function MissedCallsRatio(props: any) {
  const getInitialDate = (days: number) => {
    var newDt = new Date();
    newDt.setDate(newDt.getDate() + days);
    return moment(newDt).format("YYYY-MM-DD");
  };
  const [selectedToDate, setSelectedToDate] = React.useState(getInitialDate(0));
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    getInitialDate(-30)
  );
  const [maxDate, setMaxToDate] = React.useState(getInitialDate(0));
  const [validationMessage, setvalidationMessage] = React.useState("");
  const [isValidate, setIsValidate] = React.useState(false);

  const [selectedCaller, setSelectedCaller] = React.useState("all");
  const [selectedRole, setselectedRole] = React.useState("all-user");
  const [selectedUserName, setSelectedUserName] = useState("");

  const classes = useStyles();
  const [reportData, setReportData] = useState("");
  const [pdfBytes, setpdfBytes] = useState("");
  useEffect(() => {
    //getRevenue();
  }, []);

  const handleFromDateChange = (date: any) => {
    console.log(date.target.value);
    setSelectedFromDate(date.target.value);
  };
  const handleToDateChange = (date: any) => {
    setSelectedToDate(date.target.value);
  };
  const handleUserName = (data:any) => {
    setSelectedUserName(data.target.value);
  };

  const getToDateString = (date: any) => {
    var time = "23:59:59";
    var newDt = moment(date + ' ' + time);
    return moment(newDt).format("YYYY-MM-DD ");
  };

  const getFromDateString = (date: any) => {
    var newDt = moment(new Date(date));
    return moment(newDt).format("YYYY-MM-DD");
  };
  const getDateFromString = (date: string) => {
    var newDt = new Date(date);

    return newDt;
  };
  const CurrentDate = () => {
    var currDate = new Date();
    return new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      currDate.getDate()
    );
  };
  const parseDate = (dt: Date) => {
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  };
  const gettReport = () => {
    if (
      parseDate(getDateFromString(selectedFromDate)) > CurrentDate() ||
      parseDate(getDateFromString(selectedToDate)) > CurrentDate()
    ) {
      setIsValidate(true);
      setvalidationMessage("From Date and To Date cannot be in future");
      console.log("--------Date--------");
      const value = new Date(moment().format("YYYY-MM-DD"));
      console.log(moment().toDate().toDateString());
      console.log("From Date and To Date cannot be in future");
      return;
    }

    setIsValidate(false);
    setvalidationMessage("");
    const frmDate = selectedFromDate;
    const toDate = moment(selectedToDate + ' ' + "23:59:59").format('YYYY-MM-DD');
    //?fromDate=0001-01-01&toDate=0001-01-01
    ReportService.GetCallsCountRatioReport(
      frmDate == "Invalid date" ? "0001-01-01" : frmDate,
      toDate == "Invalid date" ? "0001-01-01" : toDate,
      selectedUserName,
      selectedRole,
      selectedCaller,
      
      
    ).then((res: any) => {
      setReportData(res.data.result.reportHTML);
      setpdfBytes(res.data.result.reportBytes);
      setShowDownloadBtn(true);
    });
  };

  const ClearSearchCriteria = () => {
    setSelectedToDate(getInitialDate(0));
    setSelectedFromDate(getInitialDate(-30));
    setSelectedCaller("all");
    setselectedRole("all-user");
    setSelectedUserName("");
    setvalidationMessage("");
  };
  const handleChangeRole = (event: any) => {
    setselectedRole(event.target.value);
  };
  const handleChangeCaller = (event: any) => {
    setSelectedCaller(event.target.value);
  };
  const Download = () => {
    var blob = Utili.b64toBlob(pdfBytes);
    var url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "MissedCallsRatioReport";
    a.click();
  };
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  return (
    <div className="col-12">
      <SpinnerLoader isGeneral={true} tasksList={
        ['GetCallsCountRatioReport']
      } inInternal={false} />
      <h3>Missed Calls Ratio</h3>
      <div className="pt-2">
        <form className={classes.container} noValidate>
          <TextField
            id="dateFrom"
            label="From Date"
            type="date"
            value={selectedFromDate}
            onChange={handleFromDateChange}
            InputProps={{ inputProps: { max: selectedToDate } }}
            //defaultValue="0001-01-01" // 0001-01-01
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="dateTo"
            label="To Date"
            type="date"
            value={selectedToDate}
            onChange={handleToDateChange}
            InputProps={{ inputProps: { max: maxDate } }}
            //defaultValue="0001-01-01"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
        <div className="">
          <div className="p-2 mt-2 mr-3 float-left">
            <div className="label-bx">
              <h5>Caller Type </h5>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
            <input
                type="radio"
                value="all"
                onChange={handleChangeCaller}
                checked={selectedCaller === "all"}
                id="all"
                name="role"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="all">
                All
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="caller"
                onChange={handleChangeCaller}
                checked={selectedCaller === "caller"}
                id="caller"
                name="role"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="caller">
                Caller
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="recipient"
                name="role"
                className="custom-control-input"
                value="recipient"
                onChange={handleChangeCaller}
                checked={selectedCaller === "recipient"}
              />
              <label className="custom-control-label" htmlFor="recipient">
                Recipient
              </label>
            </div>
           
          </div>
          <div className="p-2 mt-2 float-left">
            <div className="label-bx">
              <h5>Subscription Type </h5>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="all-user"
                onChange={handleChangeRole}
                checked={selectedRole === "all-user"}
                id="all-user"
                name="callType"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="all-user">
                All
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="standard"
                onChange={handleChangeRole}
                checked={selectedRole === "standard"}
                id="standard"
                name="callType"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="standard">
              Standard
              </label>
            </div>

            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="premium"
                onChange={handleChangeRole}
                checked={selectedRole === "premium"}
                id="premium"
                name="callType"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="premium">
              Premium
              </label>
            </div>
            
          </div>
          <div className="p-2 mt-2 float-left">
            <div className="label-bx">
              <h5>Name </h5>
            </div>
              <input
                className="form-control form-control-sm"
                type="name"
                value={selectedUserName}
                onChange={handleUserName}
                style={{ backgroundColor: "#fff" }}
                // ref={searchForm({ required: false })}
                placeholder="Name"
              />
           
            
          </div>

        
          <div className="mt-4 float-left">
            <button
              color="secondary"
              onClick={ClearSearchCriteria}
              type="button"
              className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
            >
              Reset
            </button>
            <button
              color="secondary"
              onClick={gettReport}
              type="button"
              className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
            >
              View Report
            </button>
            <div className="clearfix"></div>
            <div>
              {isValidate && (
                <span className="error-msg" style={{ color: "#ff0000" }}>
                  {validationMessage}
                </span>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="mt-4">
          <div className="mb-0 heading">
            <h5>
              Report{" "}
              {showDownloadBtn ? (
                <button
                  color="secondary"
                  onClick={Download}
                  type="button"
                  className="btn btn-sm btn-outline-secondary float-right"
                >
                  Save as
                </button>
              ) : (
                ""
              )}
            </h5>
          </div>
          <div className="report-view-bx">
            <div dangerouslySetInnerHTML={{ __html: reportData }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissedCallsRatio;
