import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import User from '../User';

const UserGraph = (props: any) => {
  return (
    <>
      <LineChart
        width={520}
        height={280}
        data={props.userGraph}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="deposits"
          stroke="#3ba7b3"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="spendings" stroke="#b63cc2" />
        <Line type="monotone" dataKey="earnings" stroke="#5ec859" />

      </LineChart>
    </>
  );
}

export default UserGraph;