import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import withAuth from "../../components/hod/withAuth";
import { BalanceReport, NewRefundRequest, RefundReport, Refunds, Withdrawls, Dashboard, RevenueReport, Spendings, AdviceSeekerSpendings, Earnings, Bonuses, DepositsReport, ExpenseReport, OwwllEarnings, Deposits } from '../financials'
import Issues from "../administrator/issues/Issues";
import "bootstrap/dist/css/bootstrap.min.css";
import SpinnerLoader from "../../components/spinner-loader/SpinnerLoader.component";

function Financials(props: any) {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <SpinnerLoader isGeneral={true} tasksList={
        ['getRevenueReport', 'GetExpenseReport', 'GetBonusDetailReport', 'GetRefundReport', 'GetSpendingDetailReport', 'GetEarningDetailReport', 'GetDepositDetailReport', 'GetEarningReport', 'getAdviceSeekerSpendingsReport', 'GetDepositReport', 'getOwwllActiveTimeReport', 'GetCallsReport', 'GetReferralReport', 'GetBalanceReport', 'getTimeonCallReport']
      } inInternal={false} />
      <div className="row m-0">
        <div className="col-2 p-0 mb-4">
          <div className="left-nav-bx">
            <nav className="navbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className={"nav-link " + (props.location.pathname == "/financials/dashboard" ? "active-sublink" : "")} to={`${url}/dashboard`}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <h6 className="navbar-subheading" >Transactions</h6>
                  <ul className="navbar-nav ml-4">
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/deposits" ? "active-sublink" : "")} to={`${url}/deposits`}>
                        Deposits
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/earnings" ? "active-sublink" : "")} to={`${url}/earnings`}>
                        Earnings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/bonuses" ? "active-sublink" : "")} to={`${url}/bonuses`}>
                        Bonuses
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/spendings" ? "active-sublink" : "")} to={`${url}/spendings`}>
                        Spendings
                      </Link>
                    </li>

                  </ul>
                </li>

                <li className="nav-item active-page ">
                  <h6 className="navbar-subheading" >Summary Reports</h6>
                  <ul className="navbar-nav ml-4">
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/revenuereport" ? "active-sublink" : "")} to={`${url}/revenuereport`}>
                        Revenue
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/owwllearnings" ? "active-sublink" : "")} to={`${url}/owwllearnings`}>
                        Earnings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/depositsreport" ? "active-sublink" : "")} to={`${url}/depositsreport`}>
                        Deposits
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/balancereport" ? "active-sublink" : "")} to={`${url}/balancereport`}>
                        Balances
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/adviceseekerspendingsreport" ? "active-sublink" : "")} to={`${url}/adviceseekerspendingsreport`}>
                        Spendings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/refundsreport" ? "active-sublink" : "")} to={`${url}/refundsreport`}>
                        Refunds
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <h6 className="navbar-subheading" >Operations</h6>
                  <ul className="navbar-nav ml-4">
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/withdrawls" ? "active-sublink" : "")} to={`${url}/withdrawls`}>
                        Withdrawals
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/refunds" ? "active-sublink" : "")} to={`${url}/refunds`}>
                        Refunds
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link " + (props.location.pathname == "/financials/issues" ? "active-sublink" : "")} to={`${url}/issues`}>
                        Issues
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
        <Switch>
          <Route exact path={path}>
            <div className="col-10 p-4">
              <h3>Welcome to Report Manager</h3>
            </div>
          </Route>
          <Route path={`${path}/dashboard`} component={Dashboard} />
          <Route path={`${path}/withdrawls`} component={Withdrawls} />
          {/* <Route path={`${path}/newrefundrequest`} component={NewRefundRequest} /> */}
          <Route path={`${path}/revenuereport`} component={RevenueReport} />
          <Route path={`${path}/expensereport`} component={ExpenseReport} />
          <Route path={`${path}/owwllearnings`} component={OwwllEarnings} />
          <Route path={`${path}/depositsreport`} component={DepositsReport} />
          <Route path={`${path}/balancereport`} component={BalanceReport} />
          <Route path={`${path}/refundsreport`} component={RefundReport} />
          <Route path={`${path}/adviceseekerspendingsreport`} component={AdviceSeekerSpendings} />
          <Route path={`${path}/deposits`} component={Deposits} />
          <Route path={`${path}/bonuses`} component={Bonuses} />
          <Route path={`${path}/earnings`} component={Earnings} />
          <Route path={`${path}/spendings`} component={Spendings} />
          <Route path={`${path}/issues`} component={Issues} />

        </Switch>
      </div>
    </div>
  );
}

export default withAuth(Financials);
