import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ClubHouse from "./../../../images/clubhouse.png";
import TikTok from "./../../../images/ticktok.png";
import Website from "./../../../images/website.png";
import Youtube from "./../../../images/youtube.png";
import Calender from "./../../../images/calendar.png";
import "./Upgrades.css";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSaveAltIcon,
} from "@material-ui/data-grid";
import UserService from "../../../services/user-service/UserService";
import { Modal } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import React from "react";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Utili from "../../../utility/Utility";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const User: any = [];

function Upgrades(props: any) {
  const columns: GridColDef[] = [
    { field: "id", hide: true },
    {
      field: "fullName",
      headerName: "Name",
      flex: 0.4,
      align: "left",
      headerAlign: "center",
      renderCell: (params: any) =>
        params.row.isActive
          ? params.row.isDeleted
            ? "**" + params.row.fullName
            : params.row.fullName
          : "*" + params.row.fullName,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.4,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "swapStatus",
      headerName: "Status",
      flex: 0.2,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 250,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          style={{ background: "#bfa056", color: "#fff" }}
          variant="contained"
          className="MuiButton-textSizeSmall"
          onClick={() => reView(users.find((e: any) => e.id == params.row.id))}
        >
          Review
        </Button>
      ),
    },
  ];

  const [showForm, setShowForm] = useState(false);
  const [users, setUsers] = useState(User);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [userOfficeHours, setUserofficeHours] = useState<any>({});
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    errors: errors1,
    formState: { errors },
  } = useForm();

  const [showUserProfile, setShowUserProfile] = useState(false);
  const closeUserProfile = () => setShowUserProfile(false);
  const openUserProfile = () => setShowUserProfile(true);
  const shortDays: any = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
  useEffect(() => {
    UserService.getPendingUser().then((res) => {
      setUsers(res.data.result);
    });
  }, []);
  const refreshPage = () => {
    window.location.reload();
  };

  const reView = (user: any) => {
    UserService.getProfile(user.id).then((res) => {
      console.log(res.data.result);
      if (
        res.data.result.subscriptionType === null ||
        res.data.result.subscriptionType === ""
      ) {
        res.data.result.subscriptionType = "Paid";
      }

      if (res.data.result.subscriptionType == "MonthlyFree") {
        res.data.result.subscriptionType = "One Month Free";
      }

      if (res.data.result.subscriptionType == "LifeTimeFree") {
        res.data.result.subscriptionType = "Lifetime Free";
      }

      setCurrentUser(res.data.result);
      console.log(currentUser);
      openUserProfile();
    });
    UserService.GetOfficeHours(user.id)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result) {
          setUserofficeHours(res.data.result);
        } else {
          setUserofficeHours(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancel = () => {
    setShowForm(!showForm);
  };
  const submit = (data: any, status: any) => {
    var date = moment();
    data.id = currentUser.id;
    data.status = status;
    data.remarks =
      date.tz("America/New_York").format("MM/DD/YYYY hh:mm A") +
      " - " +
      data.remarks;
    closeUserProfile();
    confirmAlert({
      title: "Confirm",
      message:
        status == "Approved"
          ? "Are you sure to Approve the Swap to Owwll request? You will not be able to change again!"
          : "Are you sure to Reject the Swap to Owwll request? You will not be able to change again!.",
      buttons: [
        {
          label: " Confirm",
          onClick: () => {
            UserService.upgrade(data).then((res) => {
              UserService.getPendingUser().then((res) => {
                setUsers(res.data.result);
              });
            });
            // refreshPage();
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "getpendinguser",
          "updateswapstatus",
          "getuserprofile",
          "getOfficeHours",
        ]}
        inInternal={false}
      />
      <h3 className="mb-3">Upgrades</h3>
      <div style={{ paddingBottom: "40px", width: "100%" }}>
        <h5 className="mb-0 heading">Requests</h5>
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          columns={columns}
          rows={users}
          pageSize={10}
          autoHeight={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
        />
        <div style={{ marginTop: "10px" }}>
          <p style={{ fontSize: "15px" }}>
            <strong>*</strong> Deactivated User
          </p>
          <p style={{ marginTop: "-15px", fontSize: "15px" }}>
            <strong>**</strong> Deleted User
          </p>
        </div>
      </div>
      <Modal show={showUserProfile} onHide={closeUserProfile} size="xl">
        <Modal.Body>
          <form autoComplete="off" noValidate>
            <div className="modal-header">
              <div className="modal-title h4">User Profile</div>
            </div>
            <div className="modal-body-bx">
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Name</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.fullName}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Email</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Title</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.title}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Company/Organization</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {currentUser.organization != null &&
                    currentUser.organization != ""
                      ? currentUser.organization
                      : currentUser.occupation}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Enable</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {currentUser.isActive ? <CheckIcon /> : <CloseIcon />}
                  </p>
                </div>
                <div className="col-2">
                  <label className="lbl">Featured</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {currentUser.isFeatured ? <CheckIcon /> : <CloseIcon />}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">City</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.city}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">State</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.state}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">College</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.collegeAttend}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Date of Birth</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {moment(currentUser.dob).format("MM/DD/YYYY")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Swap Status</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.swapStatus}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Cell Phone #</label>
                </div>
                <div className="col-3">
                  <p className="">{currentUser.phoneNumber}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Social Profiles</label>
                </div>
                <div className="col-3 sm_wrapper">
                  {currentUser.socialProfiles?.map((s: any, i: any) => (
                    <span
                      className="slink"
                      onClick={() =>
                        window.open(
                          "https://" + s.url.replace(/^https?\:\/\//i, ""),
                          "_blank"
                        )
                      }
                      key={i}
                    >
                      {s.media_type == "Facebook" && s.url != "" ? (
                        <Facebook />
                      ) : s.media_type == "Instagram" && s.url != "" ? (
                        <Instagram />
                      ) : s.media_type == "Twitter" && s.url != "" ? (
                        <Twitter />
                      ) : s.media_type == "LinkedIn" && s.url != "" ? (
                        <LinkedIn />
                      ) : s.media_type == "ClubHouse" && s.url != "" ? (
                        <img src={ClubHouse} />
                      ) : s.media_type == "TikTok" && s.url != "" ? (
                        <img src={TikTok} />
                      ) : s.media_type == "Website" && s.url != "" ? (
                        <img src={Website} />
                      ) : s.media_type == "Youtube" && s.url != "" ? (
                        <img src={Youtube} />
                      ) : (
                        ""
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Call Rates</label>
                </div>
                <div className="col-5">
                  <table className="table table-bordered">
                    <thead className="tbl-bk">
                      <tr>
                        <th scope="col">Duration</th>
                        <th scope="col">Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUser.callRates?.map((c: any, i: any) => (
                        <tr key={i}>
                          <td className="text-center">{c.duration} min</td>
                          <td className="text-right">
                            {Utili.formatAmount(c.fee)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {userOfficeHours != null ? (
                <div className="office-hours-sec">
                  <div className="office-hours-title">
                    <label className="lbl">Office Hours</label>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Office Hours</label>
                    </div>
                    <div className="col-5">
                      <table className="table table-bordered">
                        <thead className="tbl-bk">
                          <tr>
                            <th scope="col">Days</th>
                            <th scope="col">Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userOfficeHours.days?.map((c: any, i: any) => (
                            <tr key={i}>
                              <td className="text-left">
                                {shortDays[c.dayOfWeek]}
                              </td>

                              {c.enable && c.fromTime != "" && c.toTime ? (
                                <td className="text-left">
                                  {c.fromTime.replace(":00 ", " ") +
                                    " - " +
                                    c.toTime.replace(":00 ", " ")}
                                </td>
                              ) : (
                                <td className="text-left">N/A</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Start Date</label>
                    </div>

                    <div className="date-row">
                      <p className="">
                        {moment(userOfficeHours.startDate).format("MM/DD/YYYY")}
                      </p>
                      <img src={Calender} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">End Date</label>
                    </div>
                    <div className="date-row">
                      <p className="">
                        {moment(userOfficeHours.endDate).format("MM/DD/YYYY")}
                      </p>
                      <img src={Calender} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Notification</label>
                    </div>
                    <div className="col-10">
                      <p className="">
                        {userOfficeHours.is_notification
                          ? "Enabled"
                          : "Disabled"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-2">
                  <label className="lbl">About</label>
                </div>
                <div className="col-10">
                  <p className="">{currentUser.describeYourself}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Previous Comments</label>
                </div>
                <div className="col-10">
                  {
                    // @ts-ignore
                    currentUser.userSwapStatus?.requestLogs?.map(
                      (h: any, index: any) => (
                        <p className="" key={index}>
                          {h.remarks}
                        </p>
                      )
                    )
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Comments</label>
                    <span className="text-danger asterisk-bx"> *</span>
                  </div>
                  <div className="col-10">
                    <textarea
                      name="remarks"
                      ref={register({ required: true })}
                      className="form-control"
                      placeholder="Comments"
                    />
                    {/* {errors1.remarks && (
                      <span className="text-danger">Commnets is required</span>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Premium Account</label>
                </div>
                <div className="col-10">
                  <p className="">{currentUser.subscriptionType}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-md-10">
                  <div className="pb-4">
                    <span className="pull-left text-danger">
                      All fields are required with Asterisk (
                      <span className="asterisk-bx text-danger">*</span>)
                    </span>
                    <div className="pull-right">
                      <Button variant="contained" onClick={closeUserProfile}>
                        Cancel
                      </Button>
                      {currentUser.isActive === true ||
                      currentUser.isDeleted === false ? (
                        <>
                          <Button
                            className="ml-2 primary"
                            variant="contained"
                            onClick={handleSubmit((data) =>
                              submit(data, "Rejected")
                            )}
                          >
                            Reject
                          </Button>
                          <Button
                            className="ml-2 primary"
                            variant="contained"
                            onClick={handleSubmit((data) =>
                              submit(data, "Approved")
                            )}
                          >
                            Approve
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Upgrades;
