import UserService from "../../../../services/user-service/UserService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import CustomNoRowsOverlay from "../../../../components/NoRecord";
import moment from "moment";
import BackNavigate from "../../../../components/back-naviagtion/BackNavigate";
import SpinnerLoader from "../../../../components/spinner-loader/SpinnerLoader.component";
const Referral: any = [];

function Referrals(props: any) {
  const [referrals, setReferrals] = useState(Referral);
  const params = useParams<any>();

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    { field: "fullName", headerName: "Name", flex: 0.5, headerAlign: "left" },
    { field: "email", headerName: "Email", flex: 0.5, headerAlign: "left" },
    {
      field: "referredOn",
      headerName: "Referral Date",
      flex: 0.5, headerAlign: "left" ,
        valueFormatter: (params) =>
        moment(params.row.referredOn).format("MM/DD/YYYY hh:mm A"),
    },
  ];

  useEffect(() => {
    UserService.getReferrals(params.id).then((res) => {
      setReferrals(res.data.result);
      console.log(res.data.result);
    });
  }, []);

  return (
    <div>
      <SpinnerLoader isGeneral={true} tasksList={
        ['getreferrals',]
      } inInternal={false} />
      <h3 className="mb-3 brand-title">
        Brand Ambassador: <span> {referrals[0]?.refFullName}</span>
        <div className="backbutton float-right">
          <BackNavigate />
        </div>
      </h3>

      <div className="row m-0">
        <div className="col-10 p-0">
          <div style={{ paddingBottom: "40px", width: "120%" }}>
            <div className="mb-0 heading">
              <h5>Referrals</h5>
            </div>
            <div className="data-grid" >
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              columns={columns}
              rows={referrals}
              pageSize={10}
              autoHeight={true}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referrals;
