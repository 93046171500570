import http from "../HttpInterceptor";

const getRevenueReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetRevenueReport?fromDate=" + fromDate + "&toDate=" + toDate,
    {
      headers: { task_name: "getRevenueReport" },
    }
  );
};
const getExpenseReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetExpenseReport?fromDate=" + fromDate + "&toDate=" + toDate,
    {
      headers: { task_name: "GetExpenseReport" },
    }
  );
};
const getEarningsReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetEarningReport?fromDate=" + fromDate + "&toDate=" + toDate,
    {
      headers: { task_name: "GetEarningReport" },
    }
  );
};
const getDepositsReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetDepositReport?fromDate=" + fromDate + "&toDate=" + toDate,
    {
      headers: { task_name: "GetDepositReport" },
    }
  );
};

const getTimeonCallReport = (fromDate: any, toDate: any, name: any) => {
  return http.get(
    "/gateway/GetTimeOnCallReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name,
    {
      headers: { task_name: "getTimeonCallReport" },
    }
  );
};
const getSubscriptionReport = (
  fromDate: any,
  toDate: any,
  name: any,
  email: any
) => {
  return http.get(
    "/gateway/GetSubscriptionReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name +
      "&email=" +
      email,
    {
      headers: { task_name: "getSubscriptionReport" },
    }
  );
};
const getOwwllActiveTimeReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetActiveByExpertReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
    {
      headers: { task_name: "getOwwllActiveTimeReport" },
    }
  );
};
const getAdviceSeekerSpendingsReport = (fromDate: any, toDate: any) => {
  return http.get(
    "/gateway/GetMoneySpentByAdviceReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
    {
      headers: { task_name: "getAdviceSeekerSpendingsReport" },
    }
  );
};
const GetDepositDetailReport = (fromDate: any, toDate: any, name: any) => {
  return http.get(
    "/gateway/GetDepositDetailReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name,
    {
      headers: { task_name: "GetDepositDetailReport" },
    }
  );
};
const GetSpendingDetailReport = (fromDate: any, toDate: any, name: any) => {
  return http.get(
    "/gateway/GetSpendingDetailReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name,
    {
      headers: { task_name: "GetSpendingDetailReport" },
    }
  );
};

const GetBonusDetailReport = (
  fromDate: any,
  toDate: any,
  name: any,
  BonusStatus: any
) => {
  return http.get(
    "/gateway/GetBonusDetailReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name +
      "&BonusStatus=" +
      BonusStatus,
    {
      headers: { task_name: "GetBonusDetailReport" },
    }
  );
};
const GetEarningDetailReport = (fromDate: any, toDate: any, name: any) => {
  return http.get(
    "/gateway/GetEarningDetailReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name,
    {
      headers: { task_name: "GetEarningDetailReport" },
    }
  );
};
const getCallCountReport = (
  fromDate: any,
  toDate: any,
  caller: any,
  callType: any
) => {
  return http.get(
    "/gateway/GetCallsReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&countType=" +
      callType +
      "&isCaller=" +
      (caller == "Caller" ? true : false) +
      "&isReceipient=" +
      (caller == "Recipient" ? true : false),
    {
      headers: { task_name: "GetCallsReport" },
    }
  );
};
const getReferralReport = (name: any) => {
  return http.get("/gateway/GetReferralReport?fullName=" + name, {
    headers: { task_name: "GetReferralReport" },
  });
};

const getBalanceReport = (data: any) => {
  return http.get(
    "/gateway/GetBalanceReport?FullName=" +
      data.fullName +
      "&Role=" +
      data.role +
      "&SortOn=" +
      data.sortOn +
      "&SortDir=" +
      data.sortDir,
    {
      headers: { task_name: "GetBalanceReport" },
    }
  );
};

const GetRefundReport = (
  fromDate: any,
  toDate: any,
  name: any,
  Status: any
) => {
  return http.get(
    "/gateway/GetRefundReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&name=" +
      name +
      "&Status=" +
      Status,
    {
      headers: { task_name: "GetRefundReport" },
    }
  );
};

const GetCallsCountRatioReport = (
  fromDate: any,
  toDate: any,
  userName: any,
  userRole: any,
  userType: any
) => {
  return http.get(
    "gateway/GetCallsCountRatioReport?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&userName=" +
      userName +
      "&userRole=" +
      userRole +
      "&userType=" +
      userType,
    {
      headers: { task_name: "GetCallsCountRatioReport" },
    }
  );
};

export default {
  GetRefundReport,
  GetBonusDetailReport,
  GetSpendingDetailReport,
  GetDepositDetailReport,
  GetEarningDetailReport,
  getAdviceSeekerSpendingsReport,
  getOwwllActiveTimeReport,
  getTimeonCallReport,
  getBalanceReport,
  getRevenueReport,
  getExpenseReport,
  getEarningsReport,
  getDepositsReport,
  getCallCountReport,
  getReferralReport,
  GetCallsCountRatioReport,
  getSubscriptionReport,
};
