import { Button } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import IssuesService from "../../../services/issues-service/IssuesService";
import { useForm } from "react-hook-form";
import moment from 'moment-timezone';
import CustomNoRowsOverlay from "../../../components/NoRecord";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const CallLog: any = [];

function Issues(props: any) {
  const [showGrid, setShowGrid] = useState(true);
  const {
    register: searchCallLog,
    setValue: setValuesSearchCallLog,
    handleSubmit: submitSearchForm,
    reset: resetSearchFrom,
    watch,
    control: contorlSearch,
    getValues: getValuesSearchCallLog,
  } = useForm();
  const { register, handleSubmit, reset, errors } = useForm();

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    { field: "displayNumber", headerName: "Issue Number", width: 200 },
    {
      field: "fullName", headerName: "User", width: 200,
      renderCell: (params: any) =>
        params.row.isActive ?
          params.row.isDeleted ? "**" + params.row.fullName : params.row.fullName
          : "*" + params.row.fullName
    },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "statusDate",
      headerName: "Date",
      width: 200,
      align: "left",
      valueFormatter: (params) =>
        moment(params.row?.statusDate).format("MM/DD/YYYY hh:mm A"),
    },

    { field: "issueStatus", headerName: "Status", width: 120, align: "left" },
    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          style={{ background: "#bfa056", color: "#fff" }}
          variant="contained"
          className="MuiButton-textSizeSmall"
          onClick={() =>
            review(callLogs.find((e: any) => e.id == params.row.id))
          }
        >
          {params.row.issueStatus == "Closed" ? "View" : "Review"}
        </Button>
      ),
    },
  ];

  const [callLogs, setCallLogs] = useState(CallLog);
  const [currentCallLogs, setCurrentCallLogs] = useState<any>({});
  const [detail, setDetail] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const closeDetail = () => setDetail(false);
  const openDetail = () => setDetail(true);

  const review = (callLog: any) => {
    setCurrentCallLogs(callLog);
    openDetail();
  };

  const getCallLog = () => {
    setShowGrid(false);
    setCallLogs(CallLog);
    IssuesService.getAll(getValuesSearchCallLog("status"))
      .then((res) => {
        setCallLogs(res.data.result);
        setShowGrid(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const refreshPage = () => {
    window.location.reload();
  }
  useEffect(() => {
    setValuesSearchCallLog("status", "Open");

    IssuesService.getAll("Open")
      .then((res) => {
        setCallLogs(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submit = (data: any, issueStatus: any) => {
    var date = moment();
    data.id = currentCallLogs.id;
    data.issueStatus = issueStatus;
    data.issueNumber = currentCallLogs.issueNumber;
    if (
      currentCallLogs.comments != "" &&
      currentCallLogs.comments != null &&
      currentCallLogs.comments != undefined
    ) {
      data.comments =
        currentCallLogs.comments +
        "\n" +
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") +
        " - " +
        data.comments;
    } else {
      data.comments =
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") + " - " + data.comments;
    }
    IssuesService.save(data)
      .then((res) => {
        if (res.data.message == "updated") {
          getCallLog();
          // var status = getValuesSearchCallLog("status");
          // IssuesService.getAll(status)
          // .then((res) => {
          //   debugger
          //   setCallLogs(res.data.result);
          // })
          // .catch((err) => {
          //   console.log(err);
          // });
        }

      })
      .catch((err) => {
        console.log(err);
      });

    closeDetail();


  };

  return (
    <div className="">
      <SpinnerLoader isGeneral={true} tasksList={
        ['getcalllogissues', 'updatecalllogissue']
      } inInternal={false} />
      <h3 className="mb-3">Issues</h3>
      <form autoComplete="off" className="form-inline" noValidate>
        <div className="form-group mb-2">
          <div className="p-2">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="All"
                id="all"
                ref={searchCallLog}
                name="status"
                className="custom-control-input"
                onChange={getCallLog}
              />
              <label className="custom-control-label" htmlFor="all">
                All
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="Open"
                id="open"
                ref={searchCallLog}
                name="status"
                className="custom-control-input"
                onChange={getCallLog}
              />
              <label className="custom-control-label" htmlFor="open">
                Open
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="In Progress"
                id="inprogress"
                ref={searchCallLog}
                name="status"
                className="custom-control-input"
                onChange={getCallLog}
              />
              <label className="custom-control-label" htmlFor="inprogress">
                In Progress
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="Closed"
                id="closed"
                ref={searchCallLog}
                name="status"
                className="custom-control-input"
                onChange={getCallLog}
              />
              <label className="custom-control-label" htmlFor="closed">
                Closed
              </label>
            </div>
          </div>
        </div>
      </form>
      <div className="row m-0">
        <div className="col-10 p-0">
          <div style={{ paddingBottom: "40px", width: "120%" }}>
            <div className="mb-0 heading">
              <h5>Issues</h5>
            </div>
            {showGrid ?
              <div className="data-grid">
                <DataGrid
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  rows={callLogs}
                  pageSize={10}
                  autoHeight={true}
                  disableColumnMenu={true}
                  disableSelectionOnClick={true}
                />
                <div style={{ marginTop: '10px' }}>
                  <p style={{ fontSize: '15px' }}><strong>*</strong>  Deactivated User</p>
                  <p style={{ marginTop: '-15px', fontSize: '15px' }}><strong>**</strong> Deleted User</p>
                </div>
              </div> : ''

            }

          </div>
          <Modal centered show={detail} onHide={closeDetail} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Issue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="col-12 mt-2" autoComplete="off" noValidate>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Issue Number</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentCallLogs.displayNumber}</p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Name</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentCallLogs.fullName}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Description</label>
                  </div>
                  <div className="col-10">
                    <p className="text-wrap">{currentCallLogs.description}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Date</label>
                  </div>
                  <div className="col-3">
                    <p className="">
                      {moment(currentCallLogs.statusDate).format(
                        "MM/DD/YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                  <div className="col-2">
                    <label className="lbl">Status</label>
                  </div>
                  <div className="col-3">
                    <p className="">{currentCallLogs.issueStatus}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <label className="lbl">Previous Comments</label>
                  </div>
                  <div className="col-10">
                    <p className="">{currentCallLogs.comments}</p>
                  </div>
                </div>
                {currentCallLogs.issueStatus == "Closed" ? (
                  ""
                ) : (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-2">
                        <label className="lbl">Comments</label>
                        <span className="text-danger"> *</span>
                      </div>
                      <div className="col-10">
                        <textarea
                          name="comments"
                          ref={register({ required: true })}
                          className="form-control"
                          placeholder="comments"
                        />
                        {errors.comments && (
                          <span className="text-danger">
                            Comments are required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <br />
                <div className="pull-right p-3">
                  <Button
                    className="mr-2"
                    variant="contained"
                    onClick={closeDetail}
                  >
                    Cancel
                  </Button>
                  {currentCallLogs.issueStatus == "In Progress" ||
                    currentCallLogs.issueStatus == "Closed" || (currentCallLogs.issueStatus === "Open" && (currentCallLogs.isActive == false || currentCallLogs.isDeleted == true)) ? (
                    ""
                  ) : (
                    <Button
                      variant="contained"
                      style={{ background: "#bfa056", color: "#fff" }}
                      onClick={handleSubmit((data) =>
                        submit(data, "In Progress")
                      )}
                    >
                      In Progress
                    </Button>
                  )}
                  {currentCallLogs.issueStatus == "Closed" || (currentCallLogs.issueStatus === "Open" && (currentCallLogs.isActive == false || currentCallLogs.isDeleted == true)) ? (
                    ""
                  ) : (
                    <Button
                      className="ml-2"
                      style={{ background: "#bfa056", color: "#fff" }}
                      variant="contained"
                      onClick={handleSubmit((data) => submit(data, "Closed"))}
                    >
                      Close
                    </Button>
                  )}
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Issues;
