import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/LeftNav.css"
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Reports from "./pages/reports/Reports";
import Administrator from "./pages/administrator/Administrator";
import Financials from "./pages/financials/Financials";
import Attributes from "./pages/attributes/Attributes";
import Settings from './pages/settings/Settings';
import "react-confirm-alert/src/react-confirm-alert.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App(props: any) {


  return (
    <div>
      <div className="App">
        {/* {loader ? (
          <div id="overlay">
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )} */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <ToastContainer />
        <Router>
          <Switch>
            <Route path="/settings" component={Settings} />
            <Route path="/administrator" component={Administrator} />
            <Route path="/financials" component={Financials} />
            <Route path="/attributes" component={Attributes} />
            <Route path="/reports" component={Reports} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}
export default App;
