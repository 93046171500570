import http from "../HttpInterceptor";

 

const BroadCastMessages = (data: any) => {
  return http.post("gateway/SendBackgroundMessages", data, {
    headers: {'task_name': 'SendBackgroundMessages'}
});
};

export default {
    BroadCastMessages,
};