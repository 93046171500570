import http from "../HttpInterceptor";

const GetActiveNotiConfiguration = () => {
  return http.get("gateway/settings/GetActiveNotiConfiguration", {
    headers: { task_name: "GetActiveNotiConfiguration" },
  });
};

const getAppConfiguration = () => {
  return http.get("gateway/settings/GetAppConfiguration", {
    headers: { task_name: "GetAppConfiguration" },
  });
};

const GetGatedSignupConfig = () => {
  return http.get("gateway/settings/GetGatedSignupConfig", {
    headers: { task_name: "GetGatedSignupConfig" },
  });
};
const GetAppConfigs = () => {
  return http.get("gateway/settings/GetAppConfigs", {
    headers: { task_name: "GetAppConfigs" },
  });
};
const GetSignupBonusExpiryDaysConfig = () => {
  return http.get("gateway/settings/GetSignupBonusExpiryDaysConfig", {
    headers: { task_name: "GetSignupBonusExpiryDaysConfig" },
  });
};
const GetReferralBonusExpiryDaysConfig = () => {
  return http.get("gateway/settings/GetReferralBonusExpiryDaysConfig", {
    headers: { task_name: "GetReferralBonusExpiryDaysConfig" },
  });
};
const GetTopupBonusExpiryDaysConfig = () => {
  return http.get("gateway/settings/GetTopupBonusExpiryDaysConfig", {
    headers: { task_name: "GetTopupBonusExpiryDaysConfig" },
  });
};
const GetCallSourceOrder = () => {
  return http.get("gateway/settings/GetCallSourceOrder", {
    headers: { task_name: "GetCallSourceOrder" },
  });
};
const GetCallSourceOrderWithdrawal = () => {
  return http.get("gateway/settings/GetCallSourceOrderWithdrawal", {
    headers: { task_name: "GetCallSourceOrderWithdrawal" },
  });
};

const GetBrandAmbassadorConfigs = () => {
  return http.get("gateway/settings/GetBrandAmbassadorConfigs", {
    headers: { task_name: "GetBrandAmbassadorConfigs" },
  });
};

const UpdateGatedSignupConfig = (data: any) => {
  return http.post("gateway/settings/UpdateGatedSignupConfig", data, {
    headers: { task_name: "UpdateGatedSignupConfig" },
  });
};
const UpdateSignupBonusAllowedConfig = (data: any) => {
  return http.post(
    `gateway/settings/UpdateSignupBonusAllowedConfig?allowed=${data}`,
    null,
    {
      headers: { task_name: "UpdateSignupBonusAllowedConfig" },
    }
  );
};

const UpdateSignupBonusAmountConfig = (data: any) => {
  return http.post(
    `gateway/settings/UpdateSignupBonusAmountConfig?amount=${data}`,
    null,
    {
      headers: { task_name: "UpdateSignupBonusAmountConfig" },
    }
  );
};

const UpdateSignupBonusExpiryDaysConfig = (data: any) => {
  return http.post(
    `gateway/settings/UpdateSignupBonusExpiryDaysConfig?amount=${data}`,
    null,
    {
      headers: { task_name: "UpdateSignupBonusExpiryDaysConfig" },
    }
  );
};
const UpdateReferralBonusExpiryDaysConfig = (data: any) => {
  return http.post(
    `gateway/settings/UpdateReferralBonusExpiryDaysConfig?amount=${data}`,
    null,
    {
      headers: { task_name: "UpdateReferralBonusExpiryDaysConfig" },
    }
  );
};
const UpdateTopupBonusExpiryDaysConfig = (data: any) => {
  return http.post(
    `gateway/settings/UpdateTopupBonusExpiryDaysConfig?amount=${data}`,
    null,
    {
      headers: { task_name: "UpdateTopupBonusExpiryDaysConfig" },
    }
  );
};

const UpdateAppConfiguration = (data: any) => {
  return http.post("gateway/settings/UpdateAppConfiguration", data, {
    headers: { task_name: "UpdateGatedSignupConfig" },
  });
};
const UpdateWithdrawalLimitConfig = (data: any) => {
  return http.post("gateway/settings/UpdateWithdrawalLimitConfig", data, {
    headers: { task_name: "UpdateWithdrawalLimitConfig" },
  });
};
const UpdateDepositLimitConfig = (data: any) => {
  return http.post("gateway/settings/UpdateDepositLimitConfig", data, {
    headers: { task_name: "UpdateDepositLimitConfig" },
  });
};

const getAllEnabledCategories = () => {
  return http.get("gateway/settings/GetAllEnabledCategories", {
    headers: { task_name: "GetAllEnabledCategori" },
  });
};

const selectEnabledCategoriesForOrdering = (categoryName: any) => {
  debugger;
  return http.post(
    `gateway/settings/SelectEnabledCategoriesForOrdering?categoryName=${categoryName}`,
    null,
    {
      headers: { task_name: "SelectEnabledCategoriesForOrdering" },
    }
  );
};

const getSelectedCategories = () => {
  return http.get("gateway/settings/GetSelectedCategories", {
    headers: { task_name: "GetSelectedCategories" },
  });
};

const removeSelectedCategory = (categoryName: string) => {
  return http.post(
    `gateway/settings/RemoveSelectedCategory?categoryName=${categoryName}`,
    null,
    {
      headers: { task_name: "RemoveSelectedCategory" },
    }
  );
};

const UpdateCategoryOrder = (data: any) => {
  return http.post("gateway/settings/UpdateCategoryOrder", data, {
    headers: { task_name: "UpdateCategoryOrder" },
  });
};

const update = (data: any) => {
  return http.post("gateway/settings/UpdateNotificationConfigs", data, {
    headers: { task_name: "UpdateNotificationConfigs" },
  });
};
const updateExpertNotificationTime = (startTime: string, endTime: string) => {
  return http.post(
    `gateway/settings/UpdateExpertNotificationTime?startTime=${startTime}&endTime=${endTime}`,
    null,
    {
      headers: { task_name: "updateExpertNotificationTime" },
    }
  );
};
export default {
  UpdateTopupBonusExpiryDaysConfig,
  UpdateReferralBonusExpiryDaysConfig,
  UpdateSignupBonusExpiryDaysConfig,
  GetTopupBonusExpiryDaysConfig,
  GetReferralBonusExpiryDaysConfig,
  GetSignupBonusExpiryDaysConfig,
  UpdateDepositLimitConfig,
  UpdateWithdrawalLimitConfig,
  UpdateSignupBonusAmountConfig,
  UpdateSignupBonusAllowedConfig,
  GetCallSourceOrderWithdrawal,
  GetCallSourceOrder,
  GetBrandAmbassadorConfigs,
  GetAppConfigs,
  UpdateGatedSignupConfig,
  GetGatedSignupConfig,
  UpdateAppConfiguration,
  getAppConfiguration,
  GetActiveNotiConfiguration,
  updateExpertNotificationTime,
  update,
  getAllEnabledCategories,
  selectEnabledCategoriesForOrdering,
  getSelectedCategories,
  removeSelectedCategory,
  UpdateCategoryOrder,
};
