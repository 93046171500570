import { Button } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import CustomNoRowsOverlay from '../NoRecord';
import UserService from '../../services/user-service/UserService';
import moment from "moment";
import Utility from '../../utility/Utility';
const List: any = [];
function TopUp(props: any) {
    const [bonusList, setBonusList] = useState(List);
    const columns: GridColDef[] = [
        { field: "id", hide: true },
        {
            field: "bonusIssueDate",
            headerName: "Issue Date",
            flex: 0.5,
            headerAlign: "center",
            align: "center",
            headerClassName: "grid-h",
            valueFormatter: (params) =>
                moment(params.row.bonusIssueDate).format("MM/DD/YYYY hh:mm A"),
        },
        {
            field: "bonusExpiryDate",
            headerName: "Expiry Date",
            flex: 0.5,
            headerAlign: "center",
            align: "center",
            headerClassName: "grid-h",
            valueFormatter: (params) =>
                moment(params.row.bonusExpiryDate).format("MM/DD/YYYY hh:mm A"),
        },
        {
            field: "amount",
            headerName: "Bonus Amount ($)",
            width: 200,
            headerAlign: "center",
            align: "center",
            headerClassName: "grid-h",
        },
        {
            field: "balanceAmount",
            headerName: "Current Balance ($)",
            width: 200,
            headerAlign: "center",
            align: "center",
            headerClassName: "grid-h",
        },

    ];
    const [userProfile, setUserProfile] = useState<any>(props.UserProfile);
    const [errorTopupBonus, seterrorTopupBonus] = useState<any>(false);
    const [errorTopupAmount, seterrorTopupAmount] = useState<any>(false);
    const [isDecimal, setDecimal] = useState<any>(false);
    const {
        register: reg_Topup,
        setValue: setValues_Topup,
        control,
        handleSubmit: submit_Topup,
        reset: reset_Topup,
        getValues: getValues_Topup,
    } = useForm();
    const onSubmit = (data: any) => {
        if (data.toExpireDays === '' || parseInt(data.toExpireDays) < 1) {
            seterrorTopupBonus(true);
        } else if (data.bonusAmoumnt === '' || parseInt(data.bonusAmoumnt) < 1) {
            seterrorTopupAmount(true);
        } else {
            UserService.addTopupBonus(data).then((res: any) => {
                if (res.data) {
                    Utility.notifyUpdated();
                    UserService.getTopUpBonuses(data.userId).then((res: any) => {
                        setBonusList(res.data.result)
                    }).catch((err) => {
                        console.log(err)
                    })
                }
                console.log(res.data)
                setDecimal(false)
            }).catch((err) => {
                setDecimal(true)
                console.log(err)
            })
            seterrorTopupAmount(false);
            seterrorTopupBonus(false);
        }

    }
    useEffect(() => {

        UserService.getTopUpBonuses(props.UserProfile.id).then((res: any) => {
            setBonusList(res.data.result);
            console.log(res.data.result);
        }).catch((err) => {
            console.log(err)
        })
    }, []);
    return (
        <>
            <div className='mt-4' style={{ marginBottom: '0px' }}>
                <label className='ml-4' >Bonus amount</label>
                <input hidden name="userId" ref={reg_Topup} value={props.UserProfile.id} />
                <label className='ml-4 mr-1' >$</label>
                <input
                    className='mr-4'
                    style={{ width: 200, outline: 0 }}
                    type='number' min={0}
                    name="bonusAmoumnt"
                    pattern="[0-9]+"
                    ref={reg_Topup({ required: true })}
                />

                <label className='ml-4' >Expiring in</label>

                <input
                    className='ml-4'
                    pattern="[0-9]+"
                    style={{ width: 100, outline: 0 }}
                    type='number' min={0}
                    name="toExpireDays"
                    ref={reg_Topup({ required: true })}
                />
                <label className='mr-4 ml-1' >Day(s)</label>
                <Button
                    onClick={submit_Topup(onSubmit)}
                    variant="contained"
                    className="MuiButton-textSizeSmall primary"
                    size="small"
                >
                    Top-up
                </Button>
            </div>
            {isDecimal ?
                <p style={{ color: 'red', marginLeft: '10%' }}>Topup Amount and Expiry Day(s) cannot be in decimal</p>
                :
                ''}
            {errorTopupAmount ?
                <p style={{ color: 'red', marginLeft: '10%' }}>Topup Amount cannot be zero or negative Value</p>
                :
                ''}
            {errorTopupBonus ?
                <p style={{ color: 'red', marginLeft: '30%' }}>Topup Bonus Expiry cannot be zero or negative day</p>
                :
                ''}
            <div className='table-o'>
                <h5 className="mb-0 heading">History</h5>
                <DataGrid
                    getRowId={(row) => row.id}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    columns={columns}
                    rows={bonusList}
                    pageSize={10}
                    autoHeight={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                />

            </div>
        </>
    )
}

export default TopUp