import http from "../HttpInterceptor";

const getAll = () => {
  return http.get("gateway/admin/getallusers");
};

const getAllUser = () => {
  return http.get("gateway/getallusers", {
    headers: { task_name: "getAllUser" },
  });
};

const getProfile = (id: any) => {
  return http.get(`gateway/user/getuserprofile?userId=${id}`, {
    headers: { task_name: "getuserprofile" },
  });
};

const GetOfficeHours = (id: any) => {
  return http.get(`gateway//GetOfficeHours?userId=${id}`, {
    headers: { task_name: "getOfficeHours" },
  });
};

const getUserPreference = (userId: any, messageOption: any) => {
  return http.get(
    `/gateway/GetPreference?userId=${userId}` +
      "&messageOption=" +
      messageOption,
    {
      headers: { task_name: "GetPreference" },
    }
  );
};

const create = (data: any) => {
  return http.post("gateway/user/createadmin", data, {
    headers: { task_name: "createadmin" },
  });
};

const onAddPreference = (data: any) => {
  return http.post("/gateway/AddPreference", data, {
    headers: { task_name: "AddPreference" },
  });
};

const update = (data: any) => {
  return http.post("gateway/user/update", data, {
    headers: { task_name: "updateuser" },
  });
};

const updateAdmin = (data: any) => {
  return http.post("gateway/user/updateadmin", data, {
    headers: { task_name: "updateadmin" },
  });
};

const remove = (id: any) => {
  return http.delete(`gateway/user/${id}`);
};

const searchEmail = (data: any) => {
  return http.post("gateway/user/search", data, {
    headers: { task_name: "searchEmail" },
  });
};
const searchSignupRequests = (data: any) => {
  return http.post("gateway/user/searchsignuprequests", data, {
    headers: { task_name: "searchsignuprequests" },
  });
};
const searchDeletionRequests = (data: any) => {
  // data.type=0;
  return http.post("/gateway/SearchDeletedUser", data, {
    headers: { task_name: "SearchDeletedUser" },
  });
};
const searchDeactivationRequests = (data: any) => {
  // data.type=1;
  return http.post("/gateway/SearchDeletedUser", data, {
    headers: { task_name: "SearchDeletedUser" },
  });
};
const addTopupBonus = (data: any) => {
  return http.post("gateway/addTopupBonus", data, {
    headers: { task_name: "addTopupBonus" },
  });
};

const getUserBonusTranscationHistory = (id: any) => {
  return http.get(`gateway/getUserBonusTranscationHistory?UserId=${id}`, {
    headers: { task_name: "getUserBonusTranscationHistory" },
  });
};

const getTopUpBonuses = (id: any) => {
  return http.get(`gateway/getTopUpBonuses?UserId=${id}`, {
    headers: { task_name: "getTopUpBonuses" },
  });
};

const approveSignupRequests = (data: any) => {
  return http.post("gateway/user/approveSignupRequests", data, {
    headers: { task_name: "approveSignupRequests" },
  });
};
const processUserRemoval = (data: any) => {
  return http.post("gateway/ProcessUserRemoval", data, {
    headers: { task_name: "ProcessUserRemoval" },
  });
};

const getPendingUser = () => {
  return http.get("gateway/user/getpendinguser", {
    headers: { task_name: "getpendinguser" },
  });
};

const upgrade = (data: any) => {
  return http.post(`gateway/updateswapstatus`, data, {
    headers: { task_name: "updateswapstatus" },
  });
};
const getFeedBack = (id: any) => {
  return http.get(`gateway/getfeedback/${id}`, {
    headers: { task_name: "getfeedback" },
  });
};

const getReferrals = (id: any) => {
  return http.get(`gateway/getreferrals/${id}`, {
    headers: { task_name: "getreferrals" },
  });
};

const UpdateFeedback = (id: any) => {
  return http.post(`gateway/updateFeedback`, id, {
    headers: { task_name: "updateFeedback" },
  });
};

const AddReferralCode = (data: any) => {
  return http.post(`gateway/UpdateReferralCodeByAdmin`, data, {
    headers: { task_name: "addReferralCode" },
  });
};

const GetRefundReasonConfigs = () => {
  return http.get("gateway/settings/GetRefundReasonConfigs", {
    headers: { task_name: "GetRefundReasonConfigs" },
  });
};

const GetAllRefundRequests = (data: any) => {
  return http.get(
    "gateway/GetAllRefundRequests?pageSize=" +
      data.pageSize +
      "&pageNumber=" +
      data.pageNumber +
      "&name=" +
      data.name +
      "&status=" +
      data.status,
    {
      headers: { task_name: "GetAllRefundRequests" },
    }
  );
};

const GetCallDetail = (callID: any) => {
  return http.get("gateway/GetCallDetail?callLogId=" + callID, {
    headers: { task_name: "GetCallDetail" },
  });
};

const GetCallLogsListForRefundAction = (data: any) => {
  return http.get(
    "gateway/GetCallLogsListForRefundAction?caller=" +
      data.caller +
      "&receiver=" +
      data.receiver,
    {
      headers: { task_name: "GetCallLogsListForRefundAction" },
    }
  );
};

const UpdateRefundRequest = (data: any) => {
  return http.post(`gateway/UpdateRefundRequest`, data, {
    headers: { task_name: "UpdateRefundRequest" },
  });
};

const GetUsersPhoneList = (data: any) => {
  return http.post(`gateway/getuserphonelist`, data, {
    headers: { task_name: "GetUserPhoneList" },
  });
};

export default {
  processUserRemoval,
  searchDeletionRequests,
  UpdateRefundRequest,
  GetCallLogsListForRefundAction,
  GetCallDetail,
  GetAllRefundRequests,
  GetRefundReasonConfigs,
  addTopupBonus,
  getUserBonusTranscationHistory,
  AddReferralCode,
  approveSignupRequests,
  searchSignupRequests,
  getPendingUser,
  updateAdmin,
  getAll,
  getAllUser,
  getProfile,
  create,
  update,
  remove,
  searchEmail,
  upgrade,
  getFeedBack,
  UpdateFeedback,
  getReferrals,
  getTopUpBonuses,
  GetUsersPhoneList,
  searchDeactivationRequests,
  getUserPreference,
  onAddPreference,
  GetOfficeHours,
};
