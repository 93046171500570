import React, { useEffect, useState } from "react";
import "./SourceOption.css";

import { DataGrid, GridRowsProp, GridColDef } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { spinnerService } from "../../../services/SpinnerService";
import AttributeService from "../../../services/attribute-service/AttributeService";
import { Attributes } from "../categories/Categories";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import CustomNoRowsOverlay from "../../../components/NoRecord";

function SourceOption(props: any) {
  const [show, setShow] = useState(false);
  const [hobbiesSource, setHobbiesSource] = useState(Array<Attributes>());
  const [origionalHobbies, setOrigionalHobbies] = useState(Array<Attributes>());
  const [hobbyId, setHobbyId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [hobbyValue, setHobbyValue] = useState("");
  const [searchName, setSearchName] = useState("");
  const [addingHobby, setAddingHobby] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [existMessage, setExistMessage] = useState(false);

  const handleClose = () => {
    setHobbyId("");
    setIsActive(false);
    setHobbyValue("");
    setShow(false);
    setExistMessage(false);
  };
  const handleShow = () => {
    setAddingHobby(true);
    setShow(true);
  };

  useEffect(() => {
    // spinnerService.showLoading(true);
    loadAllHobbies();
    // spinnerService.showLoading(false);
  }, []);
  const loadAllHobbies = () => {
    AttributeService.getAllSourceOptions().then((res) => {
      setHobbiesSource(res.data.result);
      setOrigionalHobbies(res.data.result);
    });
  };
  const rows: GridRowsProp = hobbiesSource;

  const columns: GridColDef[] = [
    { field: "name", headerName: "Source", width: 250 },
    {
      field: "isActive",
      headerName: "Enabled",
      width: 150,
      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "col6",
      headerName: " ",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>
            <IconButton
              data-toggle="modal"
              data-target="#editModal"
              onClick={() =>
                edit(hobbiesSource.find((e: any) => e.id == params.row.id))
              }
              aria-label="edit"
            >
              <EditIcon className="edit" fontSize="small" />
            </IconButton>{" "}
          </strong>
        </div>
      ),
    },
  ];

  const edit = (Source: any) => {
    setAddingHobby(false);
    console.log(JSON.stringify(Source));
    setHobbyId(Source.id);
    setHobbyValue(Source.name);
    setIsActive(Source.isActive);
    setShow(true);
  };
  const onSave = () => {
    var data = {
      id: hobbyId,
      name: hobbyValue.trim(),
      isActive: isActive,
    };

    AttributeService.SaveSource(data).then((res) => {
      if (res.data.result == null) {
        setExistMessage(true);
        return;
      } else {
        loadAllHobbies();
        // setHobbiesSource(res.data.result);
        // setOrigionalHobbies(res.data.result);

        setTimeout(() => {
          searchHandler();
          setShow(false);
        }, 200);
        setTimeout(() => {
          setHobbyId("");
          setHobbyValue("");
          setIsActive(false);
          setExistMessage(false);
        }, 2000);
      }
    });
  };
  function handleInputChange(event: any) {
    setHobbyValue(event.target.value);
  }
  function handleCheckboxClick(event: any) {
    setIsActive((prevValue) => !prevValue); // invert value
  }
  function handleSearchInputChange(event: any) {
    setSearchName(event.target.value);
  }
  function keyPress(e: any) {
    if (e.keyCode == 13) {
      e.preventDefault();
      searchHandler();
    }
  }
  const searchHandler = () => {
    let searcQuery = searchName.toLowerCase();
    // if (searcQuery == "") {
    //   return;
    // }
    const displayedContacts = origionalHobbies.filter((el: any) => {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(searcQuery) !== -1;
    });
    setHobbiesSource(displayedContacts);
  };
  return (
    <div className="p-3 mt-2 col-10">
      <div className="col-12">
        <h3 className="mb-3">Manage Source Options</h3>
      </div>
      <div className="col-12">
        <h5 className="mb-0 heading">Search</h5>
        <div className="p-3 border" style={{ backgroundColor: "#E1E2E1" }}>
          <form autoComplete="off" className="form-inline">
            <div className="form-group mb-2">
              <label className="m-2">Source Option</label>
              <input
                className="form-control form-control-sm sr-input"
                value={searchName}
                onChange={handleSearchInputChange}
                style={{ backgroundColor: "#fff" }}
                //ref={searchForm({ required: false })}
                placeholder="Search Source Option"
                onKeyDown={keyPress}
              />
              <div className="float-right">
                <button
                  type="button"
                  onClick={searchHandler}
                  className="btn search-btn float-right ml-5"
                >
                  <i className="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="p-3 col-12">
        <div
          style={{
            height: 730,
            paddingBottom: "80px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <div className="mb-0 heading">
            <h5>
              Source Options
              <button
                color="secondary"
                onClick={handleShow}
                className="btn btn-sm btn-outline-secondary float-right"
              >
                Add Source
              </button>
            </h5>
          </div>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              {addingHobby ? "Add Source" : "Edit Source"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-bx">
              <div className="form-group">
                <label className="">Source</label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="category"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Source"
                  value={hobbyValue}
                  ref={register({ required: true })}
                  onChange={handleInputChange}
                  required
                />
                {errors.category && (
                  <span style={{ color: "#ff0000" }}>Source is required</span>
                )}
              </div>

              <div className="custom-checkbox">
                <label>
                  <input
                    name="isActive"
                    type="checkbox"
                    checked={isActive}
                    ref={register}
                    onChange={handleCheckboxClick}
                  />
                  <span className=""> Enabled</span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {existMessage && (
              <span style={{ color: "#ff0000" }}>Source already exist.</span>
            )}
            <Button
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSave)}
              style={{ background: "#bfa056", color: "#fff" }}
              variant="contained"
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default SourceOption;
