import http from "../HttpInterceptor";

const getAll = (param: any) => {
  return http.get("gateway/getcalllogissues?param=" + param, {
    headers: {'task_name': 'getcalllogissues'}
});
};


const save = (data: any) => {
  return http.post("gateway/updatecalllogissue", data, {
    headers: {'task_name': 'updatecalllogissue'}
});
};

export default {
  getAll,
  // get,
  save,
};