import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReportService from "../../../services/report-service/ReportService";
import Utili from "../../../utility/Utility";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function ReferralReport(props: any) {


  const classes = useStyles();
  const [reportData, setReportData] = useState("");
  const [pdfBytes, setpdfBytes] = useState("");

  const [fullName, setFullName] = React.useState("");

  const handleFullNameChange = (name: any) => {
    console.log("name=" + name.target.value);
    setFullName(name.target.value);
  };


  const getReferral = () => {

    console.log("fullName=" + fullName);
    ReportService.getReferralReport(fullName == "" ? "" : fullName).then(
      (res: any) => {
        setReportData(res.data.result.reportHTML);
        setpdfBytes(res.data.result.reportBytes);
        setShowDownloadBtn(true);
      }
    );
  };
  const ClearSearchCriteria = () => {
    setFullName("");
  };
  const Download = () => {
    var blob = Utili.b64toBlob(pdfBytes);
    var url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "ReferralReport";
    a.click();
  };
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  return (
    <div className="col-12">
       <SpinnerLoader isGeneral={true} tasksList={
        ['GetReferralReport']
      } inInternal={false} />
      <h3>Referrals</h3>
      <div className="pt-2">
        <div className="formouter">
          <form className={classes.container} noValidate>
            <label className="m-1">Name</label>
            <input
              className="form-control form-control-sm"
              type="name"
              name="txtfullName"
              value={fullName}
              onChange={handleFullNameChange}
              style={{ backgroundColor: "#fff" }}
              //ref={searchForm({ required: false })}
              placeholder="Name"
            />

            <button
              color="secondary"
              onClick={ClearSearchCriteria}
              type="button"
              className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
            >
              Reset
            </button>
            <button
              color="secondary"
              onClick={getReferral}
              type="button"
              className="btn btn-sm  float-right search-btn stnd-btn view-r-btn"
            >
              View Report
            </button>
          </form>
        </div>
        <div className="mt-4">
          <div className="mb-0 heading">
            <h5>
              Report{" "}
              {showDownloadBtn ? (
                <button
                  color="secondary"
                  onClick={Download}
                  type="button"
                  className="btn btn-sm btn-outline-secondary float-right"
                >
                  Save as
                </button>
              ) : (
                ""
              )}
            </h5>
          </div>
          <div className="report-view-bx">
            <div dangerouslySetInnerHTML={{ __html: reportData }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralReport;
