import { useEffect } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { authService } from '../../services/AuthService';
import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const withAuth = (Component: any) => (props: any) => {

    const history = useHistory();
    let { path } = useRouteMatch();

    useEffect(() => {
        let isAuth = authService.checkAuth();
        if (!isAuth) {
            history.push("/login");
        } else {
            if (path == '/login') {
                history.push("/");
            }
        }
    }, [])

    return (
        <>
         <Header {...props} />
        <Component {...props} />
        <Footer {...props}/>
        </>
       
    )
};
export default withAuth;