import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Refunds.css";
import {
  DataGrid,
  GridColDef,
} from "@material-ui/data-grid";
import UserService from "../../../services/user-service/UserService";
import { Modal } from "react-bootstrap";
import moment from 'moment-timezone';
import { confirmAlert } from "react-confirm-alert";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
import { Link } from "react-router-dom";
import Utility from "../../../utility/Utility";

const User: any = [];

function Refunds(props: any) {

  const [page, setPage] = useState(0);
  const [RowCount, setRowCount] = useState<any>(0);
  const [requestStatus, setRequestStatus] = useState<any>("Pending");
  const [fullName, setFullName] = useState("");
  const [refundList, setrefundList] = useState(User);
  const [callDetail, setcallDetail] = useState<any>({});
  const [requestDetail, setrequestDetail] = useState<any>({});
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showReviewButton, setSetReviewButton] = useState(false);

  const closeUserProfile = () => {
    setcallDetail({});
    setrequestDetail({});
    setShowUserProfile(false);
  }
  const openUserProfile = () => setShowUserProfile(true);
  const [RefundReason, setRefundReason] = useState([]);

  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    reset: resetSearchFrom,
    watch,
    control: contorlSearch,
    getValues: getValuesSearchForm,
  } = useForm();

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    {
      field: "issueNumber",
      headerName: "Issue Number",
      width: 200,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "callWithFirstName",
      headerName: "User",
      flex: 0.4,
      align: "left",
      headerAlign: "center",
      renderCell: (params: any) =>
        params.row.isActive ?
          params.row.isDeleted ? "**" + params.row.callWithFirstName : params.row.callWithFirstName
          : "*" + params.row.callWithFirstName

    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.4,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "refundCreatedDate",
      headerName: "Date",
      width: 160,
      // align: "left",
      // headerAlign: "center",
      valueFormatter: (params) => (
        params.row.refundCreatedDate &&
        moment(params.row.refundCreatedDate).format("MM/DD/YYYY hh:mm A")
      ),

    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          style={{ background: "#bfa056", color: "#fff" }}
          variant="contained"
          className="MuiButton-textSizeSmall"
          onClick={() => reView(refundList.find((e: any) => e.callId == params.row.callId))}
        >
          {params.row.status == "Approved" || params.row.status == "Rejected"
            ? "View"
            : "Review"}
        </Button>
      ),
    },
  ];



  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    errors: errors1,
    formState: { errors },
  } = useForm();

  const pagination = (page: any) => {
    setPage(page);
    let data = {
      pageSize: 10,
      pageNumber: page,
      name: fullName,
      status: requestStatus
    }
    UserService.GetAllRefundRequests(data).then((res) => {
      setRowCount(res.data.item1)
      //console.log(res.data.item2);
      setrefundList(res.data.item2);
    });
  }

  useEffect(() => {
    setValuesSearchForm("status", "Pending");
    let data = {
      pageSize: 10,
      pageNumber: 0,
      name: fullName,
      status: requestStatus,
    }
    setrefundList(User)
    UserService.GetAllRefundRequests(data).then((res) => {
      setRowCount(res.data.item1)
      setrefundList(res.data.item2);
    });
    // UserService.GetRefundReasonConfigs().then((res) => {
    //   setRefundReason(res.data.result)

    // })
  }, []);

  const reView = (user: any) => {
    debugger;
    setSetReviewButton(user.isActive==false || user.isDeleted );
    console.log(user.callId);
    setcallDetail({});
    setrequestDetail(({}));
    UserService.GetCallDetail(user.callId).then((res) => {
      if (res.data) {
        setcallDetail(res.data.callDTO);
        setrequestDetail(res.data.refundRequestDetialDTO);
      }

    });


    openUserProfile();
  };
  const handleNameChange = (data: any) => {
    setFullName(data.target.value);
  }
  const search = () => {
    let data = {
      pageSize: 10,
      pageNumber: 0,
      name: fullName,
      status: requestStatus
    }
    setrefundList(User)
    UserService.GetAllRefundRequests(data).then((res) => {
      setRowCount(res.data.item1)
      setrefundList(res.data.item2);
    });
  }
  const submit = (data: any, status: any) => {
    data.status = status;
    data.reason = callDetail.refundReason;
    data.additionalDetail = callDetail.additionalDetail;
    closeUserProfile();
    confirmAlert({
      title: "Confirm",
      message:
        status == "Approved"
          ? "Are you sure to Approve the Refund request? You will not be able to change again!"
          : "Are you sure to Reject the Refund request? You will not be able to change again!.",
      buttons: [
        {
          label: " Confirm",
          onClick: () => {
            UserService.UpdateRefundRequest(data).then((res) => {
              Utility.notifyUpdated();
              let GetAllRefund = {
                pageSize: 10,
                name: fullName,
                pageNumber: page,
                status: requestStatus
              }
              setrefundList(User)
              UserService.GetAllRefundRequests(GetAllRefund).then((res) => {
                setRowCount(res.data.item1)
                setrefundList(res.data.item2);
              });

            });
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };

  const getfilter = (data: any) => {
    setPage(0);
    if (data.target.value !== undefined) {
      setRequestStatus(data.target.value);
    }
    console.log(data.target.value);
    if (data.target.value === undefined) {
      data.target.value = requestStatus;
    }

    let GetAllRefund = {
      pageSize: 10,
      name: fullName,
      pageNumber: page,
      status: data.target.value,
    }
    setrefundList(User);
    UserService.GetAllRefundRequests(GetAllRefund).then((res: any) => {
      setRowCount(res.data.item1)
      setrefundList(res.data.item2);
    });
  };

  return (
    <div className="">
      <SpinnerLoader isGeneral={true} tasksList={
        ['GetAllRefundRequests', 'GetCallDetail', 'UpdateRefundRequest']
      } inInternal={false} />


      <h3 className="mb-3">Refunds</h3>

      <div>
        <form className="form-inline signup-up-sec mb-2">
          <div className="lft-sec">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="All"
                id="all"
                name="status"
                className="custom-control-input"
                ref={searchForm}
                onChange={getfilter}
              />
              <label className="custom-control-label" htmlFor="all">
                All
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="Pending"
                id="pending"
                name="status"
                className="custom-control-input"
                ref={searchForm}
                onChange={getfilter}
              />
              <label className="custom-control-label" htmlFor="pending">
                Pending
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="Approved"
                id="approved"
                name="status"
                className="custom-control-input"
                ref={searchForm}
                onChange={getfilter}
              />
              <label className="custom-control-label" htmlFor="approved">
                Approved
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                value="Rejected"
                id="rejected"
                name="status"
                className="custom-control-input"
                ref={searchForm}
                onChange={getfilter}
              />
              <label className="custom-control-label" htmlFor="rejected">
                Rejected
              </label>
            </div>
          </div>
          <div className="right-sec">
            {/* <label className="m-2">Name</label> */}
            <input
              className="form-control form-control-sm"
              type="name"
              name="fullName"
              style={{ backgroundColor: "#fff" }}
              onChange={(data: any) => handleNameChange(data)}
              placeholder="Name"
            />
            <Button
              type="button"
              variant="contained"
              onClick={search}
              className="btn search-btn float-right ml-3 primary"
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>
        </form>
      </div>


      <div style={{ paddingBottom: "40px", width: "100%" }}>
        <div className="user-table-header heading">
          <h5 className="mb-0 heading">Requests</h5>
          <Button className='approve-all' variant='contained'>
            <Link className="lnk" to={`newrefundrequest`}>
              New Request
            </Link></Button>
        </div>
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          columns={columns}
          rows={refundList}
          getRowId={(row) => row.callId}
          pageSize={10}
          autoHeight={true}
          rowCount={RowCount}
          paginationMode="server"
          onPageChange={(newPage) => pagination(newPage)}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
        />
        <div style={{marginTop:'10px'}}>
          <p style={{fontSize:'15px'}}><strong>*</strong>  Deactivated User</p>
          <p style={{marginTop:'-15px',fontSize:'15px'}}><strong>**</strong> Deleted User</p>
          </div>
      </div>
      <Modal show={showUserProfile} onHide={closeUserProfile} size="xl">
        <Modal.Body>
          <form autoComplete="off" noValidate>
            <div className="modal-header">
              <div className="modal-title h4">Refund Request</div>
            </div>
            <div className="modal-body-bx request-mod">
              <div style={{ width: '47%' }}>
                <h5>Call Details</h5>
                <div className="row ">
                  <div className="row w50" >
                    <label className=" t-primary">Call From: </label>
                    <p className="ml-1 label-txt font-weight-bold">{callDetail.callFromFirstName}</p>
                  </div>
                  <div className="row w50">
                    <label className=" t-primary row" >Call To: </label>
                    <p className=" ml-4 label-txt font-weight-bold">{callDetail.callToFirstName}</p>
                  </div>
                </div>
                <div className="row ">
                  <div className="row w50" >
                    <label className="t-primary">Request Duration: </label>
                    <p className="ml-1  label-txt font-weight-bold">{callDetail.requestedDuration} mins</p>
                  </div>
                  <div className="row w50">
                    <label className="t-primary" >Actual Duration: </label>
                    <p className=" ml-4 label-txt font-weight-bold">{callDetail.actualDuration} mins</p>
                  </div>
                </div>

                <div className="row txt-center-row">
                  <div className="row d-flex justify-content-center" >
                    <label className="ml-1 label-txt t-primary font-weight-bold">Call Charges: </label>
                    <p className="ml-1 label-txt font-weight-bold">${parseFloat(callDetail?.callCharges ?? 0)?.toFixed(2)}</p>
                  </div>

                  <div className="row d-flex justify-content-center">
                    <label className=" row ml-4 t-primary" >Call End time:  </label>
                    <p className="ml-4 label-txt font-weight-bold">{callDetail.callEndedAt && moment(callDetail.callEndedAt).format("MM/DD/YYYY hh:mm A")}</p>
                  </div>
                </div>

                <div className="group-field">
                  <label htmlFor='reason' className="m-1 t-primary">Refund Reason</label>
                  <input
                    className="form-control form-control-sm"
                    type="name"
                    disabled
                    defaultValue={callDetail.refundReason}
                    style={{ width: '70%', marginLeft: '18px', marginBottom: '7px' }}


                  />

                </div>
                <div className="group-field">
                  <input name="callLogId" ref={register} defaultValue={callDetail.callId} hidden />
                  <label className="t-primary mr-2">Additional Details</label>
                  <textarea
                    disabled
                    ref={register}

                    style={{ width: '70%', resize: 'none', height: '120px' }}
                    name="additionalDetail"
                    className="form-control"
                    defaultValue={callDetail.additionalDetail}
                  />
                </div>



              </div>
              <div className="vl vl2"></div>
              <div className="" style={{ width: '47%' }}>
                <h5>Request Details</h5>
                <div className="row " >
                  <label className=" t-primary"> Requested By : </label>
                  <p className="ml-1 label-txt font-weight-bold">{requestDetail.requestedBy}</p>
                </div>
                <div className="  row">
                  <label className=" t-primary" >Request Date : </label>
                  <p className=" ml-1 label-txt font-weight-bold">{requestDetail.requestedDate && moment(requestDetail.requestedDate).format("MM/DD/YYYY hh:mm A")}</p>
                </div>
                <div className="  row">
                  <label className=" t-primary" >Request Status : </label>
                  <p className=" ml-1 label-txt font-weight-bold">{requestDetail.requestStatus}</p>
                </div>
                <div className="  row">
                  <label className=" t-primary" >Status Date : </label>
                  <p className=" ml-1 label-txt font-weight-bold">{requestDetail.statusDate && moment(requestDetail.statusDate).format("MM/DD/YYYY hh:mm A")}</p>
                </div>
                <div className="row txt-center-row" >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <label className="ml-1 t-primary font-weight-bold">Refund Amount: </label>
                    <p className="ml-1 label-txt font-weight-bold">${parseFloat(requestDetail?.refundAmount ?? 0)?.toFixed(2)}</p>
                  </div>
                </div>

                <div className="group-field">
                  <label className="t-primary">Comments <span className="font-weight-bold">
                    {(requestDetail.requestStatus === "Rejected" || requestDetail.requestStatus === "Approved") ?
                      ''
                      :
                      <>
                        *
                      </>}
                  </span></label>
                  <textarea
                    disabled={(requestDetail.requestStatus === "Rejected" || requestDetail.requestStatus === "Approved")}
                    style={{ width: '70%', resize: 'none', height: '120px' }}
                    name="comments"
                    className="form-control"
                    placeholder="Comments"
                    ref={register({ required: true })}
                    defaultValue={requestDetail.comments}
                  />
                  {(requestDetail.requestStatus === "Rejected" || requestDetail.requestStatus === "Approved") ?
                    ''
                    :
                    <>
                      <span className="text-danger mand-field">
                        This field is required with Asterick (
                        <span className="asterisk-bx text-danger">*</span>)
                      </span>
                    </>}
                </div>


              </div>

            </div>
            <hr />

            <div className="float-right b-btns">
              <Button variant="contained" onClick={closeUserProfile}>
                Cancel
              </Button>
              {(requestDetail.requestStatus === "Rejected" || requestDetail.requestStatus === "Approved") ?
                ''
                :
                (showReviewButton)  ? '' :
                  <>
                    <Button
                      className="ml-2 primary"
                      variant="contained"
                      onClick={handleSubmit((data) =>
                        submit(data, "Rejected")
                      )}
                    >
                      Reject
                    </Button>
                    <Button
                      className="ml-2 primary"
                      variant="contained"
                      onClick={handleSubmit((data) =>
                        submit(data, "Approved")
                      )}
                    >
                      Approve
                    </Button>
                  </>}



            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Refunds;
