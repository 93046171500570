import React from 'react';
import User from '../User';
import { PieChart, Pie, Cell, Legend } from "recharts";

const COLORS = ["#3ba7b3", "#b63cc2", "#5ec859"];
const UserDonut = (props: any) => {
  const data = [
    { name: "Deposit", value: props.userDonut.deposit },
    { name: "Spendings", value: props.userDonut.spending },
    { name: "Earning", value: props.userDonut.earning },

  ];
  return (
    <>
      <PieChart width={800} height={250}>
        <Legend align='left' verticalAlign='top' />
        <Pie
          data={data}
          cx={280}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

      </PieChart>
    </>
  )
}

export default UserDonut;