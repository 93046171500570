import http from "./HttpInterceptor";

const login = (user: any, props: any) => {
    return http.post("gateway/adminsignIn", user, {
        headers: {'task_name': 'authentication'}
    }
        );
}

const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (token == null || token == "null" || token == "") {
        return false;
    } else {
        return true;
    }
}

const logout = (props: any) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user-search-by");
    props.history.push("/login");
}

export const authService = {
    checkAuth,
    login,
    logout,
};
