import { Button, Tooltip } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
// import "./ManageUser.css";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import UserService from "../../../services/user-service/UserService";
import { useForm } from "react-hook-form";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import Utili from "../../../utility/Utility";
import { ExportToCsv } from "export-to-csv";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
import { Modal } from "react-bootstrap";

const User: any = [];

function UsersPhone(props: any) {
  const [hidecolumn, setHideColumn] = useState(true);
  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => (
        <span className="table-cell-trucate">{params.row.fullName}</span>
      ),
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
      renderCell: (params: any) => (
        <span className="table-cell-trucate">{params.row.email}</span>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      hide: hidecolumn,
      width: 240,
    },
    {
      field: "textAlert",
      headerName: "My Owwlls Text Alert",
      hide: hidecolumn,
      width: 240,
      renderCell: (params: any) => (
        <div>
          <span className="table-cell-button">
            {params.row.availabilitySMS ? "Yes" : "No"}
          </span>

          <Button
            type="button"
            onClick={() =>
              reView(users.find((e: any) => e.id == params.row.id))
            }
            variant="contained"
            className="MuiButton-textSizeSmall primary"
          >
            Change
          </Button>
        </div>
      ),
    },
  ];
  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    getValues: getValuesSearchForm,
  } = useForm();

  const [users, setUsers] = useState(User);
  const [show, setShow] = useState(false);
  const [prefrence, setPrefrence] = useState(false);
  const handleClose = () => setShow(false);
  const [searchResult, setSearchResult] = useState(false);
  const [page, setPage] = useState(0);
  const [reportData, setReportData] = useState("");
  const [RowCount, setRowCount] = useState<any>(0);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [selectedType, setSelectedType] = useState("Owwll");
  const [selectedHasPhone, setSelectedHasPhone] = useState(true);

  const handleChangeType = (event: any) => {
    setSelectedType(event.target.value);
  };

  const onChangeValue = (event: any) => {
    if (event.target.value == 0) {
      setPrefrence(false);
    }
    if (event.target.value == 1) {
      setPrefrence(true);
    }
  };

  const handleHasPhone = (event: any) => {
    setSelectedHasPhone(!selectedHasPhone);
  };
  const search = (data: any) => {
    const userSearchBy = JSON.stringify(data);
    data.pageNumber = page;
    data.pageSize = 10;
    localStorage.setItem("user-search-by", userSearchBy);
    setSearchResult(false);
    if (data.userType === "all") {
      UserService.GetUsersPhoneList(data).then((res) => {
        setRowCount(res.data.result.totalRecordCount);
        setUsers(res.data.result.searchResultData);
        setSearchResult(true);
      });
    } else if (data.userType === "Owlet") {
      UserService.GetUsersPhoneList(data).then((res) => {
        setRowCount(res.data.result.totalRecordCount);
        setUsers(res.data.result.searchResultData);
        setSearchResult(true);
      });
    } else {
      UserService.GetUsersPhoneList(data).then((res) => {
        setRowCount(res.data.result.totalRecordCount);
        setUsers(res.data.result.searchResultData);
        setSearchResult(true);
      });
    }
    setHideColumn(!data.hasPhone);
  };

  const savePrefrences = () => {
    var option = ["SMS"];
    var data = {
      userId: currentUser.id,
      messageOption: "AvailabilitySMS",
      settingOptions: option,
      active: prefrence,
      cellNo: currentUser.phone,
    };
    UserService.onAddPreference(data)
      .then((res) => {
        Utili.notifyAdded();
        setShow(false);
        let userSearch = localStorage.getItem("user-search-by");
        // @ts-ignore
        let Search = JSON.parse(userSearch);

        UserService.GetUsersPhoneList(Search).then((res) => {
          setRowCount(res.data.result.totalRecordCount);
          setUsers(res.data.result.searchResultData);
          setSearchResult(true);
        });
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const reView = (user: any) => {
    setCurrentUser(user);
    UserService.getUserPreference(user.id, "AvailabilitySMS")
      .then((res) => {
        setPrefrence(res.data.active);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pagination = (page: any) => {
    setPage(page);
    let userSearch = localStorage.getItem("user-search-by");
    // @ts-ignore
    let data = JSON.parse(userSearch);
    data.pageNumber = page;
    data.pageSize = 10;
    UserService.GetUsersPhoneList(data).then((res) => {
      setRowCount(res.data.result.totalRecordCount);
      setUsers(res.data.result.searchResultData);
      setSearchResult(true);
    });
  };
  return (
    <>
      <SpinnerLoader
        isGeneral={true}
        tasksList={["GetUserPhoneList", "AddPreference", "GetPreference"]}
        inInternal={false}
      />
      <>
        <h3 className="mb-3"> Users Phone Report</h3>
        <div className="p-3 border">
          <form
            autoComplete="off"
            className="form-inline u-mang-form"
            noValidate
            onSubmit={submitSearchForm(search)}
          >
            <div className="form-group mb-2">
              <label className="m-2">Name</label>
              <input
                className="form-control form-control-sm"
                type="name"
                name="name"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Name"
              />
              <label className="m-2">Phone</label>
              <input
                className="form-control form-control-sm"
                type="phone"
                name="phoneNumber"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Phone"
              />
             

              <div className="btn-enabled">
                <div className="custom-control custom-checkbox ">
                  <label>
                    <input
                      ref={searchForm}
                      name="hasPhone"
                      type="checkbox"
                      checked={selectedHasPhone === true}
                      onChange={handleHasPhone}
                    />
                    &nbsp; Has Phone
                  </label>
                </div>
                <div className="float-right">
                  <Button
                    type="button"
                    variant="contained"
                    onClick={submitSearchForm(search)}
                    className="btn search-btn float-right ml-3 primary"
                  >
                    <i className="fa fa-search"></i> Search
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {searchResult ? (
          <div className="mt-2">
            <h5 className="mb-0 heading">Report</h5>
            <div className="gridWrapper data-grid">
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                columns={columns}
                rows={users}
                pageSize={10}
                pagination
                rowHeight={41}
                autoHeight={true}
                rowCount={RowCount}
                paginationMode="server"
                onPageChange={(newPage) => pagination(newPage)}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
              />
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <div className="mb-0 heading">
              <h5>Report </h5>
            </div>
            <div className="report-view-bx">
              <div dangerouslySetInnerHTML={{ __html: reportData }} />
            </div>
          </div>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title className="title-my-owwll">
              {"My Owwlls Text Alert"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-bx">
              <div className="form-group">
                <label className="m-2">
                  Set text alert for
                  <span className="my-owwll-user"> {currentUser.fullName}</span>
                </label>

                <div className="p-3">
                  <form
                    autoComplete="off"
                    className="form-inline u-mang-form"
                    noValidate
                    onSubmit={submitSearchForm(search)}
                  >
                    <div className="form-group mb-2">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          value={1}
                          id="Yes"
                          ref={searchForm}
                          name="Yes"
                          className="custom-control-input"
                          checked={prefrence}
                          onChange={onChangeValue}
                        />
                        <label className="custom-control-label" htmlFor="Yes">
                          Yes
                        </label>
                      </div>

                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          value={0}
                          id="No"
                          ref={searchForm}
                          name="No"
                          className="custom-control-input"
                          checked={!prefrence}
                          onChange={onChangeValue}
                        />
                        <label className="custom-control-label" htmlFor="No">
                          No
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ background: "#bfa056", color: "#fff" }}
              variant="contained"
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
              onClick={savePrefrences}
            >
              Save
            </Button>
            <Button
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}
export default UsersPhone;
