import UserService from "../../../../services/user-service/UserService";
import SpinnerLoader from '../../../../components/spinner-loader/SpinnerLoader.component';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import CustomNoRowsOverlay from "../../../../components/NoRecord";
import moment from "moment";
import { Button } from "@material-ui/core";
import { CheckIcon, CloseIcon } from "../../../../components/Icons";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Utili from "../../../../utility/Utility";
import BackNavigate from "../../../../components/back-naviagtion/BackNavigate";
const Feedback: any = [];


function FeedBack(props: any) {
    const [feedback, setFeedback] = useState(Feedback);
    const params = useParams<any>();

    const columns: GridColDef[] = [
        { field: "id", hide: true },
        { field: "byFullName", headerName: "Reviewer", flex: 0.5, headerAlign: 'center' },
        { field: "comments", headerName: "Comments", flex: 0.5, headerAlign: 'center' },
        {
            field: "ratingDate ", headerName: "Rating Date", width: 200, headerAlign: 'center',
            valueFormatter: (params) => moment(params.row?.ratingDate).format("MM/DD/YYYY hh:mm A")
        },
        { field: "rating", headerName: "Rating", align: 'center', width: 100 },
        {
            field: "isPublic", headerName: "Public", width: 100, headerAlign: 'center', align: 'center',
            renderCell: (params: any) =>
                params.row.isPublic ? <CheckIcon /> : <CloseIcon />,
        },
        {
            field: "col4",
            headerName: " ",
            sortable: false,
            width: 100,
            headerAlign: 'center',
            renderCell: (params) => (
                <Button
                    style={{ background: "#bfa056", color: "#fff" }}
                    variant="contained"
                    className="MuiButton-textSizeSmall"
                    onClick={() => view(feedback.find((e: any) => e.id == params.row.id))}
                >
                    Review
                </Button>
            ),
        }
    ];
    const [selectedRating, setSelectedRating] = useState<any>({});
    const { register, handleSubmit, reset, errors } = useForm();

    const [ratingModal, setRatingModal] = useState(false);
    const [useFullCall, setUseFullCall] = useState(false);

    const closeRatingModal = () => setRatingModal(false);
    const openRatingModal = () => setRatingModal(true);

    useEffect(() => {
        UserService.getFeedBack(params.id).then((res) => {
            setFeedback(res.data.result);
            console.log(res.data.result);
        });
    }, [])

    const view = (data: any) => {
        setSelectedRating(data);
        if ((data.wasCallHelpFull == "No" || data.wasCallHelpFull == "Yes") && (data.ratingForId == data.receiver_ID)) {
            setUseFullCall(true);


        }

        openRatingModal();
    }

    const submit = (isPublic: any) => {
        var data = {
            id: selectedRating.id,
            isPublic: isPublic
        }
        UserService.UpdateFeedback(data).then((res) => {
            UserService.getFeedBack(params.id).then((res) => {
                setFeedback(res.data.result);
                closeRatingModal();
                Utili.notifyUpdated();
            });
        });
    }
    return (
        <div>
            <SpinnerLoader isGeneral={true} tasksList={
                ['getfeedback', 'updateFeedback']
            } inInternal={false} />
            <h3 className="mb-3">Feedback For {feedback[0]?.forFullName}
                <div className="backbutton float-right">
                    <BackNavigate />
                </div>
            </h3>

            <div className="row m-0">
                <div className="col-10 p-0">
                    <div style={{ paddingBottom: "40px", width: "120%" }}>
                        <div className="mb-0 heading">
                            <h5>User Feedbacks</h5>
                        </div>
                        <div className="data-grid">
                            <DataGrid
                                components={{
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                }}
                                columns={columns}
                                rows={feedback}
                                pageSize={10}
                                autoHeight={true}
                                disableColumnMenu={true}
                                disableSelectionOnClick={true} />
                        </div>

                    </div>
                </div>
            </div>
            <Modal centered show={ratingModal} onHide={closeRatingModal} size="xl" >
                <Modal.Header closeButton>
                    <Modal.Title>Feedback: {selectedRating.forFullName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="col-12 mt-2" autoComplete="off" noValidate>
                        <div className="row">
                            <div className="col-md-6 row">
                                <div className="col-md-4">
                                    <label className="lbl">Reviewer</label>
                                </div>
                                <div className="col-md-8">
                                    <p className="">{selectedRating.byFullName}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="lbl">Date</label>
                                </div>
                                <div className="col-md-8">
                                    <p className="">{moment(selectedRating.ratingDate).format("MM/DD/YYYY hh:mm A")}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="lbl">Public</label>
                                </div>
                                <div className="col-md-8">
                                    <p className="">{selectedRating.isPublic ? <CheckIcon /> : <CloseIcon />}</p>
                                </div>
                            </div>
                            {
                                selectedRating.forType == 'Owwll' ?
                                    <div className="col-md-6 row">

                                        <div className="col-md-5">
                                            <label className="lbl">Overall experience</label>
                                        </div>
                                        <div className="col-md-7">
                                            <p className="">{selectedRating.rating}</p>
                                        </div>

                                        <div className="col-md-5">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?
                                                    <label className="lbl">Was the Call Useful?</label>

                                                    : ''
                                            }

                                        </div>

                                        <div className="col-md-7">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?

                                                    <p className="">{selectedRating.wasCallHelpFull}</p>
                                                    : ''
                                            }

                                        </div>


                                        {/* <div className="col-md-5">
                                            <label className="lbl">Was the Call Useful?</label>
                                        </div>
                                        <div className="col-md-7">
                                            <p className="">{selectedRating.wasCallHelpFull}</p>
                                        </div> */}

                                        <div className="col-md-5">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?
                                                    <label className="lbl">Answered questions</label>

                                                    : ''
                                            }

                                        </div>
                                        <div className="col-md-7">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?
                                                    <p className="">{selectedRating.answeredRating}</p>

                                                    : ''
                                            }

                                        </div>
                                        <div className="col-md-5">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?
                                                    <label className="lbl">Would you recommend</label>

                                                    : ''
                                            }

                                        </div>
                                        <div className="col-md-7">
                                            {
                                                ((selectedRating.wasCallHelpFull == "No" || selectedRating.wasCallHelpFull == "Yes") && (selectedRating.ratingForId == selectedRating.receiver_ID)) ?
                                                    <p className="">{selectedRating.recommendedRating}</p>

                                                    : ''
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div className="col-md-6 row">
                                        <div className="col-md-5">
                                            <label className="lbl">Overall experience</label>
                                        </div>
                                        <div className="col-md-7">
                                            <p className="">{selectedRating.rating}</p>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="lbl">Comments</label>
                            </div>
                            <div className="col-md-10 p-0">
                                <p className="text-wrap">{selectedRating.comments}</p>
                            </div>
                        </div>
                        <br />
                        <div className="pull-right p-3">
                            <Button
                                className="mr-2"
                                variant="contained"
                                onClick={closeRatingModal}>
                                Cancel
                            </Button>

                            {
                                selectedRating.isPublic ?
                                    <Button
                                        variant="contained"
                                        style={{ background: "#bfa056", color: "#fff" }}
                                        onClick={handleSubmit(() => submit(false))}>
                                        Hide From Public
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        style={{ background: "#bfa056", color: "#fff" }}
                                        onClick={handleSubmit(() => submit(true))}>
                                        Make Public
                                    </Button>
                            }
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default FeedBack;