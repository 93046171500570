import React from 'react'
import Platform from "../Platform";
import DashboardService from '../../../../services/DashboardService';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";


const PlatformGraph = (props:any) => {
  return (
    <>
      <LineChart
        width={520}
        height={280}
        data={props.platformGraph}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="revenue"
          stroke="dodgerblue"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="expense" stroke="#e3712a" />
        <Line type="monotone" dataKey="profit" stroke="#5ec859" />
        
      </LineChart>
    </>
  )
}

export default PlatformGraph;