import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import SettingsService from "../../../services/settings-service/SettingsService";
import Utili from "../../../utility/Utility";
import { CloseIcon } from "../../../components/Icons";
import { Modal } from "react-bootstrap";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";
import AttributeService from "../../../services/attribute-service/AttributeService";
function ExpertsOrder() {
  function refreshPage() {
    window.location.reload();
  }
  const [formFields, setFormFields] = useState<any>([
    { categoryName: "", categoryOrder: "",isGroup: false },
  ]);
  const [GET_ALL_CATEGORIES, SET_ALL_CATEGORIES] = useState<any>([]);
  const [GET_ALL_GROUPS, SET_ALL_GROUPS] = useState<any>([]);
  const [SHOW_ALL_CATEGORIES, setSHOW_ALL_CATEGORIES] = useState(false);
  const [SHOW_ALL_GROUPS, setSHOW_ALL_GROUPSS] = useState(false);

  const CloseHobbyFrom = () => {
    setSHOW_ALL_CATEGORIES(false);
  };
  const CloseGroupsrFrom = () => {
    setSHOW_ALL_GROUPSS(false);
  };
  const {
    register: userForm,
    handleSubmit: submitUserForm,
    reset: resetUserForm,
  } = useForm();
  const [ErrorORDER, setErrorORDER] = useState<any>("");
  const [isSOURCEError, setSourceError] = useState<any>(false);

  const GetSelectedCategories = () => {
    SettingsService.getSelectedCategories().then((res: any) => {
      if (res.data.length > 0) {
        setFormFields(res.data);
      }
    });
  };

  const saveRequestAlert = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        { label: "Confirm", onClick: () => updateHomeOrder(data) },
        { label: "Cancel", onClick: () => {} },
      ],
    });
  };

  const updateHomeOrder = (data: any) => {
    setSourceError(false);
    SettingsService.UpdateCategoryOrder(data)
      .then((res) => {
        Utili.notifyUpdated();
        GetSelectedCategories();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  const handleFormChange = (event: any, index: any) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleFormChange2 = (event: any, index: any) => {};

  const submit = (e: any) => {
    e.preventDefault();
    if (formFields.length < 3) {
      Utili.notifyErrorMessage(
        "Minimum 3 categories / groups are required with a set order"
      );
      return;
    }
    if (formFields.length === 3) {
      if (
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[1].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[2].categoryOrder)
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order not in correct order");
      } else if (
        parseInt(formFields[0].categoryOrder) > 3 ||
        parseInt(formFields[0].categoryOrder) < 1 ||
        parseInt(formFields[1].categoryOrder) > 3 ||
        parseInt(formFields[1].categoryOrder) < 1 ||
        parseInt(formFields[2].categoryOrder) > 3 ||
        parseInt(formFields[2].categoryOrder) < 1
      ) {
        setSourceError(true);
        setErrorORDER("Value Cannot be greater than 3 and less than 1");
      } else if (
        formFields[0].categoryOrder === "" ||
        formFields[1].categoryOrder === "" ||
        formFields[2].categoryOrder === ""
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order shuold not be empty");
      } else {
        saveRequestAlert(formFields);
      }
    }
    if (formFields.length === 4) {
      if (
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[1].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[3].categoryOrder)
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order not in correct order");
      } else if (
        parseInt(formFields[0].categoryOrder) > 4 ||
        parseInt(formFields[0].categoryOrder) < 1 ||
        parseInt(formFields[1].categoryOrder) > 4 ||
        parseInt(formFields[1].categoryOrder) < 1 ||
        parseInt(formFields[2].categoryOrder) > 4 ||
        parseInt(formFields[2].categoryOrder) < 1 ||
        parseInt(formFields[3].categoryOrder) > 4 ||
        parseInt(formFields[3].categoryOrder) < 1
      ) {
        setSourceError(true);
        setErrorORDER("Value Cannot be greater than 4 and less than 1");
      } else if (
        formFields[0].categoryOrder === "" ||
        formFields[1].categoryOrder === "" ||
        formFields[2].categoryOrder === "" ||
        formFields[3].categoryOrder === ""
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order shuold not be empty");
      } else {
        saveRequestAlert(formFields);
      }
    }

    if (formFields.length === 5) {
      if (
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[1].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[4].categoryOrder)
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order not in correct order");
      } else if (
        parseInt(formFields[0].categoryOrder) > 5 ||
        parseInt(formFields[0].categoryOrder) < 1 ||
        parseInt(formFields[1].categoryOrder) > 5 ||
        parseInt(formFields[1].categoryOrder) < 1 ||
        parseInt(formFields[2].categoryOrder) > 5 ||
        parseInt(formFields[2].categoryOrder) < 1 ||
        parseInt(formFields[3].categoryOrder) > 5 ||
        parseInt(formFields[3].categoryOrder) < 1 ||
        parseInt(formFields[4].categoryOrder) > 5 ||
        parseInt(formFields[4].categoryOrder) < 1
      ) {
        setSourceError(true);
        setErrorORDER("Value Cannot be greater than 5 and less than 1");
      } else if (
        formFields[0].categoryOrder === "" ||
        formFields[1].categoryOrder === "" ||
        formFields[2].categoryOrder === "" ||
        formFields[3].categoryOrder === "" ||
        formFields[4].categoryOrder === ""
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order shuold not be empty");
      } else {
        saveRequestAlert(formFields);
      }
    }

    if (formFields.length === 6) {
      if (
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[1].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[4].categoryOrder) ===
          parseInt(formFields[5].categoryOrder)
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order not in correct order");
      } else if (
        parseInt(formFields[0].categoryOrder) > 6 ||
        parseInt(formFields[0].categoryOrder) < 1 ||
        parseInt(formFields[1].categoryOrder) > 6 ||
        parseInt(formFields[1].categoryOrder) < 1 ||
        parseInt(formFields[2].categoryOrder) > 6 ||
        parseInt(formFields[2].categoryOrder) < 1 ||
        parseInt(formFields[3].categoryOrder) > 6 ||
        parseInt(formFields[3].categoryOrder) < 1 ||
        parseInt(formFields[4].categoryOrder) > 6 ||
        parseInt(formFields[4].categoryOrder) < 1 ||
        parseInt(formFields[5].categoryOrder) > 6 ||
        parseInt(formFields[5].categoryOrder) < 1
      ) {
        setSourceError(true);
        setErrorORDER("Value Cannot be greater than 6 and less than 1");
      } else if (
        formFields[0].categoryOrder === "" ||
        formFields[1].categoryOrder === "" ||
        formFields[2].categoryOrder === "" ||
        formFields[3].categoryOrder === "" ||
        formFields[4].categoryOrder === "" ||
        formFields[5].categoryOrder === ""
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order shuold not be empty");
      } else {
        saveRequestAlert(formFields);
      }
    }

    if (formFields.length === 7) {
      if (
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[1].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[0].categoryOrder) ===
          parseInt(formFields[6].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[2].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[1].categoryOrder) ===
          parseInt(formFields[6].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[3].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[2].categoryOrder) ===
          parseInt(formFields[6].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[4].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[3].categoryOrder) ===
          parseInt(formFields[6].categoryOrder) ||
        parseInt(formFields[4].categoryOrder) ===
          parseInt(formFields[5].categoryOrder) ||
        parseInt(formFields[4].categoryOrder) ===
          parseInt(formFields[6].categoryOrder) ||
        parseInt(formFields[5].categoryOrder) ===
          parseInt(formFields[6].categoryOrder)
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order not in correct order");
      } else if (
        parseInt(formFields[0].categoryOrder) > 7 ||
        parseInt(formFields[0].categoryOrder) < 1 ||
        parseInt(formFields[1].categoryOrder) > 7 ||
        parseInt(formFields[1].categoryOrder) < 1 ||
        parseInt(formFields[2].categoryOrder) > 7 ||
        parseInt(formFields[2].categoryOrder) < 1 ||
        parseInt(formFields[3].categoryOrder) > 7 ||
        parseInt(formFields[3].categoryOrder) < 1 ||
        parseInt(formFields[4].categoryOrder) > 7 ||
        parseInt(formFields[4].categoryOrder) < 1 ||
        parseInt(formFields[5].categoryOrder) > 7 ||
        parseInt(formFields[5].categoryOrder) < 1 ||
        parseInt(formFields[6].categoryOrder) > 7 ||
        parseInt(formFields[6].categoryOrder) < 1
      ) {
        setSourceError(true);
        setErrorORDER("Value Cannot be greater than 7 and less than 1");
      } else if (
        formFields[0].categoryOrder === "" ||
        formFields[1].categoryOrder === "" ||
        formFields[2].categoryOrder === "" ||
        formFields[3].categoryOrder === "" ||
        formFields[4].categoryOrder === "" ||
        formFields[5].categoryOrder === "" ||
        formFields[6].categoryOrder === ""
      ) {
        setSourceError(true);
        setErrorORDER("Experts Categories / Groups Order shuold not be empty");
      } else {
        saveRequestAlert(formFields);
      }
    }
  };

  const deleteCategory = (index: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to remove this category?",
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            let data = [...formFields];
            data.splice(index, 1);
            setFormFields(data);
          },
        },
        { label: "Cancel", onClick: () => {} },
      ],
    });
  };
  const OpenCategoryForm = () => {
    if (formFields.length >= 7) {
      Utili.notifyErrorMessage(
        "Maximum 7 categories / groups are allowed with a set order"
      );
      return;
    } else {
      setSHOW_ALL_CATEGORIES(true);
    }
  };

  const OpenGroupsForm = () => {
    if (formFields.length >= 7) {
      Utili.notifyErrorMessage(
        "Maximum 7 categories / groups are allowed with a set order"
      );
      return;
    } else {
      setSHOW_ALL_GROUPSS(true);
    }
  };

  const saveChanges = () => {
    
  };
  const getAllCategories = () => {
    SettingsService.getAllEnabledCategories().then((res) => {
      addFixedCategories(res.data.result);
    });
  };

  const addFixedCategories = (cat: any) => {
    cat.unshift("-------------------");
    cat.unshift("Recently Active");
    cat.unshift("My Owwlls");
    cat.unshift("Featured Owwlls");
    cat.unshift("New Owwlls");
    cat.unshift("Active Owwlls");
    SET_ALL_CATEGORIES(cat);
  };

  const loadAllHobbies = () => {
    AttributeService.getAllHobbies().then((res) => {
      var getEnabledList = res.data.result.filter((category: any) => category.isActive === true);
      SET_ALL_GROUPS(getEnabledList);
     // setOrigionalHobbies(res.data.result);
    });
  };

  const onSaveCCategory = (data: any) => {
    if (Object.values(data).toString() || Object.values(data).toString().length > 0) {
      const is_AlreadyExists = formFields.filter(
        (category: any) =>
          category.categoryName === Object.values(data).toString()
      );

      if (is_AlreadyExists.length > 0) {
        Utili.notifyErrorMessage(
          Object.values(data).toString() + " already exists"
        );
      } else {
        let object = {
          categoryName: Object.values(data).toString(),
          categoryOrder: "",
          isGroup: false
        };

        setFormFields([...formFields, object]);
        CloseHobbyFrom();
      }
    } else {
      Utili.notifyErrorMessage("Please select a category");
    }
  };

  const onSaveGroups = (data: any) => {
    if (Object.values(data).toString() || Object.values(data).toString().length > 0) {
      const is_AlreadyExists = formFields.filter(
        (category: any) =>
          category.categoryName === Object.values(data).toString()
      );
      if (is_AlreadyExists.length > 0) {
        Utili.notifyErrorMessage(
          Object.values(data).toString() + " already exists"
        );
      } else {
        let object = {
          categoryName: Object.values(data).toString(),
          categoryOrder: "",
          isGroup: true
        };
        setFormFields([...formFields, object]);
        CloseGroupsrFrom();
      }
    } else {
      Utili.notifyErrorMessage("Please select a group");
    }
  };

  const onChange = (value: any) => {};

  useEffect(() => {
    loadAllHobbies();
    GetSelectedCategories();
    getAllCategories();
  }, []);
  return (
    <>
      <SpinnerLoader
        isGeneral={true}
        tasksList={[
          "SelectEnabledCategoriesForOrdering",
          "GetAllEnabledCategories",
          "GetSelectedCategories",
          "UpdateCategoryOrder",
        ]}
        inInternal={false}
      />
      <div className=" p-0" style={{ width: "80%" }}>
        <h3 className="mb-3">Home Categories Order</h3>
        <h4 className="mb-0 heading">
        Home Categories Order
          <Button
            variant="contained"
            className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right pearl-black-btn"
            onClick={OpenCategoryForm}
          >
            Add Category
          </Button>

          <Button
            variant="contained"
            className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right pearl-black-btn margin10"
            onClick={OpenGroupsForm}
          >
            Add Group
          </Button>


        </h4>
        <div className="p-2 border config-rows mb-4">
          <form onSubmit={submit}>
            {formFields.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <div className="row mb-0">
                    <div className="col-6">
                      <label className="mt-2">{item.categoryName}</label>
                      <label className="mt-2 margin10Left">{item.isGroup ? "(Group)": ""}</label>
                    </div>
                    <div className="col-2">
                      <input
                        hidden
                        type="text"
                        name={`categoryName`}
                        value={item.categoryName}
                        onChange={(event) => handleFormChange2(event, index)}
                      />
                      <input
                        className="form-control form-control-sm "
                        value={item.categoryOrder}
                        type="number"
                        name={`categoryOrder`}
                        onChange={(event) => handleFormChange(event, index)}
                        style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                      />
                    </div>
                    <div className="col-3">
                      <CloseIcon
                        data-toggle="modal"
                        data-target="#editModal"
                        className="cross-white"
                        onClick={() => deleteCategory(index)}
                        fontSize="small"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {isSOURCEError ? (
              <p style={{ color: "red", marginLeft: "50%" }}>{ErrorORDER}</p>
            ) : (
              ""
            )}
            <div className=" form-group  pb-3">
              <Button
                type="submit"
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
                onClick={saveChanges}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Modal show={SHOW_ALL_CATEGORIES} onHide={CloseHobbyFrom}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            onSubmit={submitUserForm(onSaveCCategory)}
            className="mt-3 ml-2"
            noValidate
          >
            <div className="row pb-1 add-category">
              <div className="col-3">
                <label className="lbl">Category</label>
              </div>
              <div className="col-8">
                <select
                  name="category"
                  style={{ width: 300, outline: 0 }}
                  ref={userForm}
                  defaultValue=""
                  onChange={(e) => onChange(e.target.value)}
                >
                  <option disabled={true} value="">
                    Choose a Category
                  </option>
                  {GET_ALL_CATEGORIES.map((e: any, key: any) => {
                    return (
                      <option
                        key={key}
                        value={e.value}
                        disabled={e === "-------------------" ? true : false}
                      >
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-3"></div>
              <div className="col-8">
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                  style={{ background: "#bfa056", color: "#fff" }}
                >
                  Save
                </Button>
                <Button
                  onClick={CloseHobbyFrom}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={SHOW_ALL_GROUPS} onHide={CloseGroupsrFrom}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            autoComplete="off"
            onSubmit={submitUserForm(onSaveGroups)}
            className="mt-3 ml-2"
            noValidate
          >
            <div className="row pb-1 add-category">
              <div className="col-3">
                <label className="lbl">Group</label>
              </div>
              <div className="col-8">
                <select
                  name="group"
                  style={{ width: 300, outline: 0 }}
                  ref={userForm}
                  defaultValue=""
                  onChange={(e) => onChange(e.target.value)}
                >
                  <option disabled={true} value="">
                    Choose a Group
                  </option>
                  {GET_ALL_GROUPS.map((e: any, key: any) => {
                    return (
                      <option
                        key={key}
                        value={e.name}
                       
                      >
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-3"></div>
              <div className="col-8">
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                  style={{ background: "#bfa056", color: "#fff" }}
                >
                  Save
                </Button>
                <Button
                  onClick={CloseGroupsrFrom}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ExpertsOrder;
