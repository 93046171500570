import React, { useEffect, useState } from "react";
import "./Categories.css";
import "../../../services/attribute-service/AttributeService";
import { Button } from "@material-ui/core";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridOverlay,
} from "@material-ui/data-grid";
import { Alert, Modal } from "react-bootstrap";
import AttributeService from "../../../services/attribute-service/AttributeService";
import { spinnerService } from "../../../services/SpinnerService";
import { IconButton, makeStyles, withWidth } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import CustomNoRowsOverlay from "../../../components/NoRecord";

export interface Attributes {
  id: string;
  Name: string;
  IsActive: boolean;
}
function Categories(props: any) {
  const [show, setShow] = useState(false);
  const [origionalCategories, setOrigionalCategories] = useState(
    Array<Attributes>()
  );
  const [categoriesSource, setCategoriesSource] = useState(Array<Attributes>());
  const [addingCategory, setAddingCategory] = useState(true);
  const [categorId, setCategorId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [CategoryValue, setCategoryValue] = useState("");
  const [searchName, setSearchName] = useState("");

  const [existMessage, setExistMessage] = useState(false);

  const handleClose = () => {
    setExistMessage(false);
    ClearValues();
    setShow(false);
  };
  const handleShow = () => {
    setAddingCategory(true);
    setShow(true);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    loadAllCategories();
  }, []);
  const loadAllCategories = async () => {
    // spinnerService.showLoading(true);
    AttributeService.getAllCategories().then((res) => {
      setCategoriesSource(res.data.result);
      setOrigionalCategories(res.data.result);
    });
    // spinnerService.showLoading(false);
  };
  const rows: GridRowsProp = categoriesSource;

  const columns: GridColDef[] = [
    { field: "name", headerName: "Expertise", width: 350 },
    {
      field: "isActive",
      headerName: "Enabled",
      width: 150,
      renderCell: (params: any) =>
        params.row.isActive ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: "col6",
      headerName: " ",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <div>
          <strong>
            <IconButton
              data-toggle="modal"
              data-target="#editModal"
              onClick={() =>
                edit(categoriesSource.find((e: any) => e.id == params.row.id))
              }
              aria-label="edit"
            >
              <EditIcon className="edit" fontSize="small" />
            </IconButton>{" "}
          </strong>
        </div>
      ),
    },
  ];
  const edit = (category: any) => {
    setAddingCategory(false);
    setCategorId(category.id);
    setCategoryValue(category.name);
    setIsActive(category.isActive);
    setShow(true);
  };
  const onSave = () => {
    setExistMessage(false);
    var data = {
      id: categorId,
      name: CategoryValue.trim(),
      isActive: isActive,
    };

    AttributeService.SaveCategory(data).then((res) => {
      if (res.data.result == null) {
        setExistMessage(true);
        return;
      } else {
        loadAllCategories();
        setTimeout(() => {
          ClearValues();
          searchHandler();
          setShow(false);
        }, 200);
        setTimeout(() => {
          ClearValues();
        }, 2000);
      }
      setExistMessage(false);
    });
  };

  function ClearValues() {
    setCategorId("");
    setCategoryValue("");
    setIsActive(false);
  }
  function handleInputChange(event: any) {
    setCategoryValue(event.target.value);
  }
  function keyPress(e: any) {
    if (e.keyCode == 13) {
      e.preventDefault();
      searchHandler();
    }
  }
  function handleSearchInputChange(event: any) {
    setSearchName(event.target.value);
  }
  function handleCheckboxClick(event: any) {
    setIsActive((prevValue) => !prevValue); // invert value
  }
  const searchHandler = () => {
    let searcQuery = searchName.toLowerCase(),
      displayedContacts = origionalCategories.filter((el: any) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searcQuery) !== -1;
      });
    setCategoriesSource(displayedContacts);
  };
  return (
    <div className="p-3 mt-2 col-10">
      <div className="col-12">
        <h3 className="mb-3">Manage Expertise</h3>
      </div>

      <div className="col-12">
        <h5 className="mb-0 heading">Search</h5>
        <div className="p-3 border" style={{ backgroundColor: "#E1E2E1" }}>
          <form autoComplete="off" className="form-inline">
            <div className="form-group mb-2">
              <label className="m-2">Expertise</label>
              <input
                className="form-control form-control-sm sr-input"
                value={searchName}
                onChange={handleSearchInputChange}
                style={{ backgroundColor: "#fff" }}
                placeholder="Search Expertise"
                onKeyDown={keyPress}
              />
              <div className="float-right">
                <button
                  type="button"
                  onClick={searchHandler}
                  className="btn search-btn float-right ml-5"
                >
                  <i className="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-12 mt-4">
        {/* <Header {...props}></Header> */}

        <div
          style={{
            height: 730,
            paddingBottom: "80px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <div className="mb-0 heading">
            <h5>
              Expertise
              <button
                color="secondary"
                onClick={handleShow}
                className="btn btn-sm btn-outline-secondary float-right"
              >
                Add Expertise
              </button>
            </h5>
          </div>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              {addingCategory ? "Add Expertise" : "Edit Expertise"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-bx">
              <div className="form-group">
                <label className="">Expertise</label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="category"
                  value={CategoryValue}
                  ref={register({ required: true })}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Expertise"
                  onChange={handleInputChange}
                  required
                />
                {errors.category && (
                  <span style={{ color: "#ff0000" }}>
                    Expertise is required
                  </span>
                )}
              </div>

              <div className="custom-checkbox">
                <label>
                  <input
                    name="isActive"
                    checked={isActive}
                    ref={register}
                    type="checkbox"
                    onChange={handleCheckboxClick}
                  />
                  <span className=""> Enabled</span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {existMessage && (
              <span style={{ color: "#ff0000" }}>Expertise already exist.</span>
            )}
            <Button
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ background: "#bfa056", color: "#fff" }}
              onClick={handleSubmit(onSave)}
              variant="contained"
              className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Categories;
