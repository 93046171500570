import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import SettingsService from "../../../services/settings-service/SettingsService";
import Utili from "../../../utility/Utility";
let array: any[] = [];
function AppConfiguration() {
  function refreshPage() {
    window.location.reload();
  }

  const [Gatedsignup, setGatedsignup] = useState<any>({});
  const [isSubscription, setIsSubscription] = useState<any>();
  const [Subscription, setSubscription] = useState<any>({});
  const [isGatedsignup, setIsGatedsignup] = useState<any>();
  const [OneTimeSignupBonus, setOneTimeSignupBonus] = useState<any>({});
  const [isOneTimeSignupBonus, setISOneTimeSignupBonus] = useState<any>();
  const [SignupReferralBonusAllowed, setSignupReferralBonusAllowed] =
    useState<any>({});
  const [isReferralBonus, setReferralBonus] = useState<any>();
  const [minWithdrawalLimit, setminWithdrawalLimit] = useState<any>();
  const [maxWithdrawalLimit, setmaxWithdrawalLimit] = useState<any>();
  const [minimumCallLimit, setMinimumCallLimit] = useState<any>();
  const [minDepositLimit, setminDepositLimit] = useState<any>();
  const [maxDepositLimit, setmaxDepositLimit] = useState<any>();
  const [errorWithdrawalLimit, seterrorWithdrawalLimit] = useState(false);
  const [errorOnetimeBonus, seterrorOnetimeBonus] = useState(false);
  const [errorServiceFee, seterrorServiceFee] = useState(false);
  const [errorReferralBonus, seterrorReferralBonus] = useState(false);
  const [
    errorfirstDepositbonusEligibilitydays,
    seterrorfirstDepositbonusEligibilitydays,
  ] = useState(false);
  const [errorfirstDepositbonusAmount, seterrorfirstDepositbonusAmount] =
    useState(false);

  const [errorDepositLimit, seterrorDepositLimit] = useState(false);
  const [errorPingsPerExpert, seterrorPingsPerExpert] = useState(false);
  const [errorPingsPerDay, seterrorPingsPerDay] = useState(false);
  const [errorMinimumCallsLimit, seterrorMinimumCallsLimit] = useState(false);
  const [errorSubscriptionBonus, setErrorSubscriptionBonus] = useState(false);
  const [errorSubscriptionFee, setErrorSubscriptionFee] = useState(false);
  const [startTime, setstartTime] = useState<any>("00:00");
  const [endTime, setendTime] = useState<any>("00:00");

  const {
    register: regGatedSignUp,
    reset: resetGatedSignup,
    handleSubmit: submitGatedSignUp,
  } = useForm();
  const {
    register: regSubscription,
    reset: resetSubscription,
    handleSubmit: submitSubscription,
  } = useForm();
  const {
    register: regPingsPerExpert,
    reset: resetPingsPerExpert,
    handleSubmit: submitPingsPerExpert,
  } = useForm();
  const {
    register: regPingsPerDay,
    reset: resetPingsPerDay,
    handleSubmit: submitPingsPerDay,
  } = useForm();
  const {
    register: regMinCallLimit,
    reset: resetMinCallLimit,
    handleSubmit: submitMinCallLimit,
  } = useForm();
  const {
    register: regTime,
    reset: resetTime,
    handleSubmit: submitTime,
  } = useForm();
  const {
    register: regOneTimeSignupBonus,
    reset: resetOneTime,
    handleSubmit: submitOneTimeSignupBonus,
  } = useForm();
  const {
    register: regOneTimeSignupBonusAmount,
    reset: resetOneTimeAmount,
    handleSubmit: submitOneTimeSignupBonusAmount,
  } = useForm();

  const {
    register: regSubscriptionBonus,
    reset: resetSubscriptionBonus,
    handleSubmit: submitSubscriptionBonusAmount,
  } = useForm();

  const {
    register: regSubscriptionFee,
    reset: resetSubscriptionFee,
    handleSubmit: submitSubscriptionFee,
  } = useForm();

  const {
    register: registerAppConfig,
    reset: resetServiceFee,
    handleSubmit: submitServiceFeeConfig,
  } = useForm();

  const {
    register: registerReferralBonusAppConfig,
    reset: resetReferralAmount,
    handleSubmit: submitAppConfig,
  } = useForm();
  const {
    register: registerFirstdepositDaysAppConfig,
    reset: resetFirstdepositDays,
    handleSubmit: submitFirstDepositDaysConfig,
  } = useForm();
  const {
    register: registerFirstdepositAmountAppConfig,
    reset: resetFirstdepositAmount,
    handleSubmit: submitFirstDepositAmountConfig,
  } = useForm();
  const { register: regWithdrawalLimit, handleSubmit: submitWithdrawalLimit } =
    useForm();
  const { register: regDepositLimit, handleSubmit: submitDepositLimit } =
    useForm();

  const SubmitValues = (data: any) => {
    if (data.value === true) {
      data.value = "true";
    } else {
      data.value = "false";
    }
    SettingsService.UpdateGatedSignupConfig(data)
      .then((res) => {
        GetAppConfigs();
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  const SubmitSubscription = (data: any) => {
    if (data.value === true) {
      data.value = "true";
    } else {
      data.value = "false";
    }
    data.key = "SUBSCRIPTION_ENABLE";
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        {
          label: "Confirm",
          onClick: () => updateAppConfiguration(data),
        },
        {
          label: "Cancel",
          onClick: () => GetAppConfigs(),
        },
      ],
    });
  };

  const UpdateSignupBonusAllowedConfig = (data: any) => {
    SettingsService.UpdateSignupBonusAllowedConfig(data.value)
      .then((res) => {
        GetAppConfigs();
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  const GetAppConfigs = () => {
    SettingsService.GetAppConfigs().then((res: any) => {
      console.log(res.data.result);
      res.data.result.forEach(function (value: any) {
        if (value.key === "GATED_SIGNUP") {
          setIsGatedsignup(value.value === "true");
          setGatedsignup(value);
        } else if (value.key === "SUBSCRIPTION_ENABLE") {
          setIsSubscription(value.value === "true");
          setSubscription(value);
        } else if (value.key === "SUBSCRIPTION_BONUS_AMOUNT") {
          resetSubscriptionBonus({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "SUBSCRIPTION_AMOUNT") {
          resetSubscriptionFee({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "REFERRAL_BONUS_AMOUNT") {
          resetReferralAmount({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "PLATFORM_SERVICE_FEE_PERCENT") {
          resetServiceFee({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "SIGNUP_ONETIME_BONUS_ALLOWED") {
          setISOneTimeSignupBonus(value.value === "true");
          setOneTimeSignupBonus(value);
        } else if (value.key === "SIGNUP_REFERRAL_BONUS_ALLOWED") {
          setReferralBonus(value.value === "true");
          setSignupReferralBonusAllowed(value);
        } else if (value.key === "SIGNUP_ONETIME_BONUS_AMOUNT") {
          resetOneTimeAmount({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "MINIMUM_WITHDRAWAL_LIMIT") {
          setminWithdrawalLimit(value.value);
        } else if (value.key === "MAXIMUM_WITHDRAWAL_LIMIT") {
          setmaxWithdrawalLimit(value.value);
        } else if (value.key === "MINIMUM_DEPOSIT_LIMIT") {
          setminDepositLimit(value.value);
        } else if (value.key === "MAXIMUM_DEPOSIT_LIMIT") {
          setmaxDepositLimit(value.value);
        } else if (value.key === "NUMBER_OF_MINIMUM_CALL_LIMIT") {
          resetMinCallLimit({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "START_TIME_NOTIFICATION_EXPERT") {
          setstartTime(value.value);
        } else if (value.key === "END_TIME_NOTIFICATION_EXPERT") {
          setendTime(value.value);
        } else if (
          value.key === "ALLOW_ADVICE_SEEKER_NUMBER_OF_PINGS_PER_EXPERT"
        ) {
          resetPingsPerExpert({
            value: value.value,
            key: value.key,
          });
        } else if (
          value.key === "ALLOW_ADVICE_SEEKER_NUMBER_OF_PINGS_PER_DAY"
        ) {
          resetPingsPerDay({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "DEPOSIT_BONUS_ELIGIBILTY_DAYS") {
          resetFirstdepositDays({
            value: value.value,
            key: value.key,
          });
        } else if (value.key === "DEPOSIT_BONUS_AMOUNT") {
          resetFirstdepositAmount({
            value: value.value,
            key: value.key,
          });
        }
      });
    });
  };

  const onsubmitGatedSignUp = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        { label: "Confirm", onClick: () => SubmitValues(data) },
        { label: "Cancel", onClick: () => refreshPage() },
      ],
    });
  };

  const onsubmitGatedSignUpBonus = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        {
          label: "Confirm",
          onClick: () => UpdateSignupBonusAllowedConfig(data),
        },
        { label: "Cancel", onClick: () => refreshPage() },
      ],
    });
  };
  const UpdateSignupBonusAmountConfig = (data: any) => {
    SettingsService.UpdateSignupBonusAmountConfig(data.value)
      .then((res) => {
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const onsubmitGatedSignUpBonusAmount = (data: any) => {
    if (data.value === "" || parseInt(data.value) < 1) {
      seterrorOnetimeBonus(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          {
            label: "Confirm",
            onClick: () => UpdateSignupBonusAmountConfig(data),
          },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorOnetimeBonus(false);
    }
  };

  const onsubmitDepositLimit = (data: any) => {
    if (
      data.max === "" ||
      parseInt(data.max) < 1 ||
      data.min === "" ||
      parseInt(data.min) < 1
    ) {
      seterrorDepositLimit(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          { label: "Confirm", onClick: () => UpdateDepositLimit(data) },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorDepositLimit(false);
    }
  };
  const UpdateDepositLimit = (data: any) => {
    SettingsService.UpdateDepositLimitConfig(data)
      .then((res) => {
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };
  const onsubmittWithdrawalLimit = (data: any) => {
    if (
      data.max === "" ||
      parseInt(data.max) < 1 ||
      data.min === "" ||
      parseInt(data.min) < 1
    ) {
      seterrorWithdrawalLimit(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          { label: "Confirm", onClick: () => UpdateWithdrawalLimit(data) },
          { label: "Cancel", onClick: () => refreshPage() },
        ],
      });
      seterrorWithdrawalLimit(false);
    }
  };
  //
  const onsubmitTimeSlot = (data: any) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to Save the Changes.",
      buttons: [
        { label: "Confirm", onClick: () => UpdateExpertNotificationTime(data) },
        { label: "Cancel", onClick: () => refreshPage() },
      ],
    });
  };

  const UpdateExpertNotificationTime = (data: any) => {
    SettingsService.updateExpertNotificationTime(startTime, endTime)
      .then((res) => {
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  //
  const UpdateWithdrawalLimit = (data: any) => {
    SettingsService.UpdateWithdrawalLimitConfig(data)
      .then((res) => {
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  const submit = (data: any) => {
    if (
      (data.value === "" || parseInt(data.value) < 1) &&
      data.key === "PLATFORM_SERVICE_FEE_PERCENT"
    ) {
      seterrorServiceFee(true);
    } else if (
      (data.value === "" || parseInt(data.value) < 1) &&
      data.key === "REFERRAL_BONUS_AMOUNT"
    ) {
      seterrorReferralBonus(true);
    } else if (
      (data.value === "" || parseInt(data.value) < 2) &&
      data.key === "DEPOSIT_BONUS_ELIGIBILTY_DAYS"
    ) {
      seterrorfirstDepositbonusEligibilitydays(true);
    } else if (
      (data.value === "" || parseInt(data.value) < 1) &&
      data.key === "DEPOSIT_BONUS_AMOUNT"
    ) {
      seterrorfirstDepositbonusAmount(true);
    } else if (
      (data.value === "" || parseInt(data.value) < 1) &&
      data.key === "SUBSCRIPTION_AMOUNT"
    ) {
      setErrorSubscriptionFee(true);
    } else if (
      (data.value === "" || parseInt(data.value) < 1) &&
      data.key === "SUBSCRIPTION_BONUS_AMOUNT"
    ) {
      setErrorSubscriptionBonus(true);
    } else if (
      data.value === "" ||
      (parseInt(data.value) < 0 && data.key === "NUMBER_OF_MINIMUM_CALL_LIMIT")
    ) {
      seterrorMinimumCallsLimit(true);
    } else if (
      data.value === "" ||
      (parseInt(data.value) < 1 &&
        data.key === "ALLOW_ADVICE_SEEKER_NUMBER_OF_PINGS_PER_EXPERT")
    ) {
      seterrorPingsPerExpert(true);
    } else if (
      data.value === "" ||
      (parseInt(data.value) < 1 &&
        data.key === "ALLOW_ADVICE_SEEKER_NUMBER_OF_PINGS_PER_DAY")
    ) {
      seterrorPingsPerDay(true);
    } else {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure to Save the Changes.",
        buttons: [
          {
            label: "Confirm",
            onClick: () => updateAppConfiguration(data),
          },
          {
            label: "Cancel",
            onClick: () => GetAppConfigs(),
          },
        ],
      });
      seterrorReferralBonus(false);
      seterrorServiceFee(false);
      seterrorPingsPerExpert(false);
      seterrorPingsPerDay(false);
      seterrorMinimumCallsLimit(false);
      setErrorSubscriptionFee(false);
      setErrorSubscriptionBonus(false);
      seterrorfirstDepositbonusEligibilitydays(false);
      seterrorfirstDepositbonusAmount(false);
    }
  };

  const updateAppConfiguration = (data: any) => {
    var newDt = new Date();
    data.effectiveStart = moment(newDt).format("YYYY-MM-DD");
    var dataArray = [data];
    SettingsService.UpdateAppConfiguration(dataArray)
      .then((res) => {
        GetAppConfigs();
        Utili.notifyUpdated();
      })
      .catch((err) => {
        Utili.notifyError();
      });
  };

  useEffect(() => {
    GetAppConfigs();
  }, []);
  return (
    <>
      <div className=" p-0" style={{ width: "90%" }}>
        <h3 className="mb-3">App Configuration</h3>
        <h4 className="mb-0 heading"> Configuration </h4>
        <div className="p-2 border config-rows mb-4">
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">{Gatedsignup.label}</label>
            </div>
            <div style={{ width: "15.6%" }}>
              <input
                className="form-control form-control-sm "
                type="checkbox"
                name="value"
                defaultChecked={isGatedsignup}
                style={{
                  backgroundColor: "#fff",
                  paddingLeft: "7%",
                  width: "27px",
                }}
                ref={regGatedSignUp}
              />
            </div>
            <div className="col-2">
              {isGatedsignup ? (
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  Enabled
                </label>
              ) : (
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  Disabled
                </label>
              )}
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitGatedSignUp(onsubmitGatedSignUp)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">{OneTimeSignupBonus.label}</label>
            </div>
            <div style={{ width: "15.6%" }}>
              <input
                className="form-control form-control-sm "
                type="checkbox"
                name="value"
                defaultChecked={isOneTimeSignupBonus}
                style={{
                  backgroundColor: "#fff",
                  paddingLeft: "7%",
                  width: "27px",
                }}
                ref={regOneTimeSignupBonus}
              />
            </div>
            <div className="col-2">
              {isOneTimeSignupBonus ? (
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  Enabled
                </label>
              ) : (
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  Disabled
                </label>
              )}
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitOneTimeSignupBonus(onsubmitGatedSignUpBonus)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          <hr />

          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">One Time Signup Bonus Amount</label>
            </div>
            <div style={{ width: "15.6%" }}>
              <input
                hidden
                type="text"
                name="key"
                ref={regOneTimeSignupBonusAmount}
              />
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={regOneTimeSignupBonusAmount}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                $
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitOneTimeSignupBonusAmount(
                  onsubmitGatedSignUpBonusAmount
                )}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
            {errorOnetimeBonus ? (
              <p style={{ color: "red", marginLeft: "30%" }}>
                One time sign up bonus cannot be a negative number or empty.
              </p>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Referral Bonus Amount</label>
            </div>
            <input
              hidden
              type="text"
              name="key"
              ref={registerReferralBonusAppConfig}
            />
            <div style={{ width: "15.6%" }}>
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={registerReferralBonusAppConfig}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                $
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitAppConfig(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorReferralBonus ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Referral Bonus Amount cannot be a negative number or empty.
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">
                First Deposit Bonus Eligibility Days
              </label>
            </div>
            <input
              hidden
              type="text"
              name="key"
              ref={registerFirstdepositDaysAppConfig}
            />
            <div style={{ width: "15.6%" }}>
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={registerFirstdepositDaysAppConfig}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}></label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitFirstDepositDaysConfig(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorfirstDepositbonusEligibilitydays ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              First Deposit Bonus Eligibility Days cannot be less than 2.
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">First Deposit Bonus Up To</label>
            </div>
            <input
              hidden
              type="text"
              name="key"
              ref={registerFirstdepositAmountAppConfig}
            />
            <div style={{ width: "15.6%" }}>
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={registerFirstdepositAmountAppConfig}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                $
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitFirstDepositAmountConfig(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorfirstDepositbonusAmount ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              First Deposit Bonus Up To cannot be a negative number or empty.
            </p>
          ) : (
            ""
          )}
          <hr />

          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Service Fee - Commission</label>
            </div>
            <div style={{ width: "15.6%" }}>
              <input hidden type="text" name="key" ref={registerAppConfig} />
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={registerAppConfig}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                percent
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitServiceFeeConfig(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorServiceFee ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Platform Service Fee cannot be a negative number or empty.
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Withdrawal Limits</label>
            </div>

            <div style={{ width: "12%", display: "flex" }}>
              <label className="m-2">Min</label>

              <input
                className="form-control form-control-sm "
                defaultValue={minWithdrawalLimit}
                type="number"
                name="min"
                min={0}
                style={{ backgroundColor: "#fff" }}
                ref={regWithdrawalLimit}
              />
            </div>

            <div style={{ width: "12%", display: "flex" }}>
              <label className="m-2">Max</label>

              <input
                className="form-control form-control-sm "
                defaultValue={maxWithdrawalLimit}
                type="number"
                name="max"
                min={0}
                style={{ backgroundColor: "#fff" }}
                ref={regWithdrawalLimit}
              />
            </div>
            <div className="col-1">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                $
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitWithdrawalLimit(onsubmittWithdrawalLimit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorWithdrawalLimit ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Withdrawal limit cannot be zero or negative number
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">Deposit Limits</label>
            </div>

            <div style={{ width: "12%", display: "flex" }}>
              <label className="m-2">Min</label>

              <input
                className="form-control form-control-sm "
                defaultValue={minDepositLimit}
                type="number"
                name="min"
                min={0}
                style={{ backgroundColor: "#fff" }}
                ref={regDepositLimit}
              />
            </div>

            <div style={{ width: "12%", display: "flex" }}>
              <label className="m-2">Max</label>

              <input
                className="form-control form-control-sm "
                defaultValue={maxDepositLimit}
                type="number"
                name="max"
                min={0}
                style={{ backgroundColor: "#fff" }}
                ref={regDepositLimit}
              />
            </div>
            <div className="col-1">
              <label className="m-2 small" style={{ color: "#b59852" }}>
                $
              </label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitDepositLimit(onsubmitDepositLimit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
          {errorDepositLimit ? (
            <p style={{ color: "red", marginLeft: "30%" }}>
              Deposit limit cannot be zero or negative number
            </p>
          ) : (
            ""
          )}
          <hr />
          <div className="row mb-0">
            <div className="ml-3" style={{ width: "30%" }}>
              <label className="mt-2">
                Minimum Calls Limit to Update Call Rate
              </label>
            </div>
            <div style={{ width: "15.6%" }}>
              <input hidden type="text" name="key" ref={regMinCallLimit} />
              <input
                className="form-control form-control-sm "
                type="number"
                name="value"
                min={0}
                style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                ref={regMinCallLimit}
              />
            </div>
            <div className="col-2">
              <label className="m-2 small" style={{ color: "#b59852" }}></label>
            </div>
            <div style={{ width: "30%" }}>
              <Button
                onClick={submitMinCallLimit(submit)}
                style={{ background: "#bfa056", color: "#fff" }}
                variant="contained"
                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                size="small"
              >
                Update
              </Button>
            </div>
            {errorMinimumCallsLimit ? (
              <p style={{ color: "red", marginLeft: "30%" }}>
                Minimum calls limit rate cannot be a negative number or empty
              </p>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div style={{ marginLeft: "15px" }}>
            <h6 style={{ color: "#bfa056" }}>
              Advice seeker eligibility criteria to contact offline experts
            </h6>
            <div className="row mt-4">
              <div style={{ width: "30.6%" }}>
                <p># of pings per expert</p>
              </div>
              <div style={{ width: "27.4%" }}>
                <div style={{ width: "60.6%" }}>
                  <input
                    hidden
                    type="text"
                    name="key"
                    ref={regPingsPerExpert}
                  />
                  <input
                    className="form-control form-control-sm "
                    type="number"
                    name="value"
                    min={0}
                    style={{
                      backgroundColor: "#fff",
                      float: "left",
                    }}
                    ref={regPingsPerExpert}
                  />
                </div>
                <p
                  style={{
                    color: "#bfa056",
                    marginLeft: "10px",
                    float: "left",
                  }}
                >
                  per day{" "}
                </p>
                {errorPingsPerExpert ? (
                  <p style={{ color: "red", float: "left", width: "100%" }}>
                    pings per expert cannot be a negative number or empty.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "15.5%" }}></div>
              <div style={{ width: "20%" }}>
                <Button
                  onClick={submitPingsPerExpert(submit)}
                  style={{
                    background: "#bfa056",
                    color: "#fff",
                    marginLeft: "500px",
                  }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Update
                </Button>
              </div>
            </div>
            <hr />
            <div className="row">
              <div style={{ width: "30.6%" }}>
                <p>Total # of pings to offline experts</p>
              </div>
              <div style={{ width: "27.4%" }}>
                <div style={{ width: "60.6%" }}>
                  <input hidden type="text" name="key" ref={regPingsPerDay} />
                  <input
                    className="form-control form-control-sm "
                    type="number"
                    name="value"
                    min={0}
                    style={{
                      backgroundColor: "#fff",
                      float: "left",
                    }}
                    ref={regPingsPerDay}
                  />
                </div>
                <p
                  style={{
                    color: "#bfa056",
                    marginLeft: "10px",
                    float: "left",
                  }}
                >
                  per day{" "}
                </p>
                {errorPingsPerDay ? (
                  <p style={{ color: "red", float: "left", width: "100%" }}>
                    pings per day cannot be a negative number or empty.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "15.5%" }}></div>
              <div style={{ width: "20%" }}>
                <Button
                  onClick={submitPingsPerDay(submit)}
                  style={{
                    background: "#bfa056",
                    color: "#fff",
                    marginLeft: "500px",
                  }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Update
                </Button>
              </div>
            </div>
            <hr />
            <div className="row">
              <div style={{ width: "31%" }}>
                <p>Time Slots</p>
              </div>
              <div style={{ width: "32.5%" }}>
                {/* <p style={{ color: "#bfa056" }}>Start </p> */}
                <div style={{ width: "90.6%" }}>
                  <div
                    style={{
                      float: "left",
                      margin: "10px 10px 10px 3px",
                      display: "block",
                      width: "fit-content",
                    }}
                  >
                    <TextField
                      label="Start (EST)"
                      value={startTime}
                      type="time"
                      onChange={(data: any) => setstartTime(data.target.value)}
                      ref={regTime}
                    />
                  </div>
                  <div
                    style={{
                      float: "left",
                      margin: "10px",
                      display: "block",
                      width: "fit-content",
                      color: "#bfa056",
                    }}
                  >
                    <TextField
                      label="End (EST)"
                      value={endTime}
                      type="time"
                      onChange={(data: any) => setendTime(data.target.value)}
                      ref={regTime}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "30%" }}>
                <Button
                  onClick={submitTime(onsubmitTimeSlot)}
                  style={{
                    background: "#bfa056",
                    color: "#fff",
                    marginLeft: "100px",
                  }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Update
                </Button>
              </div>
            </div>
          </div>

          <hr />
          <div style={{ marginLeft: "15px" }}>
            <h6 style={{ color: "#bfa056" }}>Premium Account Configuration</h6>

            <hr />

            <div className="row mb-0">
              <div className="ml-3" style={{ width: "30%" }}>
                <label
                  className="mt-2"
                  style={{
                    marginLeft: "-15px",
                  }}
                >
                  Premium Account Fee
                </label>
              </div>
              <div style={{ width: "15.6%" }}>
                <input hidden type="text" name="key" ref={regSubscriptionFee} />
                <input
                  className="form-control form-control-sm "
                  type="number"
                  name="value"
                  min={0}
                  style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                  ref={regSubscriptionFee}
                />
              </div>
              <div className="col-2">
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  $
                </label>
              </div>
              <div style={{ width: "30%" }}>
                <Button
                  onClick={submitSubscriptionFee(submit)}
                  style={{
                    background: "#bfa056",
                    color: "#fff",
                    marginLeft: "100px",
                  }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Update
                </Button>
              </div>
              {errorSubscriptionFee ? (
                <p style={{ color: "red", marginLeft: "30%" }}>
                  Premium Account Amount cannot be a negative number or empty.
                </p>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="row mb-0">
              <div className="ml-3" style={{ width: "30%" }}>
                <label
                  className="mt-2"
                  style={{
                    marginLeft: "-15px",
                  }}
                >
                  Premium Account Bonus Amount
                </label>
              </div>
              <div style={{ width: "15.6%" }}>
                <input
                  hidden
                  type="text"
                  name="key"
                  ref={regSubscriptionBonus}
                />
                <input
                  className="form-control form-control-sm "
                  type="number"
                  name="value"
                  min={0}
                  style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                  ref={regSubscriptionBonus}
                />
              </div>
              <div className="col-2">
                <label className="m-2 small" style={{ color: "#b59852" }}>
                  $
                </label>
              </div>
              <div style={{ width: "30%" }}>
                <Button
                  onClick={submitSubscriptionBonusAmount(submit)}
                  style={{
                    background: "#bfa056",
                    color: "#fff",
                    marginLeft: "100px",
                  }}
                  variant="contained"
                  className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                  size="small"
                >
                  Update
                </Button>
              </div>
              {errorSubscriptionBonus ? (
                <p style={{ color: "red", marginLeft: "30%" }}>
                  Premium Account Bonus Amount cannot be a negative number or
                  empty.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppConfiguration;
