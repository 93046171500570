import { Button } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowsProp } from "@material-ui/data-grid";
import React from "react";
import { useEffect, useState } from "react";
import moment from 'moment-timezone';
import { Modal } from "react-bootstrap";
import WithdrawalService from "../../../services/withdrawal/WithdrawalService";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import "./Withdrawals.css";

import Utili from "../../../utility/Utility";
import CustomNoRowsOverlay from "../../../components/NoRecord";
import SpinnerLoader from "../../../components/spinner-loader/SpinnerLoader.component";

const Withdrawals: any = [];

function Withdrawls(props: any) {

  const [showGrid, setShowGrid] = useState(true);

  const columns: GridColDef[] = [
    { field: "id", hide: true },
    {
      field: "fullName",
      headerName: "Name",
      width: 130,
      headerAlign: "center",
      align: "left",
      headerClassName: "grid-h",
      renderCell: (params: any) =>
        params.row.isActive ?
          (params.row.isDeleted ? "**" + params.row.fullName : params.row.fullName)
          : "*" + params.row.fullName

    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      width: 130,
      align: "left",
    },
    {
      field: "requestNumber",
      headerName: "Request #",
      headerAlign: "center",
      width: 180,
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      align: "center",

      headerAlign: "center",
      renderCell: (params: any) => (
        <>{Utili.formatAmount(params.row.amount)}</>
      ),
    },
    {
      field: "createdOn",
      headerName: "Date",
      width: 160,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) =>
        moment(params.row?.createdOn).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "col4",
      headerName: " ",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          type="button"
          onClick={() =>
            review(withdrawals.find((e: any) => e.id == params.row.id))
          }
          variant="contained"
          className="MuiButton-textSizeSmall primary"
        >
          {params.row.status == "Closed" || params.row.status == "Rejected" || params.row.status == "Processed"
            ? "View"
            : "Review"}
        </Button>
      ),
    },
  ];

  const { register, handleSubmit, reset, errors } = useForm();

  const [withdrawals, setWithdrawals] = useState(Withdrawals);
  const [currentWithdrawals, setCurrentWithdrawals] = useState<any>({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register: userForm,
    handleSubmit: submitUserForm,
    reset: resetUserForm,
  } = useForm();

  const {
    register: searchForm,
    setValue: setValuesSearchForm,
    handleSubmit: submitSearchForm,
    reset: resetSearchFrom,
    watch,
    control: contorlSearch,
    getValues: getValuesSearchForm,
  } = useForm();

  useEffect(() => {
    setValuesSearchForm("status", "Pending");

    var data = {
      param: getValuesSearchForm("status"),
      email: getValuesSearchForm("email"),
      name: getValuesSearchForm("name")
    };
    WithdrawalService.getAll(data)
      .then((res) => {
        setWithdrawals(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getWd = () => {
    setWithdrawals(Withdrawals);
    setShowGrid(false);
    var data = {
      param: getValuesSearchForm("status"),
      email: getValuesSearchForm("email"),
      name: getValuesSearchForm("name")
    };
    WithdrawalService.getAll(data)
      .then((res) => {
        setWithdrawals(res.data.result);
        setShowGrid(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const review = (wd: any) => {
    setCurrentWithdrawals(wd);
    handleShow();
  };
  const submit = (data: any, status: any) => {
    var date = moment();
    data.id = currentWithdrawals.id;
    data.status = status;
    if (
      currentWithdrawals.comments != "" &&
      currentWithdrawals.comments != null &&
      currentWithdrawals.comments != undefined
    ) {
      data.comments =
        currentWithdrawals.comments +
        "\n" +
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") +
        " - " +
        data.comments;
    } else {

      data.comments =
        date.tz('America/New_York').format("MM/DD/YYYY hh:mm A") + " - " + data.comments;
    }
    WithdrawalService.save(data)
      .then((res) => {
        var obj = {
          param: getValuesSearchForm("status"),
          email: getValuesSearchForm("email"),
          name: getValuesSearchForm("name")
        };
        WithdrawalService.getAll(obj)
          .then((res) => {
            setWithdrawals(res.data.result);
            handleClose();
            Utili.notifyUpdated();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Message)
          toast.error(
            err.response.data.Message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
      });
  };

  return (
    <div>
      <SpinnerLoader isGeneral={true} tasksList={
        ['getwithdrawals', 'updatewithdrawal']
      } inInternal={false} />
      <div className="col-12 p-0">
        <h3 className="mb-3">Withdrawals</h3>
        <h4 className="mb-0 heading">
          Search
         
        </h4>
        <div className="p-3 border">
          <form
            autoComplete="off"
            className="form-inline u-mang-form"
            noValidate
            onSubmit={submitUserForm(getWd)}
          >
            <div className="form-group mb-2">
            <div className="form-group mb-2 inputs-wrap">
              <input
                className="form-control form-control-sm"
                type="name"
                name="name"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Name"
              />
               <input
                className="form-control form-control-sm"
                type="email"
                name="email"
                style={{ backgroundColor: "#fff" }}
                ref={searchForm({ required: false })}
                placeholder="Email"
              />
            </div>
              <label className="m-2 ml-4">Type</label>
              <div className="custom-control custom-radio custom-control-inline">
               
               
           
               <input
                 type="radio"
                 value="All"
                 id="all"
                 ref={searchForm}
                 name="status"
                 className="custom-control-input m-left"
                // onChange={getWd}
               />
               <label className="custom-control-label" htmlFor="all">
                 All
               </label>
             </div>
             <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Pending"
                    id="pending"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                   // onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="pending">
                    Pending
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Processed"
                    id="processed"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                   // onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="processed">
                    Processed
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Closed"
                    id="closed"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                 //   onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="closed">
                    Closed
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    value="Rejected"
                    id="rejected"
                    ref={searchForm}
                    name="status"
                    className="custom-control-input"
                   // onChange={getWd}
                  />
                  <label className="custom-control-label" htmlFor="rejected">
                    Rejected
                  </label>
                </div>

              <div className="btn-enabled">
               
                <div className="float-right">
                  <Button
                    type="submit"
                    variant="contained"
                    
                    className="btn search-btn float-right ml-3 primary"
                  >
                    <i className="fa fa-search"></i> Search
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div style={{ paddingBottom: "40px", width: "100%" }}>
          <h5 className="mb-0 heading">Requests</h5>
          {showGrid ?
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              columns={columns}
              rows={withdrawals}
              pageSize={10}
              autoHeight={true}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
            /> : ''}
          <div style={{ marginTop: '10px' }}>
            <p style={{ fontSize: '15px' }}><strong>*</strong>  Deactivated User</p>
            <p style={{ marginTop: '-15px', fontSize: '15px' }}><strong>**</strong> Deleted User</p>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Withdrawals Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="col-12 mt-2" autoComplete="off" noValidate>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Name</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.fullName}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Email</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Amount</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {Utili.formatAmount(currentWithdrawals.amount)}
                  </p>
                </div>
                <div className="col-2">
                  <label className="lbl">Request Number</label>
                </div>
                <div className="col-3">
                  <p className="">
                  {currentWithdrawals.requestNumber}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Status</label>
                </div>
                <div className="col-3">
                  <p className="">{currentWithdrawals.status}</p>
                </div>
                <div className="col-2">
                  <label className="lbl">Created On</label>
                </div>
                <div className="col-3">
                  <p className="">
                    {moment(currentWithdrawals.createdOn).format(
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <label className="lbl">Previous Comments</label>
                </div>
                <div className="col-10">
                  <p className="">{currentWithdrawals.comments}</p>
                </div>
              </div>
              {currentWithdrawals.status == "Rejected" ||
                currentWithdrawals.status == "Closed" ? (
                ""
              ) : (
                <div className="form-group">
                  <div className="row">
                    <div className="col-2">
                      <label className="lbl">Comments</label>
                      <span className="text-danger asterisk-bx"> *</span>
                    </div>
                    <div className="col-10">
                      <textarea
                        name="comments"
                        ref={register({ required: true })}
                        className="form-control"
                        placeholder="comments"
                      />

                      {/* {errors.comments && (
                        <span className="text-danger">
                          Comments are required
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              )}
              <br />
              {/* <div className="row">
                <div className="col-md-10 offset-2">
                  <span className="text-danger">Field with * are required.</span>
                </div>
              </div> */}
              <div className="row">
                <div className="col-2"></div>
                <div className="col-10">
                  <div className="pb-4">
                    {
                      currentWithdrawals.status != "Rejected" &&
                        currentWithdrawals.status != "Closed" ?
                        (
                          <span className="pull-left text-danger">
                            All fields are required with Asterisk (
                            <span className="asterisk-bx text-danger">*</span>)
                          </span>
                        ) :
                        ""
                    }

                    <div className="pull-right">
                      <Button
                        className="mr-2"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      {currentWithdrawals.status == "Rejected" || currentWithdrawals.status == "Processed"  ||
                        currentWithdrawals.status == "Closed" && (currentWithdrawals.isActive == false || currentWithdrawals.isDeleted == true) ? (
                        ""
                      ) : (
                        (currentWithdrawals.status == "Pending" && (currentWithdrawals.isActive == false || currentWithdrawals.isDeleted == true)) ? '':
                        <Button
                          variant="contained"
                          style={{ background: "#bfa056", color: "#fff" }}
                          onClick={handleSubmit((data) =>
                            submit(data, "Rejected")
                          )}
                        >
                          Reject
                        </Button>
                      )}
                      {currentWithdrawals.status == "Pending" && (currentWithdrawals.isActive == true && currentWithdrawals.isDeleted == false) ? (
                        <Button
                          className="ml-2"
                          style={{ background: "#bfa056", color: "#fff" }}
                          variant="contained"
                          onClick={handleSubmit((data) =>
                            submit(data, "Processed")
                          )}
                        >
                          Process
                        </Button>
                      ) : (
                        ""
                      )}
                      {currentWithdrawals.status == "Processed" ? (
                        <Button
                          className="ml-2"
                          style={{ background: "#bfa056", color: "#fff" }}
                          variant="contained"
                          onClick={handleSubmit((data) =>
                            submit(data, "Closed")
                          )}
                        >
                          Close
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Withdrawls;
