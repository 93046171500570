import { Button } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SettingsService from '../../../services/settings-service/SettingsService';
import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Utili from "../../../utility/Utility";
import { useForm } from "react-hook-form";

const Value: any = [];

function ActiveConfiguration(props: any) {
    const [values, setValues] = useState(Value);

    const {
        register: registerValues,
        errors: errorsAdminForm,
        handleSubmit: submitValues,
        reset: resetValues,
        setValue: setValuesConfig,
    } = useForm();

    const notifyUpdated = () =>
    toast.success(
       "Configuration updated successfully!"  ,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );


    function refreshPage() {
        window.location.reload();
    }

    const GetActiveNotiConfiguration = () => {
        SettingsService.GetActiveNotiConfiguration().then((res: any) => {
            setValues(res.data.result);
            console.log()
        })
    }

    const SubmitValues = (data: any) => {
        SettingsService.update(data.data).then((res) => {
            if(res.data != null){
                notifyUpdated();
            }
            
        }).catch((res :any) => {
            Utili.notifyError();
        })
        
    }

    useEffect(() => {
        GetActiveNotiConfiguration();

    }, []);

   const onsubmit = (data : any) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to Save the Changes.',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => SubmitValues(data)
                },
                {
                    label: 'Cancel',
                    onClick: () => refreshPage()
                }
            ]
        });
    };

    return (
        <div className="">
            <div className="col-6 p-0">
                <h3 className="mb-3">Active Configuration</h3>
                <h4 className="mb-0 heading"> Configuration </h4>
                <div className="p-3 border">
                    <form noValidate onSubmit={submitValues(onsubmit)} >
                        {values.map((item: any, index: any) => {
                            const fieldValue = `data[${index}]`;
                            return (
                                <fieldset name={fieldValue} key={fieldValue}>
                                    <div >
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="m-2">{item.label}</label>
                                            </div>
                                            <div className="col-2">
                                                <input hidden type="text" name={`${fieldValue}.key`} value={item.key} ref={registerValues} />
                                                <input hidden type="text" name={`${fieldValue}.category`} value={item.category} ref={registerValues} />
                                                <input
                                                    className="form-control form-control-sm "
                                                    type="number"
                                                    name={`${fieldValue}.value`}
                                                    defaultValue={item.value}
                                                    min="0"
                                                    style={{ backgroundColor: "#fff", paddingLeft: "7%" }}
                                                    ref={registerValues}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <label className="m-2 small" style={{ color: "#b59852" }}>{item.unit}</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            );
                        })}
                        <div className=" form-group  p-3">
                            <Button
                                type="submit"
                                style={{ background: "#bfa056", color: "#fff" }}
                                variant="contained"
                                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right ml-2"
                                size="small"

                            >
                                Save Changes
                            </Button>
                            {/* <Button
                                variant="contained"
                                className="MuiButton-sizeSmall MuiButton-textSizeSmall float-right"
                                size="small"
                                onClick={refreshPage}
                            >
                                Cancel
                            </Button> */}
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ActiveConfiguration;
