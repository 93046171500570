import { Button } from '@material-ui/core';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../../components/spinner-loader/SpinnerLoader.component'
import service from '../../../services/communication-service/communication'
import { toast } from 'react-toastify';
import { red } from '@material-ui/core/colors';
function Announcements() {
    const [UserType, setUserType] = useState('-1');
    const [count, setCount] = useState(100);
    const [title, setCountTitle] = useState(20);
    const [body, setbody] = useState('');
    const [msgTitle, setmsgTitle] = useState('');
    const [errorTitle, seterrorTitle] = useState(false);
    const [errorbody, seterrorbody] = useState(false);
    const handleUserTypeChange = (name: any) => {
        console.log("BonusStatus=" + name.target.value);
        setUserType(name.target.value);
    };
    const handleTexthange = (text: any) => {

        setCount(100 - text.target.value.length);
        setbody(text.target.value);

    };
    const handleTitlechange = (text: any) => {

        setCountTitle(20 - text.target.value.length);
        setmsgTitle(text.target.value);

    };

    const SendMessage = () => {
        const data = {
            "roleName": UserType,
            "title": msgTitle,
            "body": body
        }
        if (msgTitle === null || msgTitle === "") {
            seterrorTitle(true);

            console.log("tile")
        } else if (body === null || body === "") {
            seterrorbody(true);
            seterrorTitle(false);
          
            console.log("message")
        } else {
            seterrorTitle(false);
            seterrorbody(false);
            service.BroadCastMessages(data).then((res) => {

                if (res.data) {
                    toast.success("Messages Sent Successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setUserType("-1");
                    // setbody('');
                    // setmsgTitle('');


                }
            })
        }



    };
    return (
        <div>
            <SpinnerLoader isGeneral={true} tasksList={
                ['SendBackgroundMessages']
            } inInternal={false} />
            <h3 className="mb-3">Announcements</h3>
            <div className='row'>
                <div className='label-wrap'>
                    <div>
                        <label htmlFor='bonusstatus' className=""><b>User Type</b></label>
                    </div>
                    <div>
                        <label><b>Title</b></label>
                    </div>
                    <div>
                        <label className=''><b>Message</b></label>
                    </div>

                </div>
                <div className='i-fields-wrap'>
                    <select onChange={handleUserTypeChange} value={UserType} style={{ width: '250px' }} className="form-control form-control-sm ml-0" id="bonusstatus" name="bonusstatus">
                        <option value="-1">All</option>
                        <option value="Owwll">Owwlls</option>
                        {/* <option value="Owlet">Owlets</option> */}
                        <option value="BrandAmbassador">Brand Ambassadors</option>
                    </select>
                    <div className="input-wrapper">
                        <input
                            className="form-control form-control-sm mb-1 "
                            type="name"
                            maxLength={20}
                            name="title"
                            style={{ backgroundColor: "#fff", width: '400px' }}
                            onChange={handleTitlechange}
                            placeholder="Title"

                        />
                        <p style={{ marginLeft: '200px' }} className='inside-text2'>{title} characters remaining</p>
                    </div>
                    {
                        errorTitle ?
                            <p style={{ color: 'red', marginTop: '0px' }} >Title Is Required</p>
                            :
                            ''
                    }
                    <div className="input-wrapper">
                        <textarea
                            style={{ width: '400px', resize: 'none', height: '120px', paddingBottom:30 }}
                            name="additionalDetail"
                            onChange={handleTexthange}
                            maxLength={100}
                            className="form-control"
                        />
                        <p style={{ marginLeft: '200px' }} className='inside-text2'>{count} characters remaining</p>
                    </div>
                    {
                        errorbody ?
                            <p style={{ color: 'red'}} >Message Is Required </p>
                            :
                            ''
                    }
                     <Button style={{ float:'right', marginTop:13}} variant="contained" className="MuiButton-textSizeSmall primary" onClick={SendMessage}>
                        Send
                    </Button>
                </div>

            </div>
           


            <div className="group-field group-field2">


            </div>
        </div>
    )
}

export default Announcements