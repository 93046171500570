import './Footer.css';
import React from 'react';

function Footer() {
  return (
    <div className="footer text-center p-3" style={{ backgroundColor: '#c0a057' }}>
      Copyright © All rights reserved, Owwll 2021
    </div>
  );
}

export default Footer;
