import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import "./Administrator.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ManageUser from "./manage-user/ManageUser";
import Upgrades from "./upgrades/Upgrades";
import Withdrawls from "./withdrawls/Withdrawls";
import withAuth from "../../components/hod/withAuth";
import FeedBack from "./manage-user/feedback/FeedBack";
import Referrals from "./manage-user/referrals/Referrals";
import SignupRequest from "./signup-request/SignupRequest";
import DeletionRequests from "./deletion-requests/DeletionRequests";
import UsersPhone from "./users-phone/UsersPhone";
import {
  TimeonCall,
  CallsReport,
  OwwllActiveTime,
  ReferralReport,
  SubscriptionReport,
} from "../reports";
import Issues from "./issues/Issues";
import { NewRefundRequest, Refunds } from "../financials";
import MissedCallsRatio from "./missedCallsRatio-report/MissedCallsRatio";
import React from "react";
import Announcements from "./announcements/Announcements";
import DeactivatedUsers from "./deactivated-users/DeactivatedUsers";

function Administrator(props: any) {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <div className="row m-0">
        <div className="col-2 p-0">
          <div className="left-nav-bx">
            <nav className="navbar">
              <ul className="navbar-nav left-m">
                <li className="nav-item active-page ">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/administrator/manageuser"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/manageuser`}
                  >
                    Users
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link className={"nav-link " + (props.location.pathname == "/administrator/upgrades" ? "active-sublink" : "")} to={`${url}/upgrades`}>
                      Upgrades
                    </Link> */}
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/administrator/signuprequest"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/signuprequest`}
                  >
                    Signup Requests
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <h6>
                    <Link className={"nav-link " + (props.location.pathname == "/administrator/withdrawals" ? "active-sublink" : "")} to={`${url}/withdrawals`}>
                      Withdrawals
                    </Link>
                  </h6>
                </li> */}
                {/* <li className="nav-item">
                    <Link className={"nav-link " + (props.location.pathname == "/administrator/issues" ? "active-sublink" : "")} to={`${url}/issues`}>
                      Issues
                    </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className={
                      "nav-link " +
                      (props.location.pathname == "/administrator/announcements"
                        ? "active-sublink"
                        : "")
                    }
                    to={`${url}/announcements`}
                  >
                    Announcements
                  </Link>
                </li>
                <li className="nav-item active-page ">
                  <h6 className="navbar-subheading">Reports</h6>
                  <ul className="navbar-nav ml-4">
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/callsreport"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/callsreport`}
                      >
                        Call Counts
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/referralreport"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/referralreport`}
                      >
                        Referrals
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/timeoncallreport"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/timeoncallreport`}
                      >
                        Time on Call
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/owwllactivetimereport"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/owwllactivetimereport`}
                      >
                        Owwll Active Time
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/usersphone"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/usersphone`}
                      >
                        Users Phone
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/missedcallsratio"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/missedcallsratio`}
                      >
                        Missed Calls Ratio
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          "nav-link " +
                          (props.location.pathname ==
                          "/administrator/subscriptionreport"
                            ? "active-sublink"
                            : "")
                        }
                        to={`${url}/subscriptionreport`}
                      >
                        Premium Accounts
                      </Link>
                    </li>
                    <li className="nav-item">
                      <h6 className="navbar-subheading">Operations</h6>
                      <ul className="navbar-nav ml-4">
                        <li className="nav-item">
                          {/* <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/upgrades"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/upgrades`}
                          >
                            Upgrades
                          </Link> */}
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/withdrawls"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/withdrawls`}
                          >
                            Withdrawals
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/refunds"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/refunds`}
                          >
                            Refunds
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/issues"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/issues`}
                          >
                            Issues
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/deactivatedusers"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/deactivatedusers`}
                          >
                            Deactivations
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={
                              "nav-link " +
                              (props.location.pathname ==
                              "/administrator/deletionrequest"
                                ? "active-sublink"
                                : "")
                            }
                            to={`${url}/deletionrequest`}
                          >
                            Deletions
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-10 p-4">
          <Switch>
            <Route exact path={path}>
              <div className="">
                <h3 className="mb-3">Welcome to Administration</h3>
              </div>
            </Route>
            <Route path={`${path}/manageuser`} component={ManageUser} />
            <Route path={`${path}/upgrades`} component={Upgrades} />
            <Route path={`${path}/signuprequest`} component={SignupRequest} />
            <Route
              path={`${path}/newrefundrequest`}
              component={NewRefundRequest}
            />
            <Route path={`${path}/callsreport`} component={CallsReport} />
            <Route path={`${path}/referralreport`} component={ReferralReport} />
            <Route path={`${path}/timeoncallreport`} component={TimeonCall} />
            <Route
              path={`${path}/subscriptionreport`}
              component={SubscriptionReport}
            />
            <Route
              path={`${path}/owwllactivetimereport`}
              component={OwwllActiveTime}
            />
            <Route path={`${path}/usersphone`} component={UsersPhone} />
            <Route path={`${path}/announcements`} component={Announcements} />
            <Route path={`${path}/withdrawls`} component={Withdrawls} />
            <Route path={`${path}/issues`} component={Issues} />
            <Route path={`${path}/refunds`} component={Refunds} />
            <Route
              path={`${path}/missedcallsratio`}
              component={MissedCallsRatio}
            />
            <Route
              path={`${path}/deletionrequest`}
              component={DeletionRequests}
            />
            <Route
              path={`${path}/deactivatedusers`}
              component={DeactivatedUsers}
            />

            {/* <Route
              path={`${path}/issues`}
              component={Issues}
            />  */}
            <Route path={`${path}/feedback/:id`} component={FeedBack} />
            <Route path={`${path}/referrals/:id`} component={Referrals} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Administrator);
